import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, message, Modal, Spin } from 'antd'
import { DefaultJsonForm } from '../../../../../../components/v2/default-json-form'
import schema from './schema.json'
import { convertData2JsonForm } from '../../../../../../shared/lib/convertData2JsonForm'
import fetchAPI from '../../../../../../../lib/utils/fetch-api'
import { isFormDataRequired } from '../../../../../../../lib/utils/collections'
import { IFormData, ISchemaActual, ManufactureModalProps } from './interfaces'

export const ManufactureModal: React.FC<ManufactureModalProps> = memo((props: ManufactureModalProps) => {
  const { id, isModalOpen, onOk, onCancel } = props
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [enums, setEnums] = useState<any>({})
  const [formData, setFormData] = useState<IFormData>({
    manufacture_responsible: undefined,
    delivery_date: undefined,
    montage_specification_scan: undefined,
    mounting_office: undefined,
    mounting_responsible: undefined,
    production_number: undefined,
    mounting_date: undefined,
    mounting: false,
    comment: undefined,
  })

  const fetchEnums = useCallback(async () => {
    setLoading(true)
    try {
      const res = await fetchAPI(`/api/order/${id}/manufacture`)
      setEnums(res.enums)
      setFormData(prev => ({
        ...prev,
        production_number: res?.data?.production_number,
      }))
    } catch (error) {
      message.error('Ошибка при загрузке данных')
      setError('Ошибка при загрузке данных')
    } finally {
      setLoading(false)
    }
  }, [id])

  useEffect(() => {
    if (isModalOpen) {
      fetchEnums()
    }
  }, [isModalOpen, fetchEnums])

  const handleOk = useCallback(() => {
    const isRequiredFields = isFormDataRequired(formData, actualSchema)
    if (!isRequiredFields) {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Заполните все обязательные поля',
      })
      return
    }
    onOk?.(formData)
  }, [formData, onOk])

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    onCancel?.(e)
  }

  const onChange = useCallback(data => {
    setFormData(prevData => ({
      ...prevData,
      ...data.formData,
    }))
  }, [])

  const actualSchema = useMemo(() => {
    const schemaActual = convertData2JsonForm(schema.fields) as never as ISchemaActual

    schemaActual.resultSchema.properties.mounting_time.oneOf = [
      {
        const: '09:00 - 11:00',
        title: '09:00 - 11:00',
      },
      {
        const: '11:00 - 13:00',
        title: '11:00 - 13:00',
      },
      {
        const: '13:00 - 15:00',
        title: '13:00 - 15:00',
      },
      {
        const: '15:00 - 17:00',
        title: '15:00 - 17:00',
      },
      {
        const: '17:00 - 19:00',
        title: '17:00 - 19:00',
      },
    ]
    schemaActual.resultSchema.properties.mounting_time.enum = schemaActual.resultSchema.properties.mounting_time.oneOf
      .reduce((acc, curr) => {
        acc.push(curr.const)
        return acc
      },[])
    schemaActual.resultSchema.properties.delivery_time.oneOf = [
      {
        const: '09:00 - 11:00',
        title: '09:00 - 11:00',
      },
      {
        const: '11:00 - 13:00',
        title: '11:00 - 13:00',
      },
      {
        const: '13:00 - 15:00',
        title: '13:00 - 15:00',
      },
      {
        const: '15:00 - 17:00',
        title: '15:00 - 17:00',
      },
      {
        const: '17:00 - 19:00',
        title: '17:00 - 19:00',
      },
    ]
    schemaActual.resultSchema.properties.delivery_time.enum = schemaActual.resultSchema.properties.delivery_time.oneOf
      .reduce((acc, curr) => {
        acc.push(curr.const)
        return acc
      },[])

    if (enums?.manufacture_offices?.length) {
      schemaActual.resultSchema.properties.manufacture_responsible.oneOf = enums.manufacture_offices
        .map((organization: any) => ({ const: organization.id, title: organization.value }))
      schemaActual.resultSchema.properties.manufacture_responsible.enum = schemaActual.resultSchema.properties.manufacture_responsible.oneOf
        .reduce((acc: number[], curr: { const: number; title: string }) => {
          acc.push(curr.const)
          return acc
        },[])
    }
    if (enums?.mounting_offices?.length) {
      schemaActual.resultSchema.properties.mounting_office.oneOf = enums.mounting_offices
        .map((office: any) => ({ const: office.id, title: office.value }))
      schemaActual.resultSchema.properties.mounting_office.enum = schemaActual.resultSchema.properties.mounting_office.oneOf
        .reduce((acc: number[], curr: { const: number; title: string }) => {
          acc.push(curr.const)
          return acc
        },[])
    }
    const { mounting_office } = formData
    if (mounting_office && enums?.mounting_responsible?.length) {
      schemaActual.resultSchema.properties.mounting_responsible.oneOf = enums.mounting_responsible
        .map((office: any) => ({ const: office.id, title: office.value }))
      schemaActual.resultSchema.properties.mounting_responsible.enum = schemaActual.resultSchema.properties.mounting_responsible.oneOf
        .reduce((acc: number[], curr: { const: number; title: string }) => {
          acc.push(curr.const)
          return acc
        },[])
      schemaActual.resultSchema.properties.mounting_responsible.options.disabled = false
    }
    if (!formData.mounting) {
      delete schemaActual.resultSchema.properties.montage_specification_scan
      delete schemaActual.resultSchema.properties.mounting_responsible
      delete schemaActual.resultSchema.properties.mounting_office
      delete schemaActual.resultSchema.properties.production_number
      delete schemaActual.resultSchema.properties.mounting_date
      delete schemaActual.resultSchema.properties.mounting_time
      delete schemaActual.resultSchema.properties.comment
      schemaActual.resultSchema.required = schemaActual.resultSchema.required.filter(
        item => ![
          'montage_specification_scan',
          'mounting_responsible',
          'mounting_office',
          'production_number',
          'mounting_date',
          'mounting_time',
          'comment',
        ].includes(item)
      )
    }
    return schemaActual.resultSchema || {}
  }, [schema, formData, enums])

  if (!isModalOpen) {
    return null
  }

  return (
    <Modal
      title={schema.title}
      onOk={handleOk}
      onCancel={handleCancel}
      visible={isModalOpen}
      destroyOnClose={true}
      okButtonProps={{ disabled: loading }}
    >
      {loading ? (
        <Spin />
      ) : (
        error
          ? (<Alert message={error} type='error' showIcon />)
          : (
            <DefaultJsonForm
              formData={formData}
              schema={actualSchema}
              onChange={onChange}
              orientation='horizontal'
            />
          )
      )}
    </Modal>
  )
})
