import React, { memo, useCallback, useRef } from 'react'
import { Alert, Modal, Skeleton } from 'antd'
import { classNames } from '../../../../lib/classNames/classNames'
import { DefaultJsonForm } from '../../../v2/default-json-form'
import { useAutoCloseModal } from '../../../../hooks/useAutoCloseModal'
import { convertData2JsonForm } from '../../../../shared/lib/convertData2JsonForm'
import { schema } from '../../model/schema/modal-schema'
import { isFormDataRequired } from '../../../../../lib/utils/collections'
import { setPayment } from '../../model/api/setPayment'
import { getOrdersForm } from '../../../../store/order/action-creators'
import { useDispatch } from 'react-redux'
import { getOrderDetailData } from '../../../../store/ordersDetail/action-creators'
import { getTimeLineData } from '../../../../store/timeline/action-creators'

interface ModalPaymentAmountProps {
  className?: string
  entityId?: string | number
  isOpen?: boolean
  onClose?: () => void,
  debt?: number
}

const { resultSchema, resultObject } = convertData2JsonForm(schema)

export const ModalPaymentAmount: React.FC<ModalPaymentAmountProps> = memo(
  (props: ModalPaymentAmountProps) => {
    const dispatch = useDispatch()
    const {
      className,
      entityId,
      isOpen,
      onClose,
      debt
    } = props

    if (!entityId) {
      return null
    }

    const form = useRef<any>({
      resultSchema,
      resultObject
    })

    const [isSuccess, setIsSuccess] = useAutoCloseModal(onClose)

    const changeFormData = useCallback(async ({formData}) => {
      const isFilesEdit = JSON.stringify(formData) === JSON.stringify(form.current?.resultObject)
      if (isFilesEdit) {
        return
      }
      form.current.resultObject = {...formData}
    }, [form])

    const handleAddPayment = useCallback(async () => {
      if (!form.current) {
        return
      }
      const controller = new AbortController()
      const data = { ...form.current?.resultObject }
      const isRequired = isFormDataRequired(data, form.current?.resultSchema)

      if (!isRequired) {
        Modal.warning({
          title: 'Предупреждение',
          content: 'Заполните все обязательные поля'
        })
        return
      }

      if (debt && data.sum > debt) {
        Modal.warning({
          title: 'Предупреждение',
          content: 'Сумма внесения не может превышать сумму задолженности.'
        })
        return
      }

      if (data.sum <= 0) {
        Modal.warning({
          title: 'Предупреждение',
          content: 'Сумма внесения не может быть отрицательной или равной 0.'
        })
        return
      }

      await setPayment(entityId, data, controller.signal)
        .then((response) => {
          if (response.error) {
            Modal.error({
              title: 'Ошибка',
              content: `При внесении оплаты возникла ошибка: ${response.error}. Внесение оплаты не сохранено.`
            })
            return
          }
          setIsSuccess(true)
          dispatch(getOrdersForm(entityId))
          dispatch(getOrderDetailData('case', entityId))
          dispatch(getTimeLineData('case', entityId))
        }).catch(() =>
          Modal.error({
            title: 'Ошибка',
            content: 'При внесении оплаты возникла ошибка. Внесение оплаты не сохранено.'
          })
        )
    }, [form, entityId, setIsSuccess, debt])

  return (
    <Modal
      title='Внесение предоплаты'
      visible={isOpen}
      onOk={handleAddPayment}
      onCancel={onClose}
      className={classNames('modal-payment-amount', {}, [className])}
    >
      {isSuccess && (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message='Операция успешно выполнена:'
            showIcon
            type='success'
          />
        </div>
      )}
      {form.current ? (
        <DefaultJsonForm
          formData={{ ...form.current.resultObject }}
          schema={form.current.resultSchema}
          onChange={changeFormData}
          orientation='horizontal'
        />
      ) : <Skeleton active/>}
    </Modal>
  )
})
