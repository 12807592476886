import React, { memo, useEffect, useState } from "react"
import { UpOutlined } from "@ant-design/icons"
import { ButtonStyled } from "./style"

export const ButtonScrollTop = memo(() => {
    const [isShowButton, setIsShowButton] = useState(false)

    const setIsShow = (position: number) => {
        if (position > 300) {
            setIsShowButton(true)
        } else {
            setIsShowButton(false)
        }
    }

    const scrollEvent = () => setIsShow(window.scrollY)

    useEffect(() => {
        window.addEventListener("scroll", scrollEvent)
        return () => {
            window.removeEventListener("scroll", scrollEvent)
        }
    }, [])

    useEffect(() => {
        if (window.scrollY === 0) {
            setIsShowButton(false)
        }
    }, [window.scrollY])

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: "smooth"
        })
        setIsShowButton(false)
    }

    if (!isShowButton) {
        return null
    }

    return (
      <ButtonStyled type={"primary"} onClick={scrollToTop}>
          <UpOutlined />
      </ButtonStyled>
    )
})
