import React, { memo, useCallback, useState } from 'react'
import { Button, Form, Input } from 'antd'
import PhoneInput from 'react-phone-input-2'
import ru from 'react-phone-input-2/lang/ru.json'
import './index.css'

interface CustomersSearchFormProps {
  onSearch: (data: ISearchQuery) => void
  loading: boolean
}

interface ISearchQuery {
  phone?: string
  additional?: string
  production_number?: string
  order_id?: string
}

export const CustomersSearchForm: React.FC<CustomersSearchFormProps> = memo((props: CustomersSearchFormProps) => {
  const { onSearch, loading } = props
  const [form] = Form.useForm()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const onValuesChange = useCallback((changedValues, allValues) => {
    const isAnyFieldFilled = Object.values(allValues).some(value => value)
    setIsButtonDisabled(!isAnyFieldFilled)
  }, [])

  const onSearchHandler = useCallback(() => {
    form
      .validateFields()
      .then(values => {
        onSearch(values)
      })
      .catch(error => {
        console.error('Validation failed:', error)
      })
  }, [form, onSearch])

  const areaCodes = ['310', '311', '312', '313', '315', '318', '321', '324', '325', '326', '327', '336', '7172', '73622']
  const areaCodesWithBrackets = areaCodes.map((code) => `(${code})`)

  return (
    <Form
      form={form}
      onValuesChange={onValuesChange}
      layout={'vertical'}
      onFinish={data => {
        if (Object.values(data).some(value => value)) {
          onSearch(data)
        }
      }}
    >
      <Form.Item
        name='phone'
        label="Основной или дополнительный номер телефона"
      >
        <PhoneInput
          containerClass={'styled-phone-input'}
          enableSearch={true}
          searchPlaceholder={'Поиск'}
          localization={ru}
          country={'ru'}
          onlyCountries={['ru','kz','by','tj','uz','am','kg','az','md','tm']}
          areaCodes={areaCodesWithBrackets}
          masks={{ kz: '(...) ..-..-..' }}
          onEnterKeyPress={() => {
            form.submit()
          }}
        />
      </Form.Item>
      <Form.Item
        name='production_number'
        label="Номер производственного заказа"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='additional'
        label='Дополнительные контакты (E-mail, АвитоID и др.)'
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='order_id'
        label='ID заказа'
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='name'
        label='Контактное лицо'
      >
        <Input />
      </Form.Item>
      <Button
        onClick={onSearchHandler}
        disabled={isButtonDisabled || loading}
        type='primary'
        htmlType='submit'
      >
        Найти
      </Button>
    </Form>
  )
})
