import React, { useState } from 'react'
import { Skeleton, Statistic } from 'antd'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts'
import { StatisticsCardT, StatisticsContainerT, StatisticsRow } from '../styles'
import { IStatisticsProps, IStatisticsStageData } from '../interfaces/interfaces'
/**
 * BarChart со статистикой найма
 */
const SeveralYearsStatistic: React.FC<{ stagesData: IStatisticsStageData[] }> = ({
  stagesData,
}) => {
  return (
    <ResponsiveContainer width='100%' height={400}>
      <BarChart data={stagesData}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='month' interval={0} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey='total' fill={'#8884d8'} name='Получено откликов'>
          <LabelList dataKey='total' position='top' />
        </Bar>
        <Bar dataKey='interview' fill={'#ff7300'} name='Собеседования'>
          <LabelList dataKey='interview' position='top' />
        </Bar>
        <Bar dataKey='rejected' fill={'#d0ed57'} name='Отказы'>
          <LabelList dataKey='rejected' position='top' />
        </Bar>
        <Bar dataKey='accepted' fill={'#163bc9'} name='Приняты на работу'>
          <LabelList dataKey='accepted' position='top' />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
/**
 * Несколько BarChart'ов со статистикой найма разбитые по годам
 */
const MultiYearStatistic: React.FC<{ stagesData: IStatisticsProps['stagesData'] }> = ({
  stagesData,
}) => {
  // Группировка данных по годам
  const groupedData = stagesData.reduce<{ year: string; yearStageData: IStatisticsStageData[] }[]>(
    (acc, item) => {
      const itmYr = item.year
      const yrIndex = acc.findIndex(({ year }) => itmYr == year)
      if (yrIndex < 0) {
        acc.push({ year: itmYr, yearStageData: [item] })
      } else {
        acc[yrIndex].yearStageData.push(item)
      }
      return acc
    },
    [],
  )
  // Сортировка данных по годам
  groupedData.sort((a, b) => Number(b.year) - Number(a.year))
  return (
    <>
      {groupedData.map(({ year, yearStageData }) => (
        <div key={year} style={{ marginBottom: '40px' }}>
          <h2 style={{ textAlign: 'center', marginBottom: '10px' }}>{year} год</h2>
          <SeveralYearsStatistic stagesData={yearStageData} />
        </div>
      ))}
    </>
  )
}

const Statistics: React.FC<IStatisticsProps & { loading: boolean }> = ({
  totalResponses,
  totalAccepted,
  totalRejected,
  totalInterviews,
  stagesData,
  loading,
}) => {
  return (
    <StatisticsContainerT>
      <StatisticsRow>
        <StatisticsCardT>
          {loading ? (
            <Skeleton active />
          ) : (
            <Statistic
              title='Количество откликов'
              value={Math.round(totalResponses).toLocaleString('ru-RU')}
            />
          )}
        </StatisticsCardT>
        <StatisticsCardT>
          {loading ? (
            <Skeleton active />
          ) : (
            <Statistic
              title='Проведено собеседований'
              value={Math.round(totalInterviews).toLocaleString('ru-RU')}
            />
          )}
        </StatisticsCardT>
        <StatisticsCardT>
          {loading ? (
            <Skeleton active />
          ) : (
            <Statistic title='Отказы' value={Math.round(totalRejected).toLocaleString('ru-RU')} />
          )}
        </StatisticsCardT>
        <StatisticsCardT>
          {loading ? (
            <Skeleton active />
          ) : (
            <Statistic
              title='Приняты на работу'
              value={Math.round(totalAccepted).toLocaleString('ru-RU')}
            />
          )}
        </StatisticsCardT>
      </StatisticsRow>
      <StatisticsRow>
        <StatisticsCardT style={{ width: '100%' }}>
          {loading ? (
            <Skeleton active />
          ) : (
            <div style={{ width: '100%' }}>
              <h3>Динамика действий по месяцам</h3>
              <MultiYearStatistic stagesData={stagesData} />
              {/* <SeveralYearsStatistic stagesData={stagesData} /> */}
            </div>
          )}
        </StatisticsCardT>
      </StatisticsRow>
    </StatisticsContainerT>
  )
}

export default Statistics
