import React, { useEffect, useMemo, memo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import B2BLayout from '../../../layouts/b2b'
import { createSession, getAppealId, getAppealsFormError, getAppealsFormLoading } from './selectors'
import {
  DefaultJsonFormContextProps
} from '../../../components/v2/default-json-form/context/default-json-form-context-props'
import { getAppealsEnums, getAppealsForm } from '../../../store/appeal/action-creators'
import { HStack } from '../../../ui/Stack'
import DefaultJsonFormContext from '../../../components/v2/default-json-form/context/default-json-form-context'
import AppealsTabs from './ui/tabs/appeals-tabs'
import { AppealsForms } from './ui/appeals-forms/appeals-forms'

const breadcrumbs = [
  {
    href: '/sales',
    title: 'Продажи',
  },
  {
    href: '/appeals',
    title: 'Обращения',
  }
]

function AdminNewObjectPage() {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch()
  const appealId = useSelector(getAppealId)
  const entityType = 'appeal'
  const appealsFormIsLoading = useSelector(getAppealsFormLoading)
  const appealsFormError = useSelector(getAppealsFormError)
  const { sessionData } = useSelector(createSession)
  const isShowByAccess = sessionData.roles.includes(19)

  const defaultProps = useMemo(
    (): DefaultJsonFormContextProps => ({
      entityId: id,
      entityType: entityType,
    }), [id])

  useEffect(() => {
    let mounted = true
    if (mounted) {
      const getInitPage = async (id) => {
        await dispatch(getAppealsEnums(id))
        await dispatch(getAppealsForm(id))
      }

      getInitPage(id).catch(console.error)
    }
    return () => {
      mounted = false
    }
  }, [id, isShowByAccess, dispatch])

  useEffect(() => {
    if (id === 'new' && appealId) {
      history.push({
        pathname: `/appeal/${appealId}`,
      })
    }
    return () => {}
  }, [id, appealId])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      {
        (appealsFormError && !appealsFormIsLoading) ? (
          <HStack gap={"8"} className={'mapWrapper'}>
            При выполнении запроса возникла ошибка.
            <br/>
            {appealsFormError}
            <br/>
            Попробуйте перезагрузить страницу или открыть обращение повторно.
          </HStack>
        ) : (
          <DefaultJsonFormContext.Provider value={defaultProps}>
            <AppealsForms id={id}/>
            <AppealsTabs id={id}/>
          </DefaultJsonFormContext.Provider>
        )
      }
  </B2BLayout>
  )
}

export default memo(AdminNewObjectPage)
