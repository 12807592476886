import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import B2BLayout from '../../../layouts/b2b'
import {Space, Button} from 'antd'
import {getColumns} from './columns'
import fetchAPI from '../../../../lib/utils/fetch-api'
import {useHistory, useLocation} from 'react-router-dom'
import {Sorter} from '../../../interfaces'
import {objectToUrl} from '../../utils'
import {currentFiltersProps, DataType, Enums, TablePaginationPosition} from './interface'
import {createSelector} from 'reselect'
import {useSelector} from 'react-redux'
import './index.css'
import FiltersDrawer from "../../../components/v2/filters-drawer"
import {getJsonSettingsByKey, TableSettingsButton, TransferItem} from "../../../components/v2/table-settings-button"
import {HStack} from 'src/react-app/ui/Stack'
import {PrintButton} from "../ui/print-button/print-button"
import {getPrintPath} from "../../../components/v2/export-excel-button"
import DateFilter from "./filter-date";
import {getDataOfType} from "../../../../lib/utils/get-data-of-type"
import FixedHeader from "../../../layouts/b2b/fixed-header"
import DataTable from "../../../components/data-table";
import dayjs from "dayjs";
import {getFilters} from "./filters";
import {RouteSheetsCardModal} from "./card-modal";

const createMenuCounters = createSelector(
  (state: any) => state.menuV2,
  counters => counters,
)

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

function parseUrlSearchParams(search: string): currentFiltersProps {
  const urlParams = new URLSearchParams(search)
  return {
    date: urlParams.get('date') || undefined,
    id: Number(urlParams.get('id')) || undefined,
    vehicle_id: Number(urlParams.get('vehicle_id')) || undefined,
    driver_name: urlParams.get('client_name') || undefined,
  }
}

const RouteSheetsList = () => {
  const history = useHistory()
  const location = useLocation()

  const { sessionData } = useSelector(createSession)
  const isLeader = getDataOfType(sessionData, 'organization.id', Number, null) === 1
  const isManager = sessionData?.roles.includes(5)

  const [data, setData] = useState<DataType[]>([])
  const [availableFilters, setAvailableFilters] = useState<Enums>({
    vehicle: [],
  })
  const [urlParams] = useState(Object.fromEntries(new URLSearchParams(location.search)))
  const [currentFilters, setCurrentFilters] = useState<currentFiltersProps>({})

  const [loading, setLoading] = useState(false)
  const [importLoading, setImportLoading] = useState(false)
  const [bottomCenter] = useState<TablePaginationPosition>('bottomCenter')
  const [sorter, setSorter] = useState<Sorter>({})
  const [pagination, setPagination] = useState({
    current: Number(urlParams?.page) || 1,
    pageSize: 50,
    position: [bottomCenter],
    showSizeChanger: true,
    total: 0,
  })

  const { counters } = useSelector(createMenuCounters)
  const [localCounter, setLocalCounter] = useState<null | number>(null)
  const [enums, setEnums] = useState<any>({})
  const [selectedDate, setSelectedDate] = useState<string>('')
  const [selectedRouteSheet, setSelectedRouteSheet] = useState<DataType | null>(null)

  const headerRef = useRef<HTMLDivElement | null>(null)

  const jsonSettings = useSelector((state) => getJsonSettingsByKey(state, location.pathname))

  useEffect(() => {
    const newFilters = parseUrlSearchParams(location.search)

    setCurrentFilters(newFilters)
  }, [location.search])

  useEffect(() => {
    const newCounterData = counters?.['/route-sheets']
    if (newCounterData && !localCounter) {
      setLocalCounter(newCounterData)
    }
    if (newCounterData && localCounter && newCounterData !== localCounter) {
      setLocalCounter(newCounterData)
      getData()
    }
  }, [counters])

  const breadcrumbs = useMemo(() => {
    return [
      {
        href: '/logistics/deliveries',
        title: 'Логистика',
      },
      {
        href: '',
        title: 'Маршрутные листы',
        back: true,
      }
    ]
  }, [history])


  const getAvailableFilters = useCallback(() => {
    fetchAPI(`/api/logistics/route-sheets/enum-list`)
      .then(data => {
        setEnums(data)

        const filters = {
          vehicle: data?.vehicle_id?.map((i: any) => ({ label: i.value, value: i.id })) || [],
        }

        setAvailableFilters(filters)
      })
      .catch(() => {
        console.error('Не удалось получить список доступных фильтров')
      })
  }, [])

  const getData = useCallback(() => {
    try {
      setLoading(true)
      const urlParamsObj = Object.fromEntries(new URLSearchParams(location.search))

      fetchAPI(`/api/logistics/route-sheets/list?${objectToUrl(urlParamsObj)}`).then(({ data, pagination, sorter }) => {
        if (data) {
          setData(
            data.map((item: any) => ({
              ...item,
            })),
          )

          pagination.hide = !!selectedDate

          setPagination((prevState) => ({
            ...prevState,
            ...pagination
          }))
          setSorter(sorter)
        }
        setLoading(false)
      })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [location.search])

  useEffect(() => {
    getData()
    getAvailableFilters()
  }, [getData, getAvailableFilters])

  const handleTableChange = (pagination: any, _filters: any, sorter: any) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    setPagination((prevState) => ({ ...prevState, ...pagination }))

    const current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
    } as any

    if(pagination.current) {
      current.page = pagination.current
    }
    if(pagination.pageSize) {
      current.pageSize = pagination.pageSize
    }
    if (current?.page === 1) {
      delete current.page
    }
    if (current?.sort_order === undefined) {
      delete current.sort_order
      delete current.sort_field
    }

    const urlParams = new URLSearchParams(current).toString()
    history.push({ search: urlParams })
    getData()
  }

  const handleRowClick = (record: DataType) => {
    setSelectedRouteSheet(record)
  }

  const handleModalClose = useCallback(() => {
    setSelectedRouteSheet(null)
  }, [])

  const changeFiltersHandler = useCallback((filters: any) => {
    let tmpFilter = { ...(filters || {}), pageSize: pagination.pageSize || 50 }
    const newUrlParams = new URLSearchParams(tmpFilter).toString()
    history.push({ search: newUrlParams })
    //getData()
  }, [pagination.pageSize, getData, history])


  const columns = useMemo(() => getColumns(isLeader || isManager, sorter), [isLeader, isManager, sorter])

  const viewColumns = useMemo(() => {
    const defaultInput = { type: 'string' }

    const enhanceColumn = (column: any) => {
      if (!('dataIndex' in column) || !column.editable) {
        return column
      }

      const dataIndex = column.dataIndex

      return {
        ...column,
        input: {
          ...defaultInput,
          key: dataIndex,
        }
      }
    }

    if (jsonSettings && jsonSettings?.length > 0) {
      const enhancedColumns = columns.map(enhanceColumn)

      let result: any[] = []
      for (let i = 0; i < jsonSettings.length; i++) {
        const item = enhancedColumns.find(
          (column) => jsonSettings[i] === column.dataIndex
        )
        if (item) {
          result.push(item)
        }
      }
      return result
    }

    return columns.map(enhanceColumn)
  }, [columns, jsonSettings, enums])

  const excelPath = useMemo(() => {
    return getPrintPath('/api/logistics/route-sheets/list', new URLSearchParams(location.search))
  }, [location.search])

  const settingsColumns = useMemo(() => {
    return columns.map((column) => ({
      key: 'dataIndex' in column ? column.dataIndex : undefined,
      title: column.title,
    }))
  }, [columns]) as TransferItem[]

  // Определять выбрана ли одна дата доставки в фильтре
  useEffect(() => {
    if (currentFilters?.date) {
      const [startDate, endDate] = currentFilters.date.split('-')

      if (startDate === endDate) {
        setSelectedDate(startDate)
      } else {
        setSelectedDate('')
      }
    }
  }, [currentFilters])

  // Событие изменение фильтров
  const changeDateFilterHandler = (newDate: string) => {
    setLoading(true)
    setData([])
    const urlParams = new URLSearchParams(location.search)

    if (newDate) {
      urlParams.set('date', `${newDate}-${newDate}`)
    } else {
      urlParams.delete('date')
    }

    history.push({ search: urlParams.toString() })
    setSelectedDate(newDate)
  }

  // Событие пагинации
  const handlePageChange = (page: number, pageSize: number) => {
    setPagination((prevState) => ({
      ...prevState,
      current: Number(page) || 1,
      pageSize: pageSize,
    }))

    const currentUrlParams = new URLSearchParams(location.search)
    currentUrlParams.set('page', page.toString())
    currentUrlParams.set('pageSize', pageSize.toString())
    history.push({ search: currentUrlParams.toString() })
  }

  const handleImportToday = useCallback(async () => {
    setImportLoading(true)
    const today = dayjs().format('DD.MM.YYYY')

    try {
      await Promise.all([
        fetchAPI(`/api/logistics/route-sheets/import?date=${today}&center_name=Рязань`),
        fetchAPI(`/api/logistics/route-sheets/import?date=${today}&center_name=Калуга`)
      ])
      getData()
    } catch (error) {
      console.error('Ошибка при импорте данных:', error)
    } finally {
      setImportLoading(false)
    }
  }, [getData])

  return (
    <B2BLayout breadcrumbs={breadcrumbs} isViewScrollButton>
      <FixedHeader ref={headerRef}>
        <Space style={{ width: '100%', justifyContent: 'space-between' }} align={'start'} wrap>
          <Space>
            <DateFilter selectedDate={selectedDate} onChange={changeDateFilterHandler}/>
            <Button
              type="primary"
              onClick={handleImportToday}
              loading={importLoading}
            >
              Выгрузить за сегодняшний день
            </Button>
          </Space>
          <FiltersDrawer
            filters={getFilters({ currentFilters, availableFilters })}
            onChange={changeFiltersHandler}
            currentFilters={currentFilters}
          />
        </Space>
      </FixedHeader>
      <Space style={{ marginBottom: '10px', marginTop: '20px', width: '100%', justifyContent: 'flex-end' }} align={'start'}>
        <HStack gap={'8'} align={'start'}>
          <PrintButton
            path={excelPath}
            columns={viewColumns}
          />
          <TableSettingsButton items={settingsColumns}/>
        </HStack>

      </Space>
      <DataTable
        columns={viewColumns}
        data={data}
        loading={loading}
        rowKey={(r) => String(r.id)}
        pagination={pagination}
        onRowClick={handleRowClick}
        onPageChange={handlePageChange}
        onTableChange={handleTableChange}
        className={'route-sheets-table'}
        headerRef={headerRef}
        maxLines={2}
        bordered
      />
      <RouteSheetsCardModal
        routeSheet={selectedRouteSheet}
        onClose={handleModalClose}
      />
    </B2BLayout>
  )
}

export default RouteSheetsList
