import React, {useCallback, useEffect, useState} from 'react'
import {Drawer, Button, Form, Select, Input, Space} from 'antd'
import {DateRangePicker} from '../../input-date-range/styles'
import {InputInteger, InputPhone} from './elements'
import moment from "moment"
import {FilterOutlined} from '@ant-design/icons'
import {currentFiltersProps} from "../../../pages_v2/deliveries/list/interface";

type FilterType = {
  name: string
  type: string
  placeholder?: string
  label?: string
  value?: any
  options?: any
  disabled?: boolean
  skipReset?: boolean
  style?: React.CSSProperties
}

type TFilterValue = undefined | number | string | readonly string[]

interface FiltersDrawerProps {
  filters: FilterType[]
  onChange: (values: Record<string, any>) => void
  currentFilters: currentFiltersProps
}

const FiltersDrawer: React.FC<FiltersDrawerProps> = ({filters, onChange, currentFilters}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [form] = Form.useForm()
  const [activeFilters, setActiveFilters] = useState(
    Object.fromEntries(
      Object.entries(currentFilters).filter(([, value]) => value !== undefined)
    )
  )

  useEffect(() => {
    const newActiveFilters = Object.fromEntries(
      Object.entries(currentFilters).filter(([, value]) => value !== undefined)
    )
    setActiveFilters(newActiveFilters)

    const fieldsForForm: Record<string, any> = {}

    filters.forEach((f) => {
      if (f.type === 'dates') {
        const dateValue = currentFilters[f.name]
        if (typeof dateValue === 'string' && dateValue.includes('-')) {
          const [start, end] = dateValue.split('-')
          fieldsForForm[f.name] = [
            moment(start, 'YYYY.MM.DD'),
            moment(end, 'YYYY.MM.DD')
          ]
        } else {
          fieldsForForm[f.name] = undefined
        }
      } else if (f.type === 'select_multiple') {
        fieldsForForm[f.name] = currentFilters[f.name] ? currentFilters[f.name].split(',') : undefined
      } else {
        fieldsForForm[f.name] = currentFilters[f.name]
      }
    })

    form.setFieldsValue(fieldsForForm)
  }, [currentFilters, filters, form])

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const handleClearFilters = () => {
    const newActiveFilters = Object.fromEntries(
      Object.entries(activeFilters)
        .filter(([key]) => filters.some(f => f.name === key && f.skipReset))
        .map(([key, value]) => [key, value])
    )

    form.resetFields()
    setActiveFilters(newActiveFilters)
    onChange(newActiveFilters)
  }

  const handleApplyFilters = useCallback(() => {
    const values = form.getFieldsValue()
    const transformedValues = {...values}
    Object.keys(transformedValues).forEach(key => {
      if (Array.isArray(transformedValues[key]) && transformedValues[key][0]?.format) {
        const [start, end] = transformedValues[key]
        transformedValues[key] = `${start.format('YYYY.MM.DD')}-${end.format('YYYY.MM.DD')}`
      }
    })

    const activeFilters = Object.fromEntries(
      Object.entries(transformedValues).filter(([, value]) => value !== undefined)
    )
    setActiveFilters(activeFilters)

    onChange(activeFilters)
    setIsDrawerOpen(false)
  }, [setActiveFilters, onChange])

  const getInput = (filter: FilterType) => {
    const {type, options, placeholder, name, disabled, value} = filter

    switch (type) {
      case 'input-string':
        return (
          <Input
            placeholder={placeholder}
            style={{width: '100%'}}
            defaultValue={value}
            onChange={(e) => form.setFieldsValue({[name]: e.target.value || undefined})}
          />
        )
      case 'input-integer':
        return (
          <InputInteger
            defaultValue={value}
            onChange={(v) => form.setFieldsValue({[name]: v})}
            style={{width: '100%'}}
            placeholder={placeholder}
          />
        )
      case 'phone':
        return (
          <InputPhone
            defaultValue={value}
            onChange={(v) => form.setFieldsValue({[name]: v || undefined})}
          />
        )
      case 'select':
        console.log('options', options)
        return (
          <Select
            allowClear={options?.allowClear !== undefined ? options.allowClear : true}
            placeholder={placeholder || 'Выберите'}
            options={options?.enum || []}
            disabled={disabled || false}
            style={{width: '100%'}}
            showSearch
            optionFilterProp="label"
            onChange={(v) => form.setFieldsValue({[name]: v || undefined})}
          />
        )
      case 'select_multiple':
        return (
          <Select
            allowClear={options?.allowClear !== undefined ? options.allowClear : true}
            placeholder={placeholder || 'Выберите'}
            options={options?.enum || []}
            disabled={disabled || false}
            style={{width: '100%'}}
            onChange={(v) => form.setFieldsValue({[name]: v || undefined})}
            mode='multiple'
          />
        )
      case 'dates':
        return (
          <DateRangePicker
            style={{width: '100%'}}
          />
        )
      default:
        return null
    }
  }
  const renderFilters = filters.map((filter) => (
    <Form.Item
      key={filter.name}
      name={filter.name}
      label={filter.label}
      style={{width: '100%'}}
    >
      {getInput(filter)}
    </Form.Item>
  ))

  return (
    <>
      <Space style={{width: '100%'}}>
        <Button
          type="primary"
          icon={<FilterOutlined/>}
          ghost={Object.keys(activeFilters).length === 0}
          onClick={toggleDrawer}
        >
          Фильтры
        </Button>
        <Button
          onClick={handleClearFilters}
          disabled={Object.keys(activeFilters).length === 0}
        >
          Сбросить фильтры
        </Button>
      </Space>
      <Drawer
        title="Фильтры"
        placement="right"
        onClose={toggleDrawer}
        visible={isDrawerOpen}
        width={400}
        footer={
          <div style={{display: 'flex', gap: 10}}>
            <Button onClick={handleClearFilters} style={{width: '50%'}}>
              Очистить
            </Button>
            <Button type="primary" onClick={handleApplyFilters} style={{width: '50%'}}>
              Применить
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={filters.reduce((acc, cur) => {
            if (
              cur.type === 'dates' &&
              typeof cur.value === 'string' &&
              cur.value.includes('-')
            ) {
              const [start, end] = cur.value.split('-')
              acc[cur.name] = [
                moment(start, 'YYYY.MM.DD'),
                moment(end, 'YYYY.MM.DD'),
              ]
            } else {
              acc[cur.name] = cur.value
            }
            return acc
          }, {} as Record<string, any>)}
        >
          {renderFilters}
        </Form>
      </Drawer>
    </>
  )
}

export default FiltersDrawer
