// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
// Source: https://reactrouter.com/web/example/auth-workflow
import { Redirect, Route } from 'react-router-dom'
import { getRequestedQueryFromLocation } from '../../lib/utils/requested-query'
import React from 'react'

interface PrivateRouteProps {
  children: React.ReactNode
  session: any
  allowedRoles?: number[]
  [key: string]: any
}

export const PrivateRoute = ({ children, session, allowedRoles, ...rest }: PrivateRouteProps) => {
  const isAuthorizedSession = (session: any) => {
    return (
      session?.sessionData &&
      session?.sessionData?.user &&
      session?.sessionData?.user['id'] > 0 &&
      session?.sessionData?.roles &&
      session?.sessionData?.roles.length > 0
    )
  }

  const hasRequiredRole = (roles: number[], allowedRoles?: number[]) => {
    if (!allowedRoles || allowedRoles.length === 0) {
      return true
    }
    return roles.some((role) => allowedRoles.includes(role))
  }

  return (
    /* @ts-ignore */
    <Route
      {...rest}
      render={({ location }) => {
        if (!isAuthorizedSession(session)) {
          return (
            /* @ts-ignore */
            <Redirect
              to={{
                pathname: `/login`,
                search: `?${getRequestedQueryFromLocation(location)}`,
                // state: { from: location }
              }}
            />
          )
        }

        const userRoles = session?.sessionData?.roles || []
        if (!hasRequiredRole(userRoles, allowedRoles)) {
          return (
            /* @ts-ignore */
            <Redirect
              to={{
                pathname: '/', // Или другая страница по умолчанию
                search: `?${getRequestedQueryFromLocation(location)}`,
              }}
            />
          )
        }

        return children
      }}
    />
  )
}