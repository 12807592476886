import React, {useCallback, useRef, useMemo, useEffect, useState} from 'react'
import { DatePicker, Button } from 'antd'
import moment from 'moment'
import { ConfigProvider } from 'antd'
import locale from 'antd/es/locale/ru_RU'
import { IDate } from './interface'

export function InputDate({ value, onChange, UISchema }: IDate) {
  const ref = useRef<any>()
  const [localValue, setLocalValue] = useState<any>({})

  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const style = UISchema?.style

  useEffect( () => {
    setLocalValue(value)
  }, [value])

  const isDisabledDatePrev = useMemo(() => {
    return UISchema?.options?.isDisabledDatePrev ?? true
  }, [UISchema])

  const picker = useMemo(() => {
    return UISchema?.options?.picker ?? ''
  }, [UISchema])

  const format = useMemo(() => {
    return UISchema?.options?.format ?? 'DD.MM.YYYY'
  }, [UISchema])

  const disabledDate = useCallback(date => {
    if(typeof isDisabledDatePrev === 'string') {
      if(isDisabledDatePrev){
        return moment(date).valueOf() <  moment(isDisabledDatePrev).valueOf()
      }
    }
    if(isDisabledDatePrev){
      return moment(date).valueOf() <  moment().subtract(1, "days").valueOf()
    }
    return false
  },[isDisabledDatePrev])

  const setYesterday = useCallback(() => {
    const yesterday = moment().subtract(1, 'days').toISOString()
    onChange(yesterday)
    if (typeof ref.current.blur === 'function') {
      ref.current.blur()
    }
  }, [onChange])

  const setTomorrow = useCallback(() => {
    const yesterday = moment().add(1, 'day').toISOString()
    onChange(yesterday)
    if (typeof ref.current.blur === 'function') {
      ref.current.blur()
    }
  }, [onChange])

  const setWeekLater = useCallback(() => {
    const weekLater = moment().add(7, 'days').toISOString()
    onChange(weekLater)
    if (typeof ref.current.blur === 'function') {
      ref.current.blur()
    }
  }, [onChange])

  const Footer = useCallback(() => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {isDisabledDatePrev ? (
          <Button style={{ border: 'unset', color: '#40a9ff', padding: 0 }} onClick={setTomorrow}>
            Завтра
          </Button>
        ) : (
          <Button style={{ border: 'unset', color: '#40a9ff', padding: 0 }} onClick={setYesterday}>
            Вчера
          </Button>
        )}
        <Button style={{ border: 'unset', color: '#40a9ff', padding: 0 }} onClick={setWeekLater}>
          Через неделю
        </Button>
      </div>
    )
  }, [setWeekLater, setYesterday, setTomorrow, isDisabledDatePrev])

  const handleChange = useCallback(nextValue => {
    onChange(nextValue && nextValue.toISOString && nextValue.toISOString())
  },[onChange, localValue])

  const datePickerProps = {
    ref: ref,
    renderExtraFooter: Footer,
    onChange: handleChange,
    format: format,
    value: localValue && moment(localValue),
    disabledDate: disabledDate,
    disabled: disabled,
    style: style,
    ...(picker && { picker: picker })
  };

  // @ts-ignore
  return (
    <ConfigProvider locale={locale}>
      {/* @ts-ignore */}
      <DatePicker {...datePickerProps} />
    </ConfigProvider>
  )
}

export default InputDate
