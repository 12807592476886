import React, {useEffect, useState, useMemo} from 'react'
import {Space, Modal, Table, Spin, message, Row, Col} from 'antd'
import type {DataType} from '../interface'
import type {currentFiltersProps} from '../interface'
import {
  VehicleCard,
  VehicleCardContainer, VehicleCardGroups,
  VehicleCardIcon,
  VehicleCardInfoCol,
  VehicleCardInfoItem,
  VehicleCardInfoRow,
  VehicleCardTitle, VehicleModalContainer, VehicleModalInfo, VehicleModalInfoItem,
  VehiclePyramidNumber,
  VehicleGroupTag
} from "../styles";
import dayjs from "dayjs";
import fetchAPI from "../../../../../lib/utils/fetch-api";

// Функция для получения единой даты из диапазона
function getSingleDeliveryDateValue(deliveryDateFilter?: string): string | null {
  if (!deliveryDateFilter) return null
  const splitted = deliveryDateFilter.split('-')
  if (splitted.length !== 2) return null
  const [start, end] = splitted
  return start === end ? start : null
}

interface IVehicleSummaryTilesProps {
  data: DataType[]
  currentFilters: currentFiltersProps
}

export const VehicleSummaryTiles: React.FC<IVehicleSummaryTilesProps> = ({
                                                                           data,
                                                                           currentFilters,
                                                                         }) => {
  const [vehicleSummaries, setVehicleSummaries] = useState<any[]>([])
  const [isVehicleSummaryModalOpen, setIsVehicleSummaryModalOpen] = useState(false)
  const [currentVehicleOrders, setCurrentVehicleOrders] = useState<any[]>([])
  const [currentVehicleName, setCurrentVehicleName] = useState<string>('')
  const [vehicleDetails, setVehicleDetails] = useState<any>(null)
  const [vehicleSchedule, setVehicleSchedule] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [zoneGroups, setZoneGroups] = useState<any[]>([])

  const fetchZoneGroups = async () => {
    try {
      const response = await fetchAPI('/api/logistics/deliveries/zone_groups', {
        method: 'GET'
      })
      setZoneGroups(response as any[])
    } catch (error) {
      console.error('Ошибка при загрузке групп территорий:', error)
    }
  }

  useEffect(() => {
    fetchZoneGroups()
  }, [])

  const getGroupColor = (groupName: string): string => {
    const group = zoneGroups.find(g => g.name === groupName)
    return group?.color || '#666666'
  }

  const modalColumns = useMemo(
    () => [
      {
        title: '№',
        dataIndex: 'sequence_in_run',
        key: 'sequence_in_run',
      },
      {
        title: 'Номер ТЗ',
        dataIndex: 'order_reference',
        key: 'order_reference',
        width: 120
      },
      {
        title: 'Дилер',
        dataIndex: 'dealer',
        key: 'dealer',
        width: 150,
      },
      {
        title: 'Адрес',
        dataIndex: 'customer_location_address',
        key: 'customer_location_address',
        render: (text, record) => (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            {text}
          </div>
        ),
        width: 180
      },
      {
        title: 'Телефон клиента',
        dataIndex: 'client_phone',
        key: 'client_phone',
        width: 140,
      },
      {
        title: 'Вес',
        dataIndex: 'capacity',
        key: 'capacity',
        render: (value) => value ? value + 'кг' : ''
      },
      {
        title: 'Объем',
        dataIndex: 'volume',
        key: 'volume',
        render: (value) => value ? value + 'м²' : ''
      },
      {
        title: 'Кол-во',
        dataIndex: 'cost',
        key: 'cost',
        width: 80
      },
      {
        title: 'Дополнительные инструкции',
        dataIndex: 'additional_instructions',
        key: 'additional_instructions',
        render: (text: string, record: DataType) => {
          const style: React.CSSProperties = record.highlight_comment
            ? { backgroundColor: '#fff', color: '#f00', fontWeight: 700, padding: '4px 8px' }
            : {}
          return <span style={style}>{text || ''}</span>
        },
        width: 250
      },
      {
        title: 'Окно заказа',
        dataIndex: 'drop_window_start',
        key: 'drop_window_start',
        render: (value: string, record: DataType) => value && record.drop_window_end
          ? dayjs(value).format('HH:mm') + ' - ' + dayjs(record.drop_window_end).format('HH:mm')
          : '-',
      },
      {
        title: 'Подъем',
        dataIndex: 'lifting',
        key: 'lifting',
      },
      /*{
        title: 'Территория',
        dataIndex: 'zone',
        key: 'zone',
        width: 140
      },
      {
        title: 'Группа',
        dataIndex: 'zone_group',
        key: 'zone_group',
        width: 130
      },*/

      {
        title: 'Стоимость',
        dataIndex: 'delivery_amount',
        key: 'delivery_amount',
      },
    ],
    []
  )

  const handleVehicleCardClick = async (summaryItem: any) => {
    setCurrentVehicleOrders(summaryItem.orders || [])
    setCurrentVehicleName(summaryItem.orders[0]?.vehicle || summaryItem.vehicle_name)
    setIsVehicleSummaryModalOpen(true)

    if (vehicleSchedule[summaryItem.vehicle_name]) {
      setVehicleDetails({
        ...vehicleSchedule[summaryItem.vehicle_name],
        pyramid_number: summaryItem.pyramid_number
      })
      return
    }

    const singleDateStr = getSingleDeliveryDateValue(currentFilters?.delivery_date)
    if (!singleDateStr) return

    const orderData = summaryItem.orders.map((order: any) => (order.id))
    try {
      setLoading(true)  // Включаем прелоадер
      const response = await fetch('/api/schedex/schedule', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orders: orderData,
          date: singleDateStr,
        }),
      })

      const result = await response.json()
      if (response.ok) {
        setVehicleSchedule(prev => ({
          ...prev,
          ...result.schedule
        }))
        setVehicleDetails({
          ...result.schedule[summaryItem.vehicle_name],
          pyramid_number: summaryItem.pyramid_number
        })
      } else {
        message.error(`Ошибка: ${result.error || 'Неизвестная ошибка'}`)
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        message.error(`Ошибка при отправке запроса: ${error.message}`)
      } else {
        message.error('Неизвестная ошибка при отправке запроса')
      }
    } finally {
      setLoading(false)
    }
  }


  const handleCloseVehicleSummaryModal = () => {
    setCurrentVehicleOrders([])
    setVehicleDetails(null)
    setIsVehicleSummaryModalOpen(false)
  }

  useEffect(() => {
    const singleDateStr = getSingleDeliveryDateValue(currentFilters?.delivery_date)
    if (!singleDateStr) {
      setVehicleSummaries([])
      return
    }

    const filteredOrders = data.filter((order) => {
      if (!order.delivery_date) return false
      const orderDate = dayjs(order.delivery_date).format('YYYY.MM.DD')

      return orderDate == singleDateStr
    })

    const validOrders = filteredOrders.filter(
      (o) => o.pyramid_number && o.vehicle_id && o.sequence_in_run
    )

    const groupedByVehicle: Record<string, DataType[]> = {}
    validOrders.forEach((o) => {
      const vId = String(o.vehicle_id)
      if (!groupedByVehicle[vId]) {
        groupedByVehicle[vId] = []
      }
      groupedByVehicle[vId].push(o)
    })

    const summaries = Object.keys(groupedByVehicle).map((vehicleId) => {
      const ordersArr = groupedByVehicle[vehicleId]
      let totalCapacity = 0
      let totalVolume = 0
      let totalCost = 0
      let totalDeliveryAmount = 0

      ordersArr.forEach((ord) => {
        totalCapacity += Number(ord.capacity) || 0
        totalVolume += Number(ord.volume) || 0
        totalCost += Number(ord.cost) || 0
        totalDeliveryAmount += Number(ord.delivery_amount) || 0
      })

      const groups = [...new Set(ordersArr.map(order => order.zone_group))]
        .filter(Boolean)
        .join(', ')

      return {
        vehicle_id: vehicleId,
        pyramid_number: ordersArr[0].pyramid_number,
        vehicle_name: ordersArr[0].vehicle,
        total_capacity: totalCapacity,
        total_volume: totalVolume,
        total_cost: totalCost,
        total_delivery_amount: totalDeliveryAmount,
        orders: ordersArr,
        groups: groups,
      }
    })

    const sortedSummaries = summaries.sort((a, b) => {
      const aNum = a.pyramid_number ? +a.pyramid_number : 0;
      const bNum = b.pyramid_number ? +b.pyramid_number : 0;
      return aNum - bNum;
    })

    setVehicleSummaries(sortedSummaries)
  }, [data, currentFilters])

  const totalWeight = currentVehicleOrders.reduce(
    (acc, ord) => acc + Number(ord.capacity || 0),
    0
  )

  const totalVolume = currentVehicleOrders.reduce(
    (acc, ord) => acc + Number(ord.volume || 0),
    0
  )

  const totalDeliveryAmount = currentVehicleOrders.reduce(
    (acc, ord) => acc + Number(ord.delivery_amount || 0),
    0
  )

  if (!vehicleSummaries || vehicleSummaries.length === 0) {
    return null
  }

  return (
    <div style={{ marginBottom: 20, marginTop: 20 }}>
      <VehicleCardContainer>
        {vehicleSummaries.map((item) => (
          <VehicleCard
            key={item.pyramid_number}
            onClick={() => handleVehicleCardClick(item)}
          >
            <VehicleCardTitle>
              <VehicleCardIcon>
                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" height="24px" width="24px" viewBox="0 0 512 512">
                  <path
                    d="M507.699,229.009l-51.2-76.809c-4.745-7.125-12.74-11.401-21.299-11.401H332.8v-25.6c0-14.14-11.46-25.6-25.6-25.6H25.6    C11.46,89.6,0,101.06,0,115.2v230.4c0,14.14,11.46,25.6,25.6,25.6h65.297c5.948,29.175,31.795,51.2,62.703,51.2    c30.908,0,56.755-22.025,62.703-51.2h117.794c5.948,29.175,31.795,51.2,62.703,51.2s56.755-22.025,62.703-51.2H486.4    c14.14,0,25.6-11.46,25.6-25.6V243.209C512,238.157,510.507,233.216,507.699,229.009z M153.6,396.8    c-21.205,0-38.4-17.195-38.4-38.4s17.195-38.4,38.4-38.4c21.205,0,38.4,17.195,38.4,38.4S174.805,396.8,153.6,396.8z M307.2,345.6    h-90.897c-5.948-29.175-31.795-51.2-62.703-51.2c-30.908,0-56.755,22.025-62.703,51.2H25.6V320h25.6v-25.6H25.6V115.2h281.6V345.6    z M332.8,166.4h102.4l45.756,68.642l-68.25-17.067c-1.015-0.247-2.056-0.375-3.106-0.375h-76.8V166.4z M396.8,396.8    c-21.205,0-38.4-17.195-38.4-38.4s17.195-38.4,38.4-38.4c21.205,0,38.4,17.195,38.4,38.4S418.005,396.8,396.8,396.8z M486.4,281.6    h-25.6v25.6h25.6v38.4h-26.897c-5.948-29.175-31.795-51.2-62.703-51.2s-56.755,22.025-62.703,51.2H332.8V243.2h75.221    l78.379,19.601V281.6z"/>
                </svg>
              </VehicleCardIcon>
              {item.vehicle_name}
            </VehicleCardTitle>
            {!isNaN(Number(item.pyramid_number)) && (
              <VehiclePyramidNumber className={`pyramid-${item.pyramid_number}`}>
                {item.pyramid_number}
              </VehiclePyramidNumber>
            )}
            {item.groups && (
              <VehicleCardGroups>
                {item.groups.split(', ').map((group) => (
                  <VehicleGroupTag
                    key={group}
                    color={getGroupColor(group)}
                  >
                    {group}
                  </VehicleGroupTag>
                ))}
              </VehicleCardGroups>
            )}
            <br/>
            <VehicleCardInfoRow>
              <VehicleCardInfoCol>
                <VehicleCardInfoItem><b>Кол-во заказов:</b> {item.orders.length}</VehicleCardInfoItem>
                <VehicleCardInfoItem><b>Вес:</b> {parseFloat(item.total_capacity).toFixed(2)}кг</VehicleCardInfoItem>
                <VehicleCardInfoItem><b>Объем:</b> {parseFloat(item.total_volume).toFixed(2)}м²</VehicleCardInfoItem>
              </VehicleCardInfoCol>
              <VehicleCardInfoCol>
                <VehicleCardInfoItem><b>Кол-во изделий:</b> {parseFloat(item.total_cost)}</VehicleCardInfoItem>
                <VehicleCardInfoItem><b>Сумма
                  доставки:</b><br/> {parseFloat(item.total_delivery_amount).toFixed(2)} руб.</VehicleCardInfoItem>
              </VehicleCardInfoCol>
            </VehicleCardInfoRow>
          </VehicleCard>
        ))}
      </VehicleCardContainer>
      <Space wrap/>

      <Modal
        title={currentVehicleName}
        visible={isVehicleSummaryModalOpen}
        onCancel={handleCloseVehicleSummaryModal}
        width={1600}
      >
        <VehicleModalContainer>
          <Spin spinning={loading} style={{ minHeight: '200px' }}>
            {vehicleSchedule[currentVehicleName] ? (
              <VehicleModalInfo>
                <Row gutter={16}>
                  <Col span={6}>
                    <VehicleModalInfoItem><b>Дата:</b> {vehicleDetails?.delivery_date || '-'}</VehicleModalInfoItem>
                    <VehicleModalInfoItem><b>Загрузка:</b> {vehicleDetails?.loading_time || '-'}</VehicleModalInfoItem>
                    <VehicleModalInfoItem>
                      <b>Возвращение:</b> {vehicleDetails?.return_time || '-'}
                      <div>
                        <b>Группы:</b>{' '}
                        {[...new Set(currentVehicleOrders.map(order => order.zone_group))]
                          .filter(Boolean)
                          .join(', ')}
                      </div>
                    </VehicleModalInfoItem>
                  </Col>
                  <Col span={6}>
                    <VehicleModalInfoItem><b>Склад:</b> {vehicleDetails?.center_name || '-'}</VehicleModalInfoItem>
                    <VehicleModalInfoItem><b>Машина:</b> {vehicleDetails?.vehicle_name || '-'}</VehicleModalInfoItem>
                    <VehicleModalInfoItem><b>Водитель:</b> {vehicleDetails?.driver_name || '-'}</VehicleModalInfoItem>
                  </Col>
                  <Col span={6}>
                    <VehicleModalInfoItem><b>Суммарное время:</b> {vehicleDetails?.total_travel_time || '-'}</VehicleModalInfoItem>
                    <VehicleModalInfoItem><b>Расстояние:</b> {vehicleDetails?.distance || '-'} км</VehicleModalInfoItem>
                    <VehicleModalInfoItem><b>Пирамида:</b> {vehicleDetails?.pyramid_number || '-'}</VehicleModalInfoItem>
                  </Col>
                  <Col span={6}>
                    <VehicleModalInfoItem><b>Итоговый вес:</b> {parseFloat(totalWeight).toFixed(2)} кг</VehicleModalInfoItem>
                    <VehicleModalInfoItem><b>Итоговый объем:</b> {parseFloat(totalVolume).toFixed(2)} м²</VehicleModalInfoItem>
                    <VehicleModalInfoItem><b>Итоговая стоимость:</b> {parseFloat(totalDeliveryAmount).toFixed(2)} руб.</VehicleModalInfoItem>
                  </Col>
                </Row>
              </VehicleModalInfo>
            ) : !loading && (
              <div style={{ marginBottom: 20 }}>
                <h3>Не удалось получить данные о маршруте из Шедекс</h3>
              </div>
            )}
            {!loading && (
              <Table
                columns={modalColumns}
                dataSource={
                  currentVehicleOrders
                    ?.slice()
                    ?.sort((a, b) => Number(a.sequence_in_run) - Number(b.sequence_in_run))
                }
                rowKey="order_reference"
                pagination={false}
              />
            )}
          </Spin>
        </VehicleModalContainer>
      </Modal>
    </div>
  )
}
