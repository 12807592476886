import dayjs from 'dayjs'

export function useFormatDate(date?: string): string {
    if (!date) {
        return ''
    }
    const time = dayjs(date).format('HH:mm')
    const isTomorrow = dayjs().add(1, 'day').format('DD.MM.YYYY') === dayjs(date).format('DD.MM.YYYY')
    if (isTomorrow) {
        return `завтра, ${time}`
    }
    const isToday = dayjs().format('DD.MM.YYYY') === dayjs(date).format('DD.MM.YYYY')
    if (isToday) {
        return `сегодня, ${time}`
    }
    const isYesterday = dayjs().subtract(1, 'day').format('DD.MM.YYYY') === dayjs(date).format('DD.MM.YYYY')
    if (isYesterday) {
        return `вчера, ${time}`
    }
    return dayjs(date).format('D MMMM, HH:mm')
}
