import React, { memo, useCallback, useMemo } from 'react'
import { HStack } from '../../../../../../ui/Stack'
import { useSelector } from 'react-redux'
import { createSession, getCardID, getCardStages } from '../../../selectors'
import { Button } from 'antd'
import { useDispatch } from 'react-redux'
import { updateFormData } from '../../../../../../store/claim_request/action-creators'

export const FormHeader: React.FC = memo(() => {
  const id = useSelector(getCardID)
  const stageObj = useSelector(getCardStages)
  const { sessionData } = useSelector(createSession)

  const buttonsDisabled = useMemo(() => {
    if (
      stageObj?.items !== undefined &&
      stageObj?.current !== undefined &&
      sessionData?.roles.includes(29)
    ) {
      return stageObj?.items?.[stageObj?.current]?.code !== 'new'
    }
    return true
  }, [stageObj])

  const dispatch = useDispatch()

  const toClaim = useCallback(() => {
    dispatch(updateFormData(id, { stage: 'approved' }))
  }, [id, dispatch])

  return (
    <HStack gap={'8'} className={'mapWrapper'}>
      <HStack gap={'8'} max justify={'start'} className={'h3'}>
        {!id ? 'Новая заявка на рекламацию' : `Заявка на рекламацию №${id}`}
      </HStack>
      <HStack gap={'8'} max justify={'end'}>
        <Button
          type={'primary'}
          onClick={toClaim}
          disabled={buttonsDisabled}
        >
          Принять
        </Button>
      </HStack>
    </HStack>
  )
})
