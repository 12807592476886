export const TimeTableSlots = {
    '9': {
        start: 9,
        end: 11,
        view: '09.00 - 11.00'
    },
    '11': {
        start: 11,
        end: 13,
        view: '11.00 - 13.00'
    },
    '13': {
        start: 13,
        end: 15,
        view: '13.00 - 15.00'
    },
    '15': {
        start: 15,
        end: 17,
        view: '15.00 - 17.00'
    },
    '17': {
        start: 17,
        end: 19,
        view: '17.00 - 19.00'
    },
} as const
