import dayjs from 'dayjs'

interface FilterOption {
  label: string
  value: string
}

interface Filter {
  name: string
  label: string
  type: string
  value?: any
  options?: {
    enum?: FilterOption[] | string[]
  }
  skipReset?: boolean
}

export const getFilters = ({ currentFilters, availableFilters }): Filter[] => [
  {
    name: 'order_reference',
    label: '№ ТЗ',
    type: 'input-string',
    value: currentFilters?.order_reference
  },
  {
    name: 'pyramid_number',
    label: 'Пирамида №',
    type: 'select',
    value: currentFilters?.pyramid_number,
    options: {
      enum: availableFilters?.pyramid_number
    }
  },
  {
    name: 'distribution_centre_name',
    label: 'Склад',
    type: 'select_multiple',
    value: currentFilters?.distribution_centre_name?.split(',').filter(Boolean),
    options: {
      enum: [
        { label: 'Рязань', value: 'Рязань' },
        { label: 'Калуга', value: 'Калуга' }
      ]
    }
  },
  {
    name: 'dealer',
    label: 'Дилер',
    type: 'input-string',
    value: currentFilters?.dealer
  },
  {
    name: 'client_phone',
    label: 'Телефон клиента',
    type: 'phone',
    value: currentFilters?.client_phone
  },
  {
    name: 'stage',
    label: 'Стадия',
    type: 'select_multiple',
    value: currentFilters?.stage?.split(',').filter(Boolean),
    options: {
      enum: availableFilters?.stage || []
    }
  },
  {
    name: 'delivery_date',
    label: 'Плановая дата доставки',
    type: 'dates',
    value: currentFilters?.delivery_date,
    skipReset: currentFilters?.delivery_date
      ? dayjs(currentFilters.delivery_date.split('-')[0]).isSame(dayjs(currentFilters.delivery_date.split('-')[1]))
      : false
  },
  {
    name: 'old_delivery_date',
    label: 'Дата отгрузки',
    type: 'dates',
    value: currentFilters?.old_delivery_date
  },
  {
    name: 'vehicle_id',
    label: 'Машина',
    type: 'select',
    value: currentFilters?.vehicle_id,
    options: {
      enum: availableFilters?.vehicle
    }
  },
  {
    name: 'delivery_complexity',
    label: 'Сложность',
    type: 'input-integer',
    value: currentFilters?.delivery_complexity
  },
  {
    name: 'order_status_in_wd',
    label: 'Статус заказа в WD',
    type: 'select',
    value: currentFilters?.order_status_in_wd,
    options: {
      enum: availableFilters?.order_status_in_wd
    }
  },
  {
    name: 'payment_verified',
    label: 'Оплата проверена',
    type: 'select',
    value: currentFilters?.payment_verified,
    options: {
      enum: [
        { label: 'Да', value: 'y' },
        { label: 'Нет', value: 'n' }
      ]
    }
  },
  {
    name: 'type',
    label: 'Тип',
    type: 'select',
    value: currentFilters?.type,
    options: {
      enum: [
        { label: 'Доставка', value: 'Доставка' },
        { label: 'Сбор', value: 'Сбор' },
        { label: 'Довоз', value: 'Довоз' },
        { label: 'Долг', value: 'Долг' }
      ]
    }
  },
  {
    name: 'capacity',
    label: 'Вес заказа',
    type: 'input-integer',
    value: currentFilters?.capacity
  },
  {
    name: 'volume',
    label: 'Объем',
    type: 'input-integer',
    value: currentFilters?.volume
  },
  {
    name: 'cost',
    label: 'Количество изделий',
    type: 'input-integer',
    value: currentFilters?.cost
  }
]