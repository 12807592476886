import { List, Modal } from 'antd'
import { IDataByDayItem } from '../../model/selectors'
import React, { memo } from 'react'
import { TaskIcon } from './styles'
import { Link } from 'react-router-dom'
import HeaderWithDateTemplate from '../header-with-date-template/header-with-date-template'
import HeaderWithTextTemplate from '../header-with-text-template/header-with-text-template'

interface TaskDetailModalProps {
  isOpen: boolean
  onCancel: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined
  data: IDataByDayItem[]
}

export const TaskDetailModal: React.FC<TaskDetailModalProps> = memo((props: TaskDetailModalProps) => {
  const {
    isOpen,
    onCancel,
    data,
  } = props

  return (
    <Modal
      footer={null}
      visible={isOpen}
      onCancel={onCancel}
      destroyOnClose={true}
    >
      <List
        itemLayout='horizontal'
        style={{maxHeight: '600px', overflowY: 'scroll'}}
        dataSource={data}
        renderItem={(item: IDataByDayItem) => (
          <List.Item key={item.id} style={{flexDirection: 'column', alignItems: 'start'}}>
            <HeaderWithTextTemplate
              title={<><TaskIcon $stage={item.stage}/> Задача №{item.id}: {item.type}</>}/>
            {Boolean(item?.brand) &&
              <HeaderWithTextTemplate title={'Бренд:'} text={item?.brand}/>}
            {Boolean(item?.order_attribute) &&
              <HeaderWithTextTemplate title={'Признак заказа:'} text={item?.order_attribute}/>}
            {Boolean(item?.production_number) &&
              <HeaderWithTextTemplate title={'Номер произ.заказа:'} text={item?.production_number}/>}
            {Boolean(item?.address) &&
              <HeaderWithTextTemplate title={'Адрес:'} text={item?.address}/>}
            {Boolean(item?.responsible) &&
              <HeaderWithTextTemplate title={'Ответственные:'} text={item?.responsible?.join(', ')}/>}
            {Boolean(item?.task) &&
              <HeaderWithTextTemplate title={'Комментарий:'} text={item?.task}/>}
            <HeaderWithDateTemplate date={item.deadline} title={'Крайний срок:'}/>
            <HeaderWithTextTemplate
              title={<Link
                to={`/${item.card_type === 'case' ? 'order' : item.card_type}/${item.card_id}`}
              >
                Перейти в запись №{item.card_id}
              </Link>
              }
            />
          </List.Item>
        )}
      />
    </Modal>
  )
})
