import React, { memo, useCallback, useEffect, useState } from 'react'
import { HStack } from '../../../../ui/Stack'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

interface IButton{
  name: string
  isActive: boolean
  disabled?: boolean
  types: number[]
}

interface IFilterButtons {
  onChange: (filters: any, isButtonChange?: boolean) => void
  disabled: boolean
  buttonsList: IButton[]
}

export const QuickFilterButtons = memo((props: IFilterButtons) => {
  const { disabled, onChange, buttonsList } = props
  const [buttons, setButtons] = useState<IButton[]>(buttonsList)
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled)
  const history = useHistory()

  const onClickButton = useCallback((button: IButton) => () => {
    const newState = buttons.map(b => b.types === button.types ? { ...b, isActive: !b.isActive } : b)
    setButtons(newState)
    const mergeUniqueArrays = (...arrays) => Array.from(new Set([].concat(...arrays)))
    const activeTypes = mergeUniqueArrays(...newState.filter(b => b.isActive).map(i => i.types))
    history.push({
      search: new URLSearchParams({ task_type: activeTypes.join(',') }).toString()
    })
    onChange(activeTypes)
  }, [onChange, setButtons, buttons, history.location])

  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(history.location.search)
    const activeTypes = urlSearchParams.get('task_type')?.split(',')
    const newState = buttons.map(b => {
      if (
        activeTypes && b.types
          .map(String)
          .every(stage => activeTypes.includes(stage))) {
        return { ...b, isActive: true }
      } else {
        return { ...b, isActive: false }
      }
    })
    setButtons(newState)
  }, [history.location])

  return (
    <HStack gap='16' justify='start' max>
      {buttons?.map(button => (
        <Button
          disabled={button?.disabled || isDisabled}
          key={button.name}
          type={button.isActive ? 'primary' : 'default'}
          onClick={onClickButton(button)}
        >
          {button.name}
        </Button>
      ))}
    </HStack>
  )
})
