import styled from 'styled-components'

export const UserProfile = styled.div`
  background: none;
  padding: 15px;
  user-select: none;
  cursor: pointer;
  .ant-layout-sider-collapsed & {
    display: none;
  }
`

export const MapWrapper = styled.div`
  padding: 10px 0px;
`

export const MapTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 12px;
  span{
    color: #c5bfbf;
    margin-left: 5px;
  }
`

export const MapContent = styled.div`
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  background: white;
  padding-right: 36px;
  padding-bottom: 36px;
  padding-left: 36px;
`

export const TabContent = styled.div`
  padding: 10px
`

export const AlertWrapper = styled.div`
  margin-bottom: 40px;
`

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 16px;
`

export const TaskWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`
export const FileTabCtr = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 1.5rem;
  .file-drag {
    max-height: 260px;
  }
  .timeline {
    margin-top: 3rem;
  }
`
