import { BoxClient } from './box-client-v2'
import { notification} from 'antd'
import React, { memo, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import fetchAPI from '../../../lib/utils/fetch-api'
import { ArgsProps } from 'antd/es/notification'

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

const TopMenu = () => {
  const {sessionData} = useSelector(createSession)
  const history = useHistory()
  const [isOpen, setOpen] = useState<boolean>(false)
  const [clientId, setClientId] = useState(753)

  interface IOpenNotification {
    phone?: string
    name?: string
    office_name?: string
  }

  const openNotification = (data: IOpenNotification, onClick: () => void) => {
    const description = (<>
      <div style={{ fontSize: '16px', marginBottom: '6px' }}>{data?.office_name}</div>
      <div>{data?.phone || ''} {' ' + (data?.name || '')}</div>
    </>)
    const args: ArgsProps = {
      message: <>Входящий звонок</>,
      key: data?.phone,
      description,
      duration: 0,
      style: { background: '#88baff', userSelect: 'none' },
      onClick
    }
    notification.open(args)
  }

  interface IDataNoteProduct {
    parent_id: string | number
    data: {
      attributes: {
        'production-order-number': string
      }
    }
  }

  const openNotificationProduction = async (data: IDataNoteProduct, onClick: () => void) => {
    const payments = await fetchAPI(`/api/web-hooks/add-payment-contract`, {
      method: 'POST', body: JSON.stringify({
        'targetId': Number(+data.parent_id),
        pay: {
          sum: 0,
          date: ''
        }
      })
    })

    const hasDebt = +payments.sum - +payments.paymentsSumAll > 0
    const message = (<>
      Заказ №{data.parent_id} по производственному заказу №{data.data.attributes['production-order-number']} готов
      к отгрузке
      {hasDebt ? (<><br/><span>по нему есть задолженность!</span></>) : null}
    </>)
    const args: ArgsProps = {
      message: message,
      duration: 0,
      style: {background: hasDebt ? '#e38d82' : '#45c915'},
      onClick
    }
    notification.open(args)
  }

  useEffect(() => {
    const url = '/api/web-hooks/sse'
    const eventSource = new EventSource(url, { withCredentials: true })
    eventSource.addEventListener('manufactureIsReady', async event => {
      const data = JSON.parse(event.data)
      if (sessionData.roles.includes(2) && sessionData.organization.id === +data.owner_organization) {
        const onClick = () => {
          history.push(`/order/${data.id}/edit`)
        }
        await openNotificationProduction(data, onClick)
      }
    })

    eventSource.addEventListener('clientCall', event => {
      const data = JSON.parse(event.data)
      const onClick = () => {
        setClientId(data?.['clientId'] || 0)
        setTimeout(() => {
          setOpen(true)
        }, 500)
      }
      openNotification(data, onClick)
    })

    return () => {
      eventSource.close()
    }

  }, [sessionData])

  return <BoxClient
    isOpen={isOpen}
    setOpen={() => {
      setOpen(false)
    }}
    id={clientId}
  />
}

export default memo(TopMenu)
