import React, { Suspense, useCallback, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import B2BLayout from '../../layouts/b2b'
import { TasksCalendar } from '../../components/v2/tasks-calendar'
import { getTaskTypeHrTasksList } from '../../store/hr/action-creators'
import { getTaskCaseEnum, getTasksListToCalendar } from '../../store/calendar/action-creators'
import { HStack } from '../../ui/Stack'
import { ModalCreateTaskByHrResponse } from '../../views/modal-create-task-searchByHrResponse'
import { selectCalendarOptions } from './selectors'
import { QuickFilterButtons } from './ui/filter-buttons/filter-buttons'
import { createSession, getTaskCalendarCaseTypesData } from '../../components/v2/tasks-calendar/model/selectors'
import { ITaskCalendarFilter } from '../../store/calendar/interfaces'

const breadcrumbs = [{
  href: '/tasks',
  title: 'Задачи',
}]

const AdminNewObjectPage = () => {
  const { sessionData } = useSelector(createSession)
  const isGoLeader = sessionData.roles.includes(19)
  const isDealerLeader = sessionData.roles.includes(25) && !isGoLeader
  const isSaleManager = sessionData.roles.includes(3) && !isGoLeader && !isDealerLeader
  const isHrManager = sessionData.roles.includes(30) && !isGoLeader && !isDealerLeader
  const dispatch = useDispatch()
  const store = useStore()
  const options = selectCalendarOptions(store.getState())
  const [filters, setFilters] = React.useState<ITaskCalendarFilter>({})
  const enumsCase = useSelector(getTaskCalendarCaseTypesData)

  const montageTypes = enumsCase?.task_type?.filter(item => item.value === 'Сделать монтаж').map(i => i.id)
  const measurementTypes = enumsCase?.task_type?.filter(item => item.value === 'Сделать замер').map(i => i.id)
  const deliveryTypes = enumsCase?.task_type?.filter(item => item.value === 'Сделать доставку').map(i => i.id)
  const saleTypes = enumsCase?.task_type?.filter(item =>
    ![
      ...montageTypes,
      ...measurementTypes,
      ...deliveryTypes,
    ].includes(item.id)
  ).map(i => i.id)

  useEffect(() => {
    let mounted = true
    if (mounted) {
      if (isSaleManager || isDealerLeader) {
        dispatch(getTaskCaseEnum())
      }
      if (isHrManager) {
        dispatch(getTaskTypeHrTasksList())
      }
      if (isGoLeader) {
        dispatch(getTaskCaseEnum())
        dispatch(getTaskTypeHrTasksList())
      }
    }
    return () => {
      mounted = false
    }
  }, [dispatch])

  const getTasksList = useCallback(filter => {
    setFilters(filter)
  }, [setFilters])

  const handlerChangeQuickFilter = useCallback(filter => {
    setFilters(filters => ({ ...filters, task_type: filter }))
  }, [setFilters])

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      dispatch(getTasksListToCalendar(filters))
    }
  }, [filters])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <HStack align={'end'} justify={'between'} style={{ marginBottom: '20px' }}>
        <Suspense fallback=''>
          {options.length > 0 && options.includes('show_quick_filters_in_task_list_and_calendar') && (
            <QuickFilterButtons
              buttonsList={[
                {
                  name: 'Продажи',
                  isActive: false,
                  types: saleTypes,
                },
                {
                  name: 'Замеры',
                  isActive: false,
                  types: measurementTypes,
                },
                {
                  name: 'Доставка',
                  disabled: true,
                  isActive: false,
                  types: deliveryTypes,
                },
                {
                  name: 'Монтажи',
                  isActive: false,
                  types: montageTypes,
                },
              ]}
              onChange={handlerChangeQuickFilter}
              disabled={false}
            />
          )}
          <ModalCreateTaskByHrResponse
            entityType='hr'
            titleInputSearch='ID отклика'
          />
        </Suspense>
      </HStack>
      <TasksCalendar
        getList={getTasksList}
      />
    </B2BLayout>
  )
}

export default AdminNewObjectPage
