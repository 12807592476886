import { StageContainer } from '../../appeals/list/columns'
import dayjs from 'dayjs'
import { Value } from '../../elements'
import { Badge } from 'antd'
import React from 'react'
import { ISorterProps } from './interfaces'

const formatPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return <Value data={undefined}/>
  }
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`
  }
  return phoneNumber
}

export const columns = (sorter: ISorterProps) => {
  const columnsArr = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      sorter: true,
    },
    {
      title: 'Источник',
      dataIndex: 'source',
      key: 'source',
      width: 100,
      sorter: true,
    },
    {
      title: 'Стадия',
      dataIndex: 'stage',
      key: 'stage',
      sorter: true,
      width: 150,
      render: stage => <StageContainer stage={stage} />
    },
    {
      title: 'Дата обращения',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      width: 120,
      render: val => val ? dayjs(val).format('DD.MM.YYYY') : <Value data={val}/>,
    },
    {
      title: 'Дата запуска заказа',
      dataIndex: 'date_application',
      key: 'date_application',
      sorter: true,
      width: 120,
      render: val => val ? dayjs(val).format('DD.MM.YYYY') : <Value data={val}/>,
    },
    {
      title: 'Ответственный дилер',
      dataIndex: 'dealer_code',
      key: 'dealer_code',
      width: 220,
      render: val => <Value data={val}/>
    },
    {
      title: 'Ответственный менеджер',
      dataIndex: 'created_by',
      key: 'created_by',
      width: 200,
      render: val => <Value data={val}/>
    },
    {
      title: 'Номер тех. задания',
      dataIndex: 'num_tech',
      key: 'num_tech',
      width: 150,
    },
    {
      title: 'Новые сообщения',
      dataIndex: 'not_read',
      key: 'not_read',
      render: val => +val > 0 ? (<Badge count={+val}/>) : '-',
      align: 'center',
      sorter: true,
      width: 100,
    },
    {
      title: 'Адрес объекта',
      dataIndex: 'address',
      key: 'address',
      sorter: true,
      width: 300,
    },
    {
      title: 'ФИО',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: 200,
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      render: formatPhoneNumber,
      width: 150,
    },
    {
      title: 'Классификация',
      dataIndex: 'classification',
      key: 'classification',
      width: 150,
    },
    {
      title: 'Суть претензии',
      dataIndex: 'claim',
      key: 'claim',
      width: 400,
    },
    {
      title: 'Мероприятия дилера',
      dataIndex: 'dealer_event',
      key: 'dealer_event',
      width: 400,
    },
    {
      title: 'Требования дилера',
      dataIndex: 'dealer_requirement',
      key: 'dealer_requirement',
      width: 150,
    },
  ] as any[]

  return columnsArr.map(item => {
    if (item.key === sorter.sort_field) {
      item.sortOrder = sorter.sort_order
    } else {
      delete item.sortOrder
    }
    return item
  })
}
