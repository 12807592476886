import { createSelector } from 'reselect'
import { convertData2JsonForm } from '../../../../shared/lib/convertData2JsonForm'
import { objectMap } from '../../../../../lib/utils/get-table-transform-schema-and-form-data'
import { addEnumValBySchema } from '../../../../shared/lib/addEnumValBySchema'
import { getDeliveriesEnumsData, getDeliveriesFormData } from '../../../deliveries/card/selectors'

export const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)
const selectClaimRequest = state => state.claimRequest

export const getDataLoading = createSelector(
  selectClaimRequest,
  ({ isLoading }) => isLoading
)
export const getCardData = createSelector(
  selectClaimRequest,
  ({ form }) => form
)

export const getCardStages = createSelector(
  selectClaimRequest,
  ({ stages }) => stages
)
export const getCardStagesItems = createSelector(
  getCardStages,
  ({ items }) => items
)
export const getCardStagesHistory = createSelector(
  getCardStages,
  ({ history }) => history
)
export const getCardCurrentStage = createSelector(
  getCardStages,
  ({ current }) => current
)

export const getFormData = createSelector(
  selectClaimRequest,
  ({ form }) => form
)
export const getCardError = createSelector(
  getFormData,
  ({ error }) => error
)
export const getCardID = createSelector(
  getFormData,
  ({ id }) => id
)

export const getVisibleFormData = createSelector(
  [getFormData],
  formData => {
    if (formData) {
      const { data: form } = formData

      if (!form) {
        return undefined
      }

      const {
        resultObject,
        resultSchema
      } = convertData2JsonForm(form)

      return { resultSchema, resultObject }
    }
  }
)