import { Badge, BadgeProps } from 'antd'
import React, { memo, useCallback } from 'react'
import { Moment } from 'moment'

interface ListDataItem {
  type: string
  content: string
}

interface DefaultCellTemplateProps {
  listData: ListDataItem[]
  value: Moment
  clickCell: (value: Moment) => void
}

export const DefaultCellTemplate = memo((props: DefaultCellTemplateProps) => {
  const {listData, value, clickCell} = props

  const handleClickCell = useCallback((value: Moment) => () => {
    clickCell(value)
  }, [clickCell])

  return (
    <ul onClick={handleClickCell(value)} style={{height: '100%', cursor: 'pointer'}}>
      {listData.map(item => (
        <li key={item.content}>
          <Badge status={item.type as BadgeProps['status']} text={item.content}/>
        </li>
      ))}
    </ul>
  )
})
