import styled from 'styled-components'

export const VehicleModalContainer = styled.div`
  min-height: 200px;
  position: relative;
`

export const VehicleModalInfo = styled.div`
  margin-bottom: 20px;
`

export const VehicleModalInfoItem = styled.div`
  font-size: 16px;
`
