import {ActivityItemFile, ActivityItemFileWDate, ActivityItems, ActivityUpdateItem, FileFolderData, ITimelineResponse} from "../../components/v2/activity-timeline/types/types";

export const TIMELINE_LOADING = 'TIMELINE_LOADING'
export const TIMELINE_DATA = 'TIMELINE_DATA'
export const TIMELINE_FAILED = 'TIMELINE_FAILED'
export const TIMELINE_CLEAR = 'TIMELINE_CLEAR'
export const TIMELINE_ADD_ITEM = 'TIMELINE_ADD_ITEM'
export const TIMELINE_UPDATE_ITEM = 'TIMELINE_UPDATE_ITEM'
export const TIMELINE_DELETE_ITEM = 'TIMELINE_DELETE_ITEM'
export const TIMELINE_ADD_FILE_TO_FOLDER = 'TIMELINE_ADD_FILE_TO_FOLDER'
export const TIMELINE_CLEAR_FILE_FOLDER = 'TIMELINE_CLEAR_FILE_FOLDER'

export function setTimelineLoading() {
    return async dispatch => {
        dispatch({type: TIMELINE_LOADING})
    }
}

export function setTimelineData(formData: ITimelineResponse) {
    return async dispatch => {
        dispatch({type: TIMELINE_DATA, payload: formData})
    }
}

export function setTimelineFailed(error: string) {
    return async dispatch => {
        dispatch({type: TIMELINE_FAILED, payload: error})
    }
}

export function setTimelineClear() {
    return async dispatch => {
        dispatch({type: TIMELINE_CLEAR})
    }
}
export function addTimeLineItem(activityItems: ActivityItems[]) {
    return async dispatch => {
        dispatch({type: TIMELINE_ADD_ITEM, payload: activityItems})
    }
}

export function updateTimeLineItem(activityItem: ActivityUpdateItem) {
    return async dispatch => {
        dispatch({type: TIMELINE_UPDATE_ITEM, payload: activityItem})
    }
}

export function deleteTimeLineItem(activityItem: ActivityUpdateItem) {
    return async dispatch => {
        dispatch({type: TIMELINE_DELETE_ITEM, payload: activityItem})
    }
}
export function addTimeLineFilesToFolder(activityItems: ActivityItemFileWDate[], folderData: FileFolderData) {
  return async dispatch => {
      dispatch({type: TIMELINE_ADD_FILE_TO_FOLDER, payload: {items: activityItems, folder: folderData}})
  }
}
export function clearTimeLineFilesFolder(activityItems: ActivityItemFileWDate[]) {
  return async dispatch => {
      dispatch({type: TIMELINE_CLEAR_FILE_FOLDER, payload: {items: activityItems}})
  }
}

