import {
  CALENDAR_LOADING,
  CALENDAR_DATA,
  CALENDAR_FAILED,
  CALENDAR_FILTER,
  CALENDAR_TYPES_HR_LOADING,
  CALENDAR_TYPES_HR_DATA,
  CALENDAR_TYPES_HR_FAILED,
  CALENDAR_TYPES_CASE_LOADING,
  CALENDAR_TYPES_CASE_DATA,
  CALENDAR_TYPES_CASE_FAILED,
  CALENDAR_OPTIONS,
} from './actions'
import { ICalendarState } from './interfaces'

const initialState: ICalendarState = {
  data: [],
  options: [],
  isLoading: false,
  error: '',
  filter: {},
  types: {
    hr: {
      data: {
        task_type: [],
        responsible: [],
        stages: [],
      },
      error: '',
      isLoading: false,
    },
    case: {
      data: {
        task_type: [],
        responsible: [],
        stages: []
      },
      error: '',
      isLoading: false,
    },
  },
}

export const calendar = (
    state = initialState,
    {type, payload},
) => {
    switch (type) {
        case CALENDAR_LOADING:
            return {
                ...state,
                isLoading: true,
                data: [],
                error: ''
            }
        case CALENDAR_OPTIONS:
            return {
                ...state,
                options: payload,
                error: '',
            }
        case CALENDAR_DATA:
            return {
                ...state,
                isLoading: false,
                data: payload,
                error: ''
            }
        case CALENDAR_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        case CALENDAR_FILTER:
            return {
                ...state,
                filter: payload
            }
        case CALENDAR_TYPES_HR_LOADING:
            return {
                ...state,
                types: {
                    ...state.types,
                    hr: {
                        isLoading: true,
                        data: [],
                        error: '',
                    },
                },
            }
        case CALENDAR_TYPES_HR_DATA:
            return {
                ...state,
                types: {
                    ...state.types,
                    hr: {
                        isLoading: false,
                        data: payload,
                        error: '',
                    },
                },
            }

        case CALENDAR_TYPES_HR_FAILED:
            return {
                ...state,
                types: {
                    ...state.types,
                    hr: {
                        ...state.types,
                        isLoading: false,
                        error: payload,
                    },
                },
            }
        case CALENDAR_TYPES_CASE_LOADING:
            return {
                ...state,
                types: {
                    ...state.types,
                    case: {
                        isLoading: true,
                        data: [],
                        error: '',
                    },
                },
            }
        case CALENDAR_TYPES_CASE_DATA:
            return {
                ...state,
                types: {
                    ...state.types,
                    case: {
                        isLoading: false,
                        data: payload,
                        error: '',
                    },
                },
            }

        case CALENDAR_TYPES_CASE_FAILED:
            return {
                ...state,
                types: {
                    ...state.types,
                    case: {
                        ...state.types.case,
                        isLoading: false,
                        error: payload,
                    },
                },
            }
        default:
            return state
    }
}

