import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { createClaimRequest, updateFormData } from '../../../../store/claim_request/action-creators'

export function useSaveResult() {
  const dispatch = useDispatch()
  const saveResult = useCallback(async (id, formData) => {
    if (!formData) {
      return
    }
    const data = formData?.resultObject
    if (!id) {
      if (data) {
        dispatch(createClaimRequest({ ...data }))
      }
    } else {
      dispatch(updateFormData(id, { ...data }))
    }
  }, [])
  return { saveResult }
}
