export const CALENDAR_LOADING = 'CALENDAR_LOADING'
export const CALENDAR_DATA = 'CALENDAR_DATA'
export const CALENDAR_FAILED = 'CALENDAR_FAILED'
export const CALENDAR_FILTER = 'CALENDAR_FILTER'
export const CALENDAR_OPTIONS = 'CALENDAR_OPTIONS'
export const CALENDAR_TYPES_HR_LOADING = 'CALENDAR_TYPES_HR_LOADING'
export const CALENDAR_TYPES_HR_DATA = 'CALENDAR_TYPES_HR_DATA'
export const CALENDAR_TYPES_HR_FAILED = 'CALENDAR_TYPES_HR_FAILED'
export const CALENDAR_TYPES_CASE_LOADING = 'CALENDAR_TYPES_CASE_LOADING'
export const CALENDAR_TYPES_CASE_DATA = 'CALENDAR_TYPES_CASE_DATA'
export const CALENDAR_TYPES_CASE_FAILED = 'CALENDAR_TYPES_CASE_FAILED'

  export function setCalendarDataLoading() {
    return async dispatch => {
        dispatch({ type: CALENDAR_LOADING })
    }
}

export function setCalendarData(data) {
    return async dispatch => {
        dispatch({ type: CALENDAR_DATA, payload: data })
    }
}

export function setCalendarOptions(data) {
    return async dispatch => {
        dispatch({ type: CALENDAR_OPTIONS, payload: data })
    }
}

export function setCalendarFilter(data) {
    return async dispatch => {
        dispatch({type: CALENDAR_FILTER, payload: data})
    }
}

export function setCalendarHrTypes(data) {
    return async dispatch => {
        dispatch({type: CALENDAR_TYPES_HR_DATA, payload: data})
    }
}

export function setCalendarHrTypesLoading(data) {
    return async dispatch => {
        dispatch({type: CALENDAR_TYPES_HR_LOADING})
    }
}

export function setCalendarHrTypesFailed(error: string) {
    return async dispatch => {
        dispatch({type: CALENDAR_TYPES_HR_FAILED, payload: error})
    }
}

export function setCalendarCaseTypes(data) {
    return async dispatch => {
        dispatch({type: CALENDAR_TYPES_CASE_DATA, payload: data})
    }
}

export function setCalendarCaseTypesLoading(data) {
    return async dispatch => {
        dispatch({type: CALENDAR_TYPES_CASE_LOADING})
    }
}

export function setCalendarCaseTypesFailed(error: string) {
    return async dispatch => {
        dispatch({type: CALENDAR_TYPES_CASE_FAILED, payload: error})
    }
}

export function setCalendarFailed(error: string) {
    return async dispatch => {
        dispatch({type: CALENDAR_FAILED, payload: error})
    }
}
