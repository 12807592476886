import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, Tooltip } from 'antd'
import { Select } from 'antd/es'
import Search from 'antd/es/input/Search'
import { DateRangePicker } from '../../input-date-range/styles'
import { datesToMoment, datesToString } from './utils'
import { InputInteger, InputPhone } from './elements'
import { useHistory } from 'react-router-dom'

type TFilterValue = undefined | number | string | readonly string[]

function FiltersList(
  {beforeElement = null, filters = [], onChange, isChangeByButtons = false}: {
    beforeElement?: React.ReactNode
    filters: filtersType[]
    onChange: any,
    isChangeByButtons?: boolean
  }) {
  const history = useHistory()
  const [values, setValues] = useState<any>(
    filters.reduce((obj, cur) => ({ ...obj, [cur.name]: cur.value }), {}),
  )

  useEffect(() => {
    if (!isChangeByButtons) {
      return
    }
    let urlSearchParams = new URLSearchParams(history.location.search)
    const stageUrlParams = urlSearchParams.get('stage')?.split(',').filter(Boolean)
    const taskTypeParams = urlSearchParams.get('task_type')?.split(',').filter(Boolean)
    onChangeHandler({ stage: stageUrlParams, task_type: taskTypeParams }, false)
  }, [history.location, isChangeByButtons])

  const onChangeHandler = useCallback((current, isUpd = true) => {
    const newState = { ...values, ...current }
    {
      setValues(newState)
        if (isUpd) {
          onChange(
            Object.fromEntries(Object.entries(newState).filter(([, value]) => value !== undefined))
          )
        }
    }
  }, [onChange, values, setValues])

  /**
   * Возврачает инпут, в соответствии с входящими параметрами
   */
  const getInput = ({ type, options, placeholder, name, disabled, items, value, style }: filtersType, currentValue: TFilterValue) => {
    const correctStyle = { width: 200, ...style }
    if (type === 'filter_group' && items?.length) {
      return items.map(getInput, currentValue)
    }
    if (type === 'input-string') {
      return (
        <Tooltip title={options?.tooltip}>
          <Search
            allowClear
            placeholder={placeholder as string || 'Заполните'}
            defaultValue={value}
            style={correctStyle}
            onSearch={v => onChangeHandler({ [name]: v || undefined })}
          />
        </Tooltip>
      )
    }
    if (type === 'input-integer') {
      return (
        <Tooltip title={options?.tooltip}>
          <div>
            <InputInteger
              defaultValue={value}
              onSearch={(v: TFilterValue) => onChangeHandler({ [name]: v || undefined })}
              onChange={() => {}}
            />
          </div>
        </Tooltip>
      )
    }
    if (type === 'phone') {
      return (
        <Tooltip title={options?.tooltip}>
          <div>
            <InputPhone
              defaultValue={value}
              onChange={(v: TFilterValue) => onChangeHandler({ [name]: v || undefined })}
            />
          </div>
        </Tooltip>
      )
    }
    if (type === 'select') {
      return (
        <Tooltip title={options?.tooltip}>
          <Select
            allowClear={options?.allowClear !== undefined ? options.allowClear : true}
            placeholder={placeholder || 'Выберите'}
            defaultValue={value}
            options={options?.enum || []}
            disabled={disabled || false}
            style={correctStyle}
            onChange={(v: TFilterValue) => onChangeHandler({ [name]: v })}
          />
        </Tooltip>
      )
    }
      if (type === 'select_multiple') {
        return (
          <Tooltip title={options?.tooltip}>
            <Select
              allowClear={options?.allowClear !== undefined ? options.allowClear : true}
              placeholder={placeholder || 'Выберите'}
              defaultValue={value}
              value={currentValue}
              options={options?.enum || []}
              disabled={disabled || false}
              style={correctStyle}
              onChange={(v: TFilterValue) => onChangeHandler({[name]: v || undefined})}
              mode='multiple'
            />
          </Tooltip>
        )
      }
    if (type === 'dates') {
      return (
        <Tooltip title={options?.tooltip}>
          <DateRangePicker
            defaultValue={datesToMoment(value)}
            placeholder={ placeholder as [string, string] | undefined }
            style={correctStyle}
            onChange={v => onChangeHandler({ [name]: datesToString(v) })}
          />
        </Tooltip>
      )
    }
    return null
  }

  /**
   * Формирует список для вывода
   */
  const filtersList = useMemo(() => {
    return filters.map((item) => {
      return {
        render: getInput(item, values?.[item.name]),
        name: item.name,
        key: item.name,
        style: item?.style,
      }
    }, values)
  }, [filters, values, history])

  return (
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: '4px',
      position: 'relative',
      width: '100%',
      marginBottom: 10,
    }}>
      { beforeElement }
      {filtersList.map(({ render, name, key, style }) => (
        <Form.Item key={key} name={name} style={{ ...style, marginBottom: 3 }}>
          {render}
        </Form.Item>
      ))}
    </div>
  )
}

export default FiltersList
