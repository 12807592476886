import React, { useState, useEffect } from 'react'
import fetchAPI from 'src/lib/utils/fetch-api'
import {
  IFilterOption,
  IOrderByUnitItem,
  IOrdersByManager,
  IOrdersByManagerColumns,
  IOrdersByMonth,
  IOrdersByMonthColumns,
  OrderItemResponse,
} from '../types/types'

import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { getOrdersByManagerColumns, getOrdersByMonthColumns } from '../consts/columns'
import { pickManagerOrders, pickMonthOrders } from '../selectors/pickOrders'

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData,
)

export const useFetchData = (
  selectedYears: string[],
  selectedMonths: string[],
  selectedOrganizations: string[],
  selectedOffices: number[],
  selectedBrands: string[],
  selectedManagers: number[],
) => {
  const { sessionData } = useSelector(createSession)

  const [years, setYears] = useState<IFilterOption[]>([])
  const [organizations, setOrganizations] = useState<IFilterOption[]>([])
  const [offices, setOffices] = useState<IFilterOption[]>([])
  const [brands, setBrands] = useState<IFilterOption[]>([])
  const [managers, setManagers] = useState<IFilterOption[]>([])

  const [ordersByManager, setOrdersByManager] = useState<IOrdersByManager>()
  const [ordersByManagerColumns, setOrdersByManagerColumns] = useState<IOrdersByManagerColumns>([])

  const [ordersByMonth, setOrdersByMonth] = useState<IOrdersByMonth>()
  const [ordersByMonthColumns, setOrdersByMonthColumns] = useState<IOrdersByMonthColumns>([])

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [initialLoadComplete, setInitialLoadComplete] = useState<boolean>(false)

  useEffect(() => {
    loadFilterData()
  }, [])

  useEffect(() => {
    if (initialLoadComplete) {
      loadData()
    }
  }, [
    selectedYears,
    selectedMonths,
    selectedOrganizations,
    selectedOffices,
    selectedBrands,
    selectedManagers,
    initialLoadComplete,
  ])

  const loadFilterData = async () => {
    setIsLoading(true)
    try {
      const result = await fetchAPI('/api/order_report/api/filters', {
        headers: {
          Authorization: 'Basic ' + btoa('zeokna:1q55CVboYQ'),
        },
      })
      const { years, organizations, offices, brands, managers } = result
      setYears(years)
      setOrganizations(organizations)
      setOffices(offices)
      setBrands(brands)
      setManagers(managers)
    } catch (e) {
      console.log(e)
    } finally {
      setInitialLoadComplete(true)
    }
  }

  const loadData = async () => {
    setIsLoading(true)
    const filters: any = {
      years: selectedYears.join(','),
      months: selectedMonths.join(','),
      organizations: selectedOrganizations.join(','),
      offices: selectedOffices.join(','),
      brands: selectedBrands.join(','),
      managers: selectedManagers.join(','),
    }

    const queryString = new URLSearchParams(filters).toString()

    try {
      const url = `/api/order_report/api?${queryString}`
      const fetchedOrders = ((await fetchAPI(url, {
        headers: {
          Authorization: 'Basic ' + btoa('zeokna:1q55CVboYQ'),
        },
      })) || []) as OrderItemResponse[]

      const showOffices = sessionData.roles.includes(16) || sessionData.roles.includes(32)

      setOrdersByManagerColumns(getOrdersByManagerColumns(showOffices))
      setOrdersByMonthColumns(getOrdersByMonthColumns(showOffices))
      setOrdersByManager(
        pickManagerOrders(fetchedOrders, selectedMonths, selectedYears, showOffices),
      )
      setOrdersByMonth(pickMonthOrders(fetchedOrders, selectedMonths, selectedYears, showOffices))
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const refetchData = () => {
    loadData()
  }

  return {
    ordersByManager,
    ordersByManagerColumns,
    ordersByMonth,
    ordersByMonthColumns,
    years,
    organizations,
    offices,
    brands,
    managers,
    isLoading,
    refetchData,
  }
}
