import React, { useMemo, useImperativeHandle, useRef, forwardRef } from 'react'
import { InputNumber } from 'antd'
import { INumber } from './interface'

function InputInteger({ value, onChange, UISchema, onPressEnter = () => null }: INumber, ref) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const style = UISchema?.style
  const isInteger = UISchema?.options?.integer
  const inputRef = useRef<any>()

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef?.current?.focus()
    },
    onfocusout: (fn) => {
      inputRef.current.onfocusout = fn
    }
  }))

  const handleChange = (value) => {
    if (value === null || value === undefined) {
      onChange(null)
    } else if (isInteger) {
      onChange(Math.floor(value))
    } else {
      onChange(value)
    }
  }

  return (
    <InputNumber
      ref={inputRef}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      onPressEnter={onPressEnter}
      style={style}
      step={isInteger ? 1 : 0.01}
      min={0}
    />
  )
}

export default forwardRef(InputInteger)
