import React from 'react'
import {Button, Pagination} from 'antd'
import {TableFooterActions, TableFooterContainer, TableFooterInfo} from './styles'
import {DeleteOutlined, PlusOutlined, EditOutlined} from '@ant-design/icons'
import './style.css'

interface CustomTableFooterProps {
  pagination: {
    current: number
    pageSize: any
    total: number
    hide?: boolean
  }
  handlePageChange: (page: number, pageSize: number) => void
  onCreate?: () => void
  onDelete?: () => void
  showCreate?: boolean
  showDelete?: boolean
  selectedRowKeys?: React.Key[]
  actionButtons?: React.ReactNode
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
  isEditing?: boolean
  extraInfo?: React.ReactNode
}

const TableFooter: React.FC<CustomTableFooterProps> = ({
                                                         pagination,
                                                         handlePageChange,
                                                         onCreate,
                                                         onDelete,
                                                         showCreate = false,
                                                         showDelete = false,
                                                         selectedRowKeys = [],
                                                         actionButtons,
                                                         onEdit,
                                                         onSave,
                                                         onCancel,
                                                         isEditing = false,
                                                         extraInfo
                                                       }) => {
  const { current, pageSize, total, hide } = pagination

  const itemsDisplayed = pageSize === 'all' ? total : Math.min(pageSize, total - (current - 1) * pageSize)

  return (
    <div style={{ width: '100%' }}>
      <TableFooterContainer>
        <TableFooterActions>
          {showCreate && !isEditing && (
            <Button onClick={onCreate} icon={<PlusOutlined/>}>
              Создать
            </Button>
          )}
          {showDelete && !isEditing && (
            <Button
              icon={<DeleteOutlined/>}
              onClick={onDelete}
              disabled={selectedRowKeys.length === 0}
            >
              Удалить
            </Button>
          )}
          {!isEditing && onEdit && (
            <Button
              icon={<EditOutlined/>}
              onClick={onEdit}
              disabled={selectedRowKeys.length === 0}
            >
              Редактировать
            </Button>
          )}
          {isEditing && (
            <>
              <Button type="primary" onClick={onSave}>
                Сохранить
              </Button>
              <Button onClick={onCancel}>
                Отменить
              </Button>
            </>
          )}
          {actionButtons}
        </TableFooterActions>
        {!hide && (
          <>
            <Pagination
              {...pagination}
              onChange={handlePageChange}
              showSizeChanger
              onShowSizeChange={handlePageChange}
              size="small"
            />
          </>
        )}
        <TableFooterInfo>
          {extraInfo && <div>{extraInfo}</div>}
          {onEdit || onDelete && (
            <div>Выделено <b>{selectedRowKeys.length}/{itemsDisplayed}</b></div>
          )}
          <div>Всего: <b>{total}</b></div>
        </TableFooterInfo>
      </TableFooterContainer>
    </div>
  )
}

export default TableFooter