import React, {useRef, useEffect, useState} from 'react'
import {Table, Tooltip} from 'antd'
import {TableCheckboxWrapper, TableContainer, TableScroller} from './styles'
import TableFooter from '../../layouts/b2b/table-footer'
import {DataTableProps} from './interface'
import InputNumber from '../input-number'
import InputText from '../input-text'
import InputDate from '../input-date'
import InputPhone from '../input-phone'
import InputCheckbox from '../Input-checkbox'
import InputTime from "../input-time"
import SelectFromEnum from "../select-from-enum"

const EllipsisCell: React.FC<{ value: any; maxLines?: number }> = ({ value, maxLines }) => {
  const cellRef = useRef<HTMLDivElement>(null)
  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    const checkOverflow = () => {
      if (cellRef.current) {
        const { scrollHeight, clientHeight } = cellRef.current
        setShowTooltip(scrollHeight > clientHeight)
      }
    }

    checkOverflow()
  }, [value])

  const content = (
    <div ref={cellRef} className="cell-content" style={maxLines ? { WebkitLineClamp: maxLines } : {}}>
      {value}
    </div>
  )

  return showTooltip && maxLines ? (
    <Tooltip title={value} placement="topLeft">
      {content}
    </Tooltip>
  ) : content
}

function DataTable<T extends { id: React.Key; type?: string | null } & Record<string, any>>({
                                                                                              columns,
                                                                                              data,
                                                                                              loading = false,
                                                                                              rowKey,
                                                                                              pagination,
                                                                                              selectedRowKeys,
                                                                                              onSelectChange,
                                                                                              onRowClick,
                                                                                              onCreate,
                                                                                              onDelete,
                                                                                              onPageChange,
                                                                                              showDelete = false,
                                                                                              size = 'small',
                                                                                              className,
                                                                                              rowClassName,
                                                                                              bordered = false,
                                                                                              onTableChange,
                                                                                              highlightLastRow = false,
                                                                                              headerRef,
                                                                                              editableFields = [],
                                                                                              onSaveEdit,
                                                                                              maxLines,
                                                                                              extraFooterInfo
                                                                                            }: DataTableProps<T>) {
  const internalHeaderRef = useRef<HTMLDivElement | null>(null)
  const effectiveHeaderRef = headerRef || internalHeaderRef
  const [offsetHeader, setOffsetHeader] = useState(0)
  const [lastSelectedOrder, setLastSelectedOrder] = useState<number | null>(null)
  const [editingRows, setEditingRows] = useState<React.Key[]>([])
  const [editedData, setEditedData] = useState<Record<React.Key, Partial<T>>>({})

  const isEditing = editingRows.length > 0

  const handleEditClick = () => {
    setEditingRows(selectedRowKeys || [])
    setEditedData({})
  }

  const handleCancelEdit = () => {
    setEditingRows([])
    setEditedData({})
  }

  const handleSaveEdit = async () => {
    if (onSaveEdit) {
      const editedRows = editingRows
        .map(rowId => {
          const changes = editedData[rowId]
          if (!changes || Object.keys(changes).length === 0) {
            return null
          }
          return {
            id: Number(rowId),
            ...changes
          }
        })
        .filter(row => row !== null)

      if (editedRows.length > 0) {
        await onSaveEdit(editedRows as T[])
      }
    }
    setEditingRows([])
    setEditedData({})
  }

  const handleFieldChange = (rowId: React.Key, field: string, value: any) => {
    setEditedData(prev => ({
      ...prev,
      [rowId]: {
        ...(prev[rowId] || {}),
        [field]: value,
      },
    }))
  }

  const getColumnRender = (column: any) => (value: any, record: T) => {
    const isRowEditing = editingRows.includes(String(record.id))
    const isEditable = editableFields.includes(column.dataIndex)

    if (isRowEditing && isEditable && column.input) {
      const { type, format, options, style, view, enum: enumValues, oneOf, key } = column.input
      const UISchema = { options, style, view, enum: enumValues, oneOf }
      const fieldCode = key || column.dataIndex

      switch (type) {
        case 'string':
          if (format === 'date') {
            return (
              <InputDate
                value={editedData[record.id]?.[fieldCode] || value}
                onChange={val => handleFieldChange(record.id, fieldCode, val)}
                UISchema={{ ...UISchema, style: { minWidth: 120 } }}
              />
            )
          }
          if (format === 'time') {
            return (
              <InputTime
                value={editedData[record.id]?.[fieldCode] || value}
                onChange={val => handleFieldChange(record.id, fieldCode, val)}
                UISchema={{ ...UISchema, style: { minWidth: 80 } }}
              />
            )
          }
          if (format === 'phone-client') {
            return (
              <InputPhone
                value={editedData[record.id]?.[fieldCode] || value}
                onChange={val => handleFieldChange(record.id, fieldCode, val)}
                UISchema={UISchema}
              />
            )
          }
          if (column.input.view?.name === 'select-from-enum') {
            return (
              <SelectFromEnum
                value={editedData[record.id]?.[fieldCode] || value}
                schema={UISchema}
                onChange={val => handleFieldChange(record.id, fieldCode, val)}
                UISchema={{ ...UISchema, style: { minWidth: 160 } }}
              />
            )
          }
          return (
            <InputText
              value={editedData[record.id]?.[fieldCode] || value}
              onChange={e => handleFieldChange(record.id, fieldCode, e.target.value)}
              schema={{}}
              UISchema={{ ...UISchema, style: { minWidth: 100 } }}
            />
          )
        case 'number':
          return (
            <InputNumber
              value={editedData[record.id]?.[fieldCode] !== undefined ? editedData[record.id][fieldCode] : value}
              onChange={val => handleFieldChange(record.id, fieldCode, val)}
              UISchema={{ ...UISchema, style: { minWidth: 80 } }}
            />
          )
        case 'boolean':
          return (
            <InputCheckbox
              value={editedData[record.id]?.[fieldCode] || value}
              onChange={val => handleFieldChange(record.id, fieldCode, val)}
              UISchema={{
                ...UISchema,
                options: { label: ' ', hide_title: true, highlight: true },
              }}
            />
          )
        default:
          return (
            <InputText
              value={editedData[record.id]?.[fieldCode] || value}
              onChange={e => handleFieldChange(record.id, fieldCode, e.target.value)}
              schema={{}}
              UISchema={UISchema}
            />
          )
      }
    }

    const displayValue = column.render ? column.render(value, record) : value
    return <EllipsisCell value={displayValue} maxLines={maxLines} />
  }

  const enhancedColumns = columns.map(column => ({
    ...column,
    render: column.editable
      ? getColumnRender(column)
      : (value: any, record: T, index: number) => {
        const displayValue = column.render ? column.render(value, record, index) : value
        return <EllipsisCell value={displayValue} maxLines={maxLines} />
      },
  }))

  useEffect(() => {
    const updateOffset = () => {
      requestAnimationFrame(() => {
        if (effectiveHeaderRef.current) {
          setOffsetHeader(effectiveHeaderRef.current.clientHeight + 28)
        }
      })
    }

    const resizeObserver = new ResizeObserver(updateOffset)
    const mutationObserver = new MutationObserver(updateOffset)

    if (effectiveHeaderRef.current) {
      resizeObserver.observe(effectiveHeaderRef.current)
      mutationObserver.observe(effectiveHeaderRef.current, { childList: true, subtree: true })
    }

    updateOffset()
    window.addEventListener('resize', updateOffset)

    return () => {
      resizeObserver.disconnect()
      mutationObserver.disconnect()
      window.removeEventListener('resize', updateOffset)
    }
  }, [effectiveHeaderRef])

  useEffect(() => {
    const storedOrderId = sessionStorage.getItem('lastSelectedOrder')
    if (storedOrderId) {
      setLastSelectedOrder(Number(storedOrderId))
      sessionStorage.setItem('lastSelectedOrder', '')
    }
  }, [])

  useEffect(() => {
    if (lastSelectedOrder && data.length > 0) {
      const highlightedRow = document.querySelector('.highlighted-row') as HTMLTableRowElement | null
      if (highlightedRow) {
        highlightedRow.classList.add('stop-animation')
        highlightedRow.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }, [lastSelectedOrder, data])

  const tableBodyRef = useRef<HTMLDivElement | null>(null)
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const tableBody = document.querySelector('.ant-table-body') as HTMLDivElement | null
    if (tableBody) {
      tableBodyRef.current = tableBody
    }
  }, [])

  useEffect(() => {
    const syncScroll = () => {
      if (tableBodyRef.current && scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft = tableBodyRef.current.scrollLeft
      }
    }

    const syncTableScroll = () => {
      if (tableBodyRef.current && scrollContainerRef.current) {
        tableBodyRef.current.scrollLeft = scrollContainerRef.current.scrollLeft
      }
    }

    if (tableBodyRef.current) {
      tableBodyRef.current.addEventListener('scroll', syncScroll)
    }
    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener('scroll', syncTableScroll)
    }

    return () => {
      if (tableBodyRef.current) {
        tableBodyRef.current.removeEventListener('scroll', syncScroll)
      }
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('scroll', syncTableScroll)
      }
    }
  }, [])

  const handleCheckboxClick = (record: T, selected: boolean, e: React.MouseEvent) => {
    e.stopPropagation()
    if (onSelectChange && selectedRowKeys) {
      const newSelectedRowKeys = selected
        ? selectedRowKeys.filter((key) => String(key) !== String(record.id))
        : [...selectedRowKeys, String(record.id)]

      onSelectChange(newSelectedRowKeys)
    }
  }

  return (
    <TableContainer className={className}>
      <Table<T>
        sticky={{ offsetHeader }}
        showHeader={data.length > 0}
        showSorterTooltip={{ overlay: 'Нажмите для сортировки', placement: 'bottom' }}
        columns={enhancedColumns}
        rowKey={rowKey}
        dataSource={data}
        pagination={false}
        loading={loading}
        onChange={onTableChange}
        onRow={
          onRowClick
            ? record => ({
              onClick: () => {
                if (highlightLastRow && !isEditing) {
                  sessionStorage.setItem('lastSelectedOrder', String(record?.id))
                }
                if (!isEditing) {
                  onRowClick(record)
                }
              },
            })
            : undefined
        }
        scroll={{ x: 'max-content' }}
        size={size}
        rowSelection={
          selectedRowKeys && onSelectChange
            ? {
              selectedRowKeys,
              onChange: onSelectChange,
              getCheckboxProps: () => ({
                disabled: isEditing,
              }),
              renderCell: (value, record, index, originNode) => {
                return (
                  <TableCheckboxWrapper
                    onClick={(e) => handleCheckboxClick(record, selectedRowKeys.includes(String(record.id)), e)}
                  >
                    {originNode}
                  </TableCheckboxWrapper>
                )
              },
            }
            : undefined
        }
        rowClassName={record => {
          const highlightClass =
            highlightLastRow && lastSelectedOrder !== null && record.id === lastSelectedOrder
              ? 'highlighted-row'
              : ''
          const customClass = rowClassName ? rowClassName(record) : ''
          return [highlightClass, customClass].filter(Boolean).join(' ')
        }}
        bordered={bordered}
        className="sticky-footer"
        footer={() => (
          <>
            <TableScroller
              ref={scrollContainerRef}
              scrollWidth={tableBodyRef.current?.scrollWidth}
            />
            <TableFooter
              pagination={pagination}
              handlePageChange={onPageChange}
              showCreate={onCreate && !isEditing}
              showDelete={showDelete && !isEditing}
              onCreate={onCreate}
              onDelete={onDelete}
              selectedRowKeys={selectedRowKeys || undefined}
              onEdit={selectedRowKeys ? handleEditClick : undefined}
              onSave={handleSaveEdit}
              onCancel={handleCancelEdit}
              isEditing={isEditing}
              extraInfo={extraFooterInfo}
            />
          </>
        )}
      />
    </TableContainer>
  )
}

export default DataTable