import React, { useCallback, useMemo, useState } from 'react'
import { Badge, Layout } from 'antd'
import { CalculatorOutlined, CalendarOutlined, MessageOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { NavigationButton, NavigationButtonIcon, NavigationButtons, NavigationButtonTitle } from './styles'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import Ntf from './ntf'
import { CalendarIcon } from '../../assets/icons/calendar'

const createViewed = createSelector(
  (state: any) => state.viewed,
  tasks => tasks
)

const createSession = createSelector(
    (state: any) => state.session,
    sessionData => sessionData
)

/*
function getHost() {
    return '/'
}
*/
export const TopMenu = () => {
  const history = useHistory()
  // const dispatch = useDispatch()
  // const [clientId, setClientId] = useState(753)
  const viewed = useSelector(createViewed)
  const [isOpen, setOpen] = useState<boolean>(false)
  const location = useLocation();
  const {sessionData} = useSelector(createSession);
  // useEffect(() => {
  //     const controller = new AbortController()
  //     const body = {status: true, controller}
  //     dispatch(getLoadViewedTasks(body))
  //
  //     return () => {
  //         body.status = false
  //         controller.abort()
  //     }
  //
  // }, [dispatch])

  const handleCalculatorClick = useCallback(() => {
    history.push('/calculator')
  }, [history])

  const handleCalendarClick = useCallback(() => {
    history.push('/calendar')
  }, [history])

  const handleChatClick = useCallback(() => {
    setOpen(true)
    //history.push('/chat')
  }, [history])

  //openClientFirst

  const isCalendarPage = location.pathname === '/tasks/calendar';

  const handleNewCalendarClick = useCallback(() => {
    if (isCalendarPage) {
      history.push('/tasks')
      return
    }
    history.push('/tasks/calendar')
  }, [history, location])

  return (
    <NavigationButtons>
      <Ntf/>
      <NavigationButton onClick={handleChatClick}>
        <Badge count={0} offset={[5, 5]}>
          <NavigationButtonIcon>
            <MessageOutlined/>
          </NavigationButtonIcon>
        </Badge>
        <NavigationButtonTitle>Чат</NavigationButtonTitle>
      </NavigationButton>
      <NavigationButton onClick={handleCalendarClick} style={{display: 'none'}}>
        <Badge count={viewed['tasks']} offset={[5, 5]}>
          <NavigationButtonIcon>
            <CalendarOutlined/>
          </NavigationButtonIcon>
        </Badge>
        <NavigationButtonTitle>Календарь</NavigationButtonTitle>
      </NavigationButton>
      <NavigationButton onClick={handleCalculatorClick}>
        <NavigationButtonIcon>
          <CalculatorOutlined/>
        </NavigationButtonIcon>
        <NavigationButtonTitle>
          Калькулятор
        </NavigationButtonTitle>
      </NavigationButton>
      <NavigationButton onClick={handleNewCalendarClick}>
        <Badge count={0} offset={[5, 5]}>
          <NavigationButtonIcon >
            {isCalendarPage ? <UnorderedListOutlined /> : <CalendarOutlined />}
          </NavigationButtonIcon>
        </Badge>
        <NavigationButtonTitle>
          {isCalendarPage ? 'Задачи' : 'Календарь'}
        </NavigationButtonTitle>
      </NavigationButton>
    </NavigationButtons>
  )
}
