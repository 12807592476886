import React from 'react'
import { Select } from 'antd'
import {IFilterOption} from "../../model/types/types";

export interface IOfficeFilterProps {
  selectedOffices: number[]
  onOfficeChange: (offices: number[]) => void
  offices: IFilterOption[]
  style?: React.CSSProperties
}


const { Option } = Select

const OfficeFilter: React.FC<IOfficeFilterProps> = ({
  selectedOffices,
  onOfficeChange,
  offices,
  style,
}) => {
  return (
    <Select
      mode="multiple"
      value={selectedOffices}
      onChange={onOfficeChange}
      style={style}
      placeholder="Все офисы"
      allowClear
      filterOption={(input, option) => {
        const optionText = option?.children?.toString() || ''
        return optionText.toLowerCase().includes(input.toLowerCase())
      }}
    >
      {offices.map((office) => (
        <Option key={office.id} value={office.id}>
          {office.value}
        </Option>
      ))}
    </Select>
  )
}

export default OfficeFilter
