import { isDateInRange } from './isDateInRange'

export const isValidPrepaymentDate = (
  order,
  selectedMonths: string[],
  selectedYears: string[],
): boolean => {
  const validStages = ['ДОГОВОР', 'ПРОИЗВОДСТВО', 'ДОСТАВКА', 'МОНТАЖ', 'ВЫПОЛНЕНО', 'МОНТАЖ']
  const stage = order['stage-code']?.toUpperCase()
  return (
    validStages.includes(stage) &&
    Boolean(order['prepayment_date']) &&
    isDateInRange(order['prepayment_date'], selectedMonths, selectedYears)
  )
}

// 9. % ЦО/дог = кол-во parent-id,
// у которых prepayment date находится в пределах