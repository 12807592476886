import React, { useEffect, useState, useMemo } from 'react'
import { Modal, Table, Spin, Row, Col } from 'antd'
import type { DataType } from '../interface'
import { VehicleModalContainer, VehicleModalInfo, VehicleModalInfoItem } from "../styles"
import dayjs from "dayjs"
import fetchAPI from "../../../../../lib/utils/fetch-api"

interface RouteSheetsCardModalProps {
  routeSheet: DataType | null
  onClose: () => void
}

export const RouteSheetsCardModal: React.FC<RouteSheetsCardModalProps> = ({
                                                                            routeSheet,
                                                                            onClose
                                                                          }) => {
  const [orders, setOrders] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const isModalVisible = !!routeSheet

  useEffect(() => {
    if (!routeSheet?.id) {
      setOrders([])
      return
    }

    setLoading(true)
    fetchAPI(`/api/logistics/route-sheets/${routeSheet.id}`)
      .then(response => {
        setOrders(response.data || [])
      })
      .catch(error => {
        console.error('Ошибка при загрузке заказов:', error)
        setOrders([])
      })
      .finally(() => setLoading(false))
  }, [routeSheet?.id])

  const modalColumns = useMemo(
    () => [
      {
        title: '№',
        dataIndex: 'sequence_in_run',
        key: 'sequence_in_run',
      },
      {
        title: 'Номер ТЗ',
        dataIndex: 'order_reference',
        key: 'order_reference',
        width: 120
      },
      {
        title: 'Дилер',
        dataIndex: 'dealer',
        key: 'dealer',
        width: 150,
      },
      {
        title: 'Адрес',
        dataIndex: 'customer_location_address',
        key: 'customer_location_address',
        render: (text, record) => (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            {text}
          </div>
        ),
        width: 180
      },
      {
        title: 'Телефон клиента',
        dataIndex: 'client_phone',
        key: 'client_phone',
        width: 140,
      },
      {
        title: 'Вес',
        dataIndex: 'capacity',
        key: 'capacity',
        render: (value) => value ? value + 'кг' : ''
      },
      {
        title: 'Объем',
        dataIndex: 'volume',
        key: 'volume',
        render: (value) => value ? value + 'м²' : ''
      },
      {
        title: 'Кол-во',
        dataIndex: 'cost',
        key: 'cost',
        width: 80
      },
      {
        title: 'Дополнительные инструкции',
        dataIndex: 'additional_instructions',
        key: 'additional_instructions',
        render: (text: string, record: any) => {
          const style: React.CSSProperties = record.highlight_comment
            ? { backgroundColor: '#fff', color: '#f00', fontWeight: 700, padding: '4px 8px' }
            : {}
          return <span style={style}>{text || ''}</span>
        },
        width: 250
      },
      {
        title: 'Окно заказа',
        dataIndex: 'drop_window_start',
        key: 'drop_window_start',
        render: (value: string, record: any) => value && record.drop_window_end
          ? dayjs(value).format('HH:mm') + ' - ' + dayjs(record.drop_window_end).format('HH:mm')
          : '-',
      },
      {
        title: 'Подъем',
        dataIndex: 'lifting',
        key: 'lifting',
      },
      {
        title: 'Стоимость',
        dataIndex: 'delivery_amount',
        key: 'delivery_amount',
      },
    ],
    []
  )

  const totals = useMemo(() => ({
    weight: orders.reduce((acc, order) => acc + (Number(order.capacity) || 0), 0),
    volume: orders.reduce((acc, order) => acc + (Number(order.volume) || 0), 0),
    deliveryAmount: orders.reduce((acc, order) => acc + (Number(order.delivery_amount) || 0), 0)
  }), [orders])

  if (!routeSheet) return null


  return (
    <Modal
      title={`Маршрутный лист #${routeSheet.id} - ${routeSheet.vehicle}`}
      visible={isModalVisible}
      onCancel={onClose}
      width={1600}
      footer={null}
    >
      <VehicleModalContainer>
        <Spin spinning={loading}>
          <VehicleModalInfo>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <VehicleModalInfoItem><b>Дата:</b> {routeSheet.date ? dayjs(routeSheet.date).format('DD.MM.YYYY') : ''}</VehicleModalInfoItem>
                <VehicleModalInfoItem><b>Загрузка:</b> {routeSheet.loading_time}</VehicleModalInfoItem>
                <VehicleModalInfoItem><b>Возвращение:</b> {routeSheet.return_time}</VehicleModalInfoItem>
              </Col>
              <Col span={6}>
                <VehicleModalInfoItem><b>Склад:</b> {routeSheet.center_name}</VehicleModalInfoItem>
                <VehicleModalInfoItem><b>Машина:</b> {routeSheet.vehicle}</VehicleModalInfoItem>
                <VehicleModalInfoItem><b>Водитель:</b> {routeSheet.driver_name}</VehicleModalInfoItem>

              </Col>
              <Col span={6}>
                <VehicleModalInfoItem><b>Суммарное время:</b> {routeSheet.total_travel_time}</VehicleModalInfoItem>
                <VehicleModalInfoItem><b>Расстояние:</b> {routeSheet.distance} км</VehicleModalInfoItem>
                <VehicleModalInfoItem><b>Кол-во заказов:</b> {routeSheet.orders_count}</VehicleModalInfoItem>
              </Col>
              <Col span={6}>
                <VehicleModalInfoItem><b>Итоговый вес:</b> {totals.weight.toFixed(2)} кг</VehicleModalInfoItem>
                <VehicleModalInfoItem><b>Итоговый объем:</b> {totals.volume.toFixed(2)} м³</VehicleModalInfoItem>
                <VehicleModalInfoItem><b>Итоговая стоимость:</b> {totals.deliveryAmount.toFixed(2)} руб.</VehicleModalInfoItem>
              </Col>
            </Row>
          </VehicleModalInfo>
          <Table
            columns={modalColumns}
            dataSource={orders.sort((a, b) => Number(a.sequence_in_run) - Number(b.sequence_in_run))}
            rowKey="id"
            pagination={false}
          />
        </Spin>
      </VehicleModalContainer>
    </Modal>
  )
}