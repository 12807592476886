import React from 'react'
import {IResourcesTableProps, TSplittedByYearsData} from '../interfaces/interfaces'
import {StatsPerYear} from '../styles'

export const SplittedByYearsTable = (
  Table: React.FC<IResourcesTableProps>,
  {data, columns, showTotal}: IResourcesTableProps,
) => {
  const res = {} as TSplittedByYearsData
  data.forEach((monthData) => {
    const year = monthData.year
    if (!res[year]) {
      res[year] = []
    }
    res[year].push(monthData)
  })

  return (
    <>
      {Object.entries(res).map(([k, v]) => {
        return (
          <StatsPerYear>
            <h2>{k}</h2>
            <Table data={v} columns={columns} showTotal={showTotal} />
          </StatsPerYear>
        )
      })}
    </>
  )
}
