import fetchAPI from '../../../lib/utils/fetch-api'
import {
  setClaimRequestData,
  setClaimRequestFormFailed,
  setClaimRequestFormLoading, setClaimRequestId,
} from './actions'

export function getClaimRequestData(id: string) {
  return async dispatch => {
    dispatch(setClaimRequestFormLoading())
    try {
      const response = await fetchAPI(`/api/claim-request/${id}`) || []
      if (response['errors'] || response?.error) {
        let error = response['errors'] || response?.error
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setClaimRequestFormFailed(error))
      }
      return dispatch(setClaimRequestData(response))
    } catch (err) {
      console.error(err)
      return dispatch(setClaimRequestFormFailed('При загрузки полей возникла ошибка'))
    }
  }
}

interface IClaimRequestPostData {
  stage?: string
  dealer_code?: number
}

export function updateFormData(id: string | number, data: IClaimRequestPostData) {
  return async dispatch => {
    try {
      const response = await fetchAPI(
        `/api/claim-request/${id}`,
        {
          method: 'POST', body: JSON.stringify(data)
        }
      )

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setClaimRequestFormFailed(error))
      }
      return dispatch(setClaimRequestData(response))
    } catch (err) {
      return dispatch(setClaimRequestFormFailed(err as string))
    }
  }
}

export function createClaimRequest(data: IClaimRequestPostData) {
  return async dispatch => {
    try {
      const response = await fetchAPI(
        `/api/claim-request/new`,
        {
          method: 'POST', body: JSON.stringify(data)
        }
      )

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setClaimRequestFormFailed(error))
      }
      return dispatch(setClaimRequestData(response))
    } catch (err) {
      return dispatch(setClaimRequestFormFailed(err as string))
    }
  }
}