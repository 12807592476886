import React from 'react'
import { IframeWrapper } from './styles'

export const ReportIframe = () => {
  const title = 'Аксиома'
  const url =
    'https://app.powerbi.com/view?r=eyJrIjoiYzdmNDNiMGEtY2E4ZC00MDdhLWFhODUtMDc0YjQzZWVjYzQxIiwidCI6IjQ5OGE3YTFhLTJjNTEtNGExOC1iY2I4LWVkMWYyOTU5NWEyYSIsImMiOjl9&pageName=ReportSectione8b7a08b3200a3ad170b'
  return (
    <IframeWrapper>
      <iframe
        title={title}
        width={'100%'}
        height={'100%'}
        src={url}
        frameBorder={0}
        allowFullScreen={true}
      ></iframe>
    </IframeWrapper>
  )
}
