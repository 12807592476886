import { Button, Drawer, Modal } from 'antd'
import React, { useState, useRef, useEffect, useCallback, memo } from 'react'
import {  useHistory } from 'react-router-dom'
import { Form, Alert, Skeleton, Tabs, Space } from 'antd'
import { AlertWrapper } from 'src/react-app/pages/card-client/styles'
import fetchAPI from 'src/lib/utils/fetch-api'
import { DefaultJsonForm } from 'src/react-app/components/v2/default-json-form'
import TabGeneral from './tab-orders'
import TableCallLog from './tab-call-log'
import { GeneralSpace } from './styles'
import defaultSelectedSchema from './json-schema.json'
import TableContacts from './tab-contacts'
import { setEnumFields } from '../../../../../../utils'
import { HStack } from '../../../../../../../ui/Stack'
import { CloseOutlined } from '@ant-design/icons'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

interface BoxClientProps {
  isOpen: boolean
  setOpen: (state: Boolean) => void
  id: number
  appealId?: string | number
  customerId?: string | number
  warningContent?: string | JSX.Element
  onComplete: () => void
}

export const BoxClient: React.FC<BoxClientProps> = memo((props: BoxClientProps) => {
  const { isOpen, setOpen, id, appealId, customerId, warningContent, onComplete } = props
  const history = useHistory()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const formDataRef = useRef<any>({})
  const [form] = Form.useForm()
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)
  const [scenario, setScenario] = useState<number>(1)

  const onClose = () => {
    setOpen(false)
  }

  const setDisabledFields = (data, schema)  => {
    const disabledFields = data.filter(obj => obj.disabled === true)
    disabledFields.forEach(obj => {
      if (schema.properties[obj.name]) {
        schema.properties[obj.name].options = {...schema.properties[obj.name].options, disabled: true}
      }
    })
  }

  const getData = useCallback(async () => {
    {
      const clientData = await fetchAPI(`/api/customers/${id}`)
      const enums = await fetchAPI('/api/enum/customers')
      const formData = clientData.reduce((obj, cur) => ({...obj, [cur.name]: cur.value}), {})

      setDisabledFields(clientData, defaultSelectedSchema)
      setEnumFields(enums, defaultSelectedSchema)

      formDataRef.current = {...formData}
      setMatchedJSONSchema(defaultSelectedSchema)
    }
    setPageErrors([])
    setIsDataLoading(false)
  }, [id])

  useEffect(() => {
    getData()
  }, [])

  const onFormData = useCallback(({ formData }) => {
    formDataRef.current = formData
  }, [])

  const reloadPage = () => window.location.reload()

  const tryMargeHandler = useCallback(async (customer_id: number) => {
    const body = JSON.stringify({
      from_appeal: appealId,
      from_customer: customerId,
      to_customer: customer_id,
    })
    await fetchAPI('/api/marge-customers', { method: 'post', body })
      .then(response => {
        console.log({ response })
        if (!response) {
          Modal.error({
            title: 'Ошибка',
            content: 'Время ожидания ответа истекло, проверьте подключение к интернету.',
          })
        }
        if (response?.error) {
          Modal.error({
            title: 'Ошибка',
            content: response?.error,
          })
        }
        if (response?.success) {
          onComplete?.()
          setTimeout(() => {
            if(response?.redirectUri) {
              history.push(response.redirectUri)
            } else {
              reloadPage()
            }
          }, 1500)
        }
      })
      .catch(e => {
        console.error(e)
        Modal.error({
          content: 'Ошибка'
        })
      })
  }, [appealId])

  const margeHandler = useCallback(() => {
    const content = {
      1: <>
        Контакты из обращения будут добавлены к контактам выборанного клиента.<br/>
        Действие невозможно отменить.
      </>,
      2: <>
        Контакты из обращения будут добавлены к контактам выборанного клиента.<br/>
        Текущее обращение будет удалено. Действие невозможно отменить.<br/>
        После подтверждения действия вы будете переадресованы в карточку заказа.
      </>,
    }
    Modal.confirm({
      title: 'Подтвердите действие',
      content: warningContent ? warningContent : content?.[scenario],
      okText: 'Объединить',
      cancelText: 'Отмена',
      onOk: async () => {
        await tryMargeHandler(id)
      },
    })
  }, [id, scenario])

  return (
    <Drawer
      title='Карточка клиента'
      placement={'bottom'}
      closable={false}
      onClose={onClose}
      visible={isOpen}
      key={'bottom'}
      height={480}
      extra={
        <Space size={'small'}>
          <Button type='primary' icon={<CloseOutlined />} onClick={onClose}>
            Закрыть карточку
          </Button>
        </Space>
      }
    >
      <GeneralSpace>
        <Form
          {...formItemLayout}
          layout='horizontal'
          form={form}
          scrollToFirstError={true}
        >
          {pageErrors.length > 0 ? (
            <AlertWrapper>
              <Alert
                message='При выполнении операции возникла ошибка:'
                showIcon
                type='error'
                description={pageErrors.join('. ')}
              />
            </AlertWrapper>
          ) : null}
          {isDataLoading === false ? (
            <DefaultJsonForm
              formData={formDataRef.current}
              schema={matchedJSONSchema}
              onChange={onFormData}
            />
          ) : (
            <Skeleton active />
          )}
        </Form>
        <Tabs
          type='card'
          style={{ width: 'calc(100vw - 350px)'}}
        >
          <Tabs.TabPane tab='История заказов' key='1'>
            <TabGeneral
              clientId={id}
              setUnfinishedCount={data => {
                setScenario(data === 0 ? 1 : 2)
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Журнал звонков' key='2'>
            <TableCallLog clientId={id}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab='Дополнительные контакты' key='3'>
            <TableContacts userId={id}/>
          </Tabs.TabPane>
        </Tabs>
      </GeneralSpace>
      <HStack justify={'center'}>
        <Button
          type={'primary'}
          onClick={margeHandler}
        >
          Объединить
        </Button>
      </HStack>
    </Drawer>
  )
})
