import React, {memo, Suspense, useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  selectDeliveriesTimelineError,
  selectDeliveriesTimelineFiles,
  selectDeliveriesTimelineIsLoading,
} from '../selectors'
import {UploadFiles} from '../../../../components/v2/upload-files'
import {deleteDeliveriesTimeLineItem} from '../../../../store/deliveries/actions'
import {
  ActivityTimelineContextProps
} from '../../../../components/v2/activity-timeline/context/types/ActivityTimelineContextProps'
import ActivityTimelineContext from 'src/react-app/components/activity-timeline/activity-timeline-context'
import {ActivityTimeline} from '../../../../components/v2/activity-timeline'
import {FileManagerAsync as FileManager} from "../../../../components/v2/activity-file-manager/ui/file-manager.async";
import {FileTabCtr} from "../styles";


interface DeliveriesFilesTimelineProps {
  className?: string
  id: string | number
}

export const DeliveryFiles: React.FC<DeliveriesFilesTimelineProps> = memo((props: DeliveriesFilesTimelineProps) => {
  const {id, className} = props
  const dispatch = useDispatch()
  const timelineIsLoading = useSelector(selectDeliveriesTimelineIsLoading)
  const timelineError = useSelector(selectDeliveriesTimelineError)
  const timelineFiles = useSelector(selectDeliveriesTimelineFiles)
  const entityType = 'delivery'

  const deleteDeliveriesTimelineItem = useCallback((activityItem) => {
      dispatch(deleteDeliveriesTimeLineItem(activityItem))
  }, [])

  const defaultProps = useMemo(
    (): ActivityTimelineContextProps => ({
      card_id: id,
      card_type: entityType,
      deleteTimelineItem: deleteDeliveriesTimelineItem,
    }),
    [id],
  )

  return (
    <>
      <FileTabCtr>
        <div>
          <Suspense fallback={''}>
            <div className="file-drag">
              <UploadFiles
                entityType={entityType}
                entityId={id}
                url={`/api/${entityType}/${id}/add-file`}
              />
            </div>
          </Suspense>
          <Suspense fallback={''}>
            <div className="timeline">
              <ActivityTimelineContext.Provider value={defaultProps}>
                <ActivityTimeline
                  isLoading={timelineIsLoading}
                  items={timelineFiles}
                  error={timelineError}
                  className={className}
                  emptyMessage={'Нет прикрепленных файлов'}
                />
              </ActivityTimelineContext.Provider>
            </div>
          </Suspense>
        </div>
        <div>
          <Suspense fallback={''}>
            <FileManager activityItems={timelineFiles} />
          </Suspense>
        </div>
      </FileTabCtr>
    </>
  )
})
