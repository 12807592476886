import { Button, Drawer } from 'antd'
import React, { useState, useRef, useEffect, useCallback, memo } from 'react'
import { Form, Alert, Skeleton, Tabs, Space } from 'antd'
import { AlertWrapper } from 'src/react-app/pages/card-client/styles'
import fetchAPI from 'src/lib/utils/fetch-api'
import { DefaultJsonForm } from 'src/react-app/components/v2/default-json-form'
import TabGeneral from './tab-orders'
import TableCallLog from './tab-call-log'
import { GeneralSpace } from './styles'
import defaultSelectedSchema from './json-schema.json'
import TableContacts from './tab-contacts'
import { CloseOutlined } from '@ant-design/icons'
import { setEnumFields } from '../../../pages_v2/utils'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

interface BoxClientProps {
  isOpen: boolean
  setOpen: (state: Boolean) => void
  id: number
}

export const BoxClient: React.FC<BoxClientProps> = memo((props: BoxClientProps) => {
  const { isOpen, setOpen, id } = props
  if (!isOpen) {
    return null
  }
  const [opened, setOpened] = useState<Boolean>(isOpen)
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const formDataRef = useRef<any>({})
  const [form] = Form.useForm()
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)

  const onClose = () => {
    setOpened(false)
    setTimeout(() => {
      setOpen(false)
    }, 300)
  }

  const setDisabledFields = (data, schema)  => {
    const disabledFields = data.filter(obj => obj.disabled === true)
    disabledFields.forEach(obj => {
      if (schema.properties[obj.name]) {
        schema.properties[obj.name].options = {...schema.properties[obj.name].options, disabled: true}
      }
    })
  }

  const getData = useCallback(async () => {
    {
      const clientData = await fetchAPI(`/api/customers/${id}`)
      const enums = await fetchAPI('/api/enum/customers')
      const formData = clientData.reduce((obj, cur) => ({...obj, [cur.name]: cur.value}), {})

      setDisabledFields(clientData, defaultSelectedSchema)
      setEnumFields(enums, defaultSelectedSchema)

      formDataRef.current = {...formData}
      setMatchedJSONSchema(defaultSelectedSchema)
    }
    setPageErrors([])
    setIsDataLoading(false)
  }, [id])

  useEffect(() => {
    getData()
  }, [])

  const onFormData = useCallback(({ formData }) => {
    formDataRef.current = formData
  }, [])

  return (
    <Drawer
      destroyOnClose={true}
      title='Карточка клиента'
      placement={'bottom'}
      closable={false}
      onClose={onClose}
      visible={Boolean(opened)}
      key={'bottom'}
      height={480}
      extra={
        <Space size={'small'}>
          <Button type='primary' icon={<CloseOutlined />} onClick={onClose}>
            Закрыть карточку
          </Button>
        </Space>
      }
    >
      <GeneralSpace>
        <Form
          {...formItemLayout}
          layout='horizontal'
          form={form}
          scrollToFirstError={true}
        >
          {pageErrors.length > 0 ? (
            <AlertWrapper>
              <Alert
                message='При выполнении операции возникла ошибка:'
                showIcon
                type='error'
                description={pageErrors.join('. ')}
              />
            </AlertWrapper>
          ) : null}
          {isDataLoading === false ? (
            <DefaultJsonForm
              formData={formDataRef.current}
              schema={matchedJSONSchema}
              onChange={onFormData}
            />
          ) : (
            <Skeleton active />
          )}
        </Form>
        <Tabs
          type='card'
          style={{ width: 'calc(100vw - 350px)'}}
        >
          <Tabs.TabPane tab='История заказов' key='1'>
            <TabGeneral clientId={id} />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Журнал звонков' key='2'>
            <TableCallLog clientId={id}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab='Дополнительные контакты' key='3'>
            <TableContacts userId={id}/>
          </Tabs.TabPane>
        </Tabs>
      </GeneralSpace>
    </Drawer>
  )
})
