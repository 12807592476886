import { ActivityUpdateItem } from '../types/types';

export const updateTimelineItemByItemId = (newDataItem: ActivityUpdateItem, timelineItems, timelineDates ) => {
    const indexScope = timelineDates?.findIndex((dateItem) => dateItem == newDataItem.date);

    timelineItems[indexScope].items?.forEach((currentActivityItem) => {
        if (currentActivityItem.type === newDataItem.type && currentActivityItem.data?.id === newDataItem.data?.id) {
          
            currentActivityItem.data = {...currentActivityItem.data,...newDataItem.data};
        }
    })
    return timelineItems;
}
