import { toCapitalize } from 'src/react-app/utilits/toCapitalize'
import { isValidOrderByManager } from '../lib/isValidOrderByManager'
import {
  IOrderByUnitItem,
  IOrdersByManager,
  IOrdersForUnitByMonth,
  OrderByUnitItem,
  OrderItemResponse,
  OrdersForUnit,
} from '../types/types'
import dayjs from 'dayjs'
import { isDateInRange } from '../lib/isDateInRange'
import { isValidPrepaymentDate } from '../lib/isValidPrepaymentDate'
import { isValidMeasurements } from '../lib/isValidMeasurements'
import { isValidCOLid } from '../lib/isValidCOLid'
import { isValidLid } from '../lib/isValidLid'
import { calcSalesFunnel } from './calcSalesFunnel'
import { isValidContracts } from '../lib/isValidContracts'

/**
 * Отбирает из ответа валидные для менеджеров заказы
 */
export const pickManagerOrders = (
  orders: OrderItemResponse[],
  selectedMonths: string[],
  selectedYears: string[],
  showOffices: boolean,
) => {
  const ordersByManagerTmp = {} as IOrdersByManager
  orders
    .filter(el => isValidOrderByManager(el, selectedMonths, selectedYears))
    .forEach((rawOrder, i, array) => {
      // Эти поля валидируются в isValidOrderByManager
      const orderItem = rawOrder as {
        'case-date-fact': string
        'stage-code': string
        'contract-sum': number
        'production-order-number': string
        prepayment_date: string
        'manager-name': string
        'manager-id': string
        office: string
      }
      const date = new Date(orderItem['prepayment_date'])
      const month = date.toLocaleString('ru-RU', { month: 'long', timeZone: 'UTC' })
      const year = date.getFullYear().toString()
      /** Месяц + Год*/
      const MYKey = `${toCapitalize(month)} ${year}`
      const managerName = orderItem['manager-name']
      const officeName = orderItem['office']
      const managerId = orderItem['manager-id']

      if (!ordersByManagerTmp[MYKey]) {
        ordersByManagerTmp[MYKey] = { orders: {}, total: 0 }
      }

      if (!ordersByManagerTmp[MYKey].orders[managerId]) {
        ordersByManagerTmp[MYKey].orders[managerId] = []
      }
      if (showOffices && !ordersByManagerTmp[MYKey].orders[officeName]) {
        ordersByManagerTmp[MYKey].orders[officeName] = [
          new OrderByUnitItem('Итог офиса', officeName),
        ]
      }

      const order: IOrderByUnitItem = {
        rowIndex: i,
        orderDate: orderItem['prepayment_date'] as string,
        caseDate: orderItem['case-date-fact'],
        contractNum: orderItem['production-order-number'] || 'Не указан',
        sum: orderItem['contract-sum'] as number,
        manager: managerName,
        managerId: managerId,
        office: officeName,
        clientName: orderItem['client-name'],
        days: orderItem['prepayment_date']
          ? dayjs(orderItem['prepayment_date']).diff(orderItem['case-date-fact'], 'day') || 1
          : 1,
        key: orderItem['order-date-fact'],
      }
      if (showOffices) {
        // Добавляем сумму контракта к офису
        ordersByManagerTmp[MYKey].orders[officeName][0].sum += orderItem['contract-sum']
      }

      ordersByManagerTmp[MYKey].orders[managerId].push(order)
      ordersByManagerTmp[MYKey].total += orderItem['contract-sum']
    })
  return ordersByManagerTmp
}
/**
 * Отбирает из ответа валидные заказы и разбивает по месяцам
 */
export const pickMonthOrders = (
  orders: OrderItemResponse[],
  selectedMonths: string[],
  selectedYears: string[],
  showOffices: boolean,
) => {
  const ordersPerManager = {} as IOrdersForUnitByMonth
  const ordersPerOffice = {} as IOrdersForUnitByMonth
  const monthKeys = [] as string[]
  /** уникальные ID заказов, у которых Дата заявки находится в пределах выбранного периода,
   * если  Причина закрытия заказа не равно Нецелевое обращение)*/
  const unIdsCO = new Set()

  /** уникальные ID заказов, у которых Дата заявки находится в пределах выбранного периода*/
  const unIdsCaseDateFact = new Set()

  const checkOrdersFields = (month: string, unit: string, targetObj: IOrdersForUnitByMonth) => {
    if (!targetObj[month]) {
      targetObj[month] = {}
    }
    if (!targetObj[month][unit]) {
      targetObj[month][unit] = new OrdersForUnit()
    }
    return targetObj[month][unit]
  }
  orders
    // .filter(
    //   el =>
    //     //Если [Дата создания обращения] или [Дата замера] во врем. промежутке
    //     el['prepayment_date']
    // )
    .forEach((orderItem: OrderItemResponse) => {
      if (!orderItem['prepayment_date']) {
        return
      }
      const date = new Date(orderItem['prepayment_date'])
      const month = date.toLocaleString('ru-RU', { month: 'long', timeZone: 'UTC' })
      const year = date.getFullYear().toString()

      const MYKey = `${toCapitalize(month)} ${year}`
      const managerName = orderItem['manager-name'] as string
      const managerId = orderItem['manager-id'] as string
      const officeName = orderItem['office'] as string
      const managerStat = checkOrdersFields(MYKey, managerId, ordersPerManager)
      if (!managerStat.managerName) managerStat.managerName = managerName

      if (showOffices) {
        if (!managerStat.officeName) managerStat.officeName = officeName
        checkOrdersFields(MYKey, officeName, ordersPerOffice)
      }

      if (isValidPrepaymentDate(orderItem, selectedMonths, selectedYears)) {
        if (!monthKeys.includes(MYKey)) {
          monthKeys.push(MYKey)
        }
        ordersPerManager[MYKey][managerId].Orders.push(orderItem)
        ordersPerManager[MYKey][managerId].Prepayment += 1
        if (showOffices) ordersPerOffice[MYKey][officeName].Prepayment += 1
      }

      if (
        isValidMeasurements(orderItem, selectedMonths, selectedYears) /*&& item["prepayment_date"]*/
      ) {
        const dateMeasurement = new Date(orderItem?.['measurement-date-fact'])
        const monthMeasurement = dateMeasurement.toLocaleString('ru-RU', {
          month: 'long',
          timeZone: 'UTC',
        })
        const yearMeasurement = dateMeasurement.getFullYear().toString()
        const keyMeasurementDate = `${toCapitalize(monthMeasurement)} ${yearMeasurement}`
        const keyMesTmp = checkOrdersFields(keyMeasurementDate, managerId, ordersPerManager)
        if (!keyMesTmp.managerName) keyMesTmp.managerName = managerName
        ordersPerManager[keyMeasurementDate][managerId].Measurements += 1
        if (showOffices) {
          if (!keyMesTmp.officeName) keyMesTmp.officeName = officeName
          checkOrdersFields(keyMeasurementDate, officeName, ordersPerOffice)
          ordersPerOffice[keyMeasurementDate][officeName].Measurements += 1
        }
      }
      if (isValidCOLid(orderItem, selectedMonths, selectedYears)) {
        if (!unIdsCO.has(orderItem['parent-id'])) {
          ordersPerManager[MYKey][managerId].CO += 1
          if (showOffices) ordersPerOffice[MYKey][officeName].CO += 1
          unIdsCO.add(orderItem['parent-id'])
        }
      }
    })

  return calcSalesFunnel(
    ordersPerManager,
    showOffices ? ordersPerOffice : undefined,
    monthKeys,
    selectedMonths,
    selectedYears,
    showOffices,
  )
}
