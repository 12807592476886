import { manyTablesData } from './getTableData'
import { IOrdersForUnitByMonth, IOrdersByMonth } from '../types/types'

/**
 * @description Рассчитывает данные для отображения воронки продаж по месяцам, если выбрано меньше 4х месяцев данные сливаются в одну таблицу
 */

export const calcSalesFunnel = (
  monthManagersOrders: IOrdersForUnitByMonth,
  monthOfficeOrders: IOrdersForUnitByMonth | undefined,
  monthKeys: string[],
  selectedMonths: string[],
  selectedYears: string[],
  showOffices: boolean,
): IOrdersByMonth => {
  return manyTablesData(
    monthManagersOrders,
    monthOfficeOrders,
    monthKeys,
    selectedMonths,
    selectedYears,
    showOffices,
  )
}
