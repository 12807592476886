import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Badge, Layout, Menu } from 'antd'
import { UserProfile } from './user-profile'
import { Copyright } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { setCounterData, fetchMenu } from '../../store/user_menu/actions'
import IconComponent from '../../components/icon-component'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { IMenuItem } from './interfaces'
import { useEventSource } from '../../hooks/useEventSource/useEventSource'
import { preventLink } from '../../../lib/utils/helpers'

const { Sider } = Layout
const { SubMenu } = Menu

export const MenuComponent = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { menu, counters } = useSelector((state: any) => state.menuV2)
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const storedState = localStorage.getItem('menuCollapsed')
    return storedState ? JSON.parse(storedState) : false
  })
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  const [openKeys, setOpenKeys] = useState<string[]>([])
  const {sse, subscribe} = useEventSource()

  useEffect(() => {
    dispatch(fetchMenu())
  }, [dispatch])

  useEffect(() => {
    let mounted = true
    if (mounted) {
      updateSelectedKeys(location.pathname)
      updateOpenKeys(location.pathname)
      subscribe('/api/menu-counters', data => {
        dispatch(setCounterData(data))
      })
    }
    return () => {
      mounted = false
      sse?.current?.close()
    }
  }, [location, menu])

  const findSelectedKeys = (menuItems: IMenuItem[], pathname: string): string[] => {
    const findPath = (items: IMenuItem[], path: string, parentIds: string[] = []): string[] | null => {
      for (const item of items) {
        const currentPath = item.url ? item.url : ''
        if (currentPath === path) {
          return [...parentIds, String(item.id)]
        }
        if (item.children && item.children.length > 0) {
          const result = findPath(item.children, path, [...parentIds, String(item.id)])
          if (result) {
            return result
          }
        }
      }
      return null
    }

    return findPath(menuItems, pathname) || []
  }

  const findParentKeys = (menu: IMenuItem[], pathname: string): string[] => {
    const findExactPath = (items: IMenuItem[], path: string, parentIds: string[] = []): string[] | null => {
      for (const item of items) {
        const currentPath = item.url ? item.url : ''
        if (currentPath === path) {
          return parentIds
        }
        if (item.children && item.children.length > 0) {
          const result = findExactPath(item.children, path, [...parentIds, String(item.id)])

          if (result) {
            return result
          }
        }
      }
      return null
    }

    return findExactPath(menu, pathname) || []
  }


  const getUrlById = (arr: IMenuItem[], id: string) => {
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i]
      if (String(item.id) === id) {
        return item.url
      } else if (item.children && item.children.length > 0) {
        const nestedResult = getUrlById(item.children, id)
        if (nestedResult) {
          return nestedResult
        }
      }
    }
    return null
  }

  const handleClick = useCallback(
    (e: any) => {
      setSelectedKeys([e.key])
      const targetURI = getUrlById(menu, e.key)
      if (targetURI) {
        history.push(targetURI)
      }
    },
    [menu, history]
  )

  const handleCollapse = useCallback(
    (collapse) => {
      setIsCollapsed(collapse)
      localStorage.setItem('menuCollapsed', JSON.stringify(collapse))
    },
    [setIsCollapsed]
  )

  const updateSelectedKeys = useCallback((pathname: string) => {
    const keys = findSelectedKeys(menu, pathname)
    setSelectedKeys(keys)
  }, [menu])

  const updateOpenKeys = useCallback((pathname: string) => {
    if (!isCollapsed) {
      const keys = findParentKeys(menu, pathname)
      setOpenKeys(keys)
    }
  }, [menu, isCollapsed])

  const currentYear = new Date().getFullYear()

  const handleSubMenuClick = (openKeys: string[]) => {
    setOpenKeys(openKeys)
  }

  const renderMenuItems = useMemo(() => {
    const calculateTotalNewItems = (items: IMenuItem[]): number => {
      let total = 0
      for (const item of items) {
        total += counters[item.url] || 0
        if (item.children) {
          total += calculateTotalNewItems(item.children)
        }
      }
      return total
    }

    const renderSubMenu = (item: IMenuItem): React.ReactNode => {
      const newItemCount = counters[item.url] || 0
      const childrenTotal = calculateTotalNewItems(item.children || [])
      const totalCounter = newItemCount + childrenTotal

      return (
        <SubMenu
          key={item.id}
          icon={item.icon ? <IconComponent iconName={item.icon} /> : null}
          title={(<>
            {item.title}
            <Badge count={totalCounter > 0 ? totalCounter : newItemCount} offset={[10, 0]}/>
          </>)}>
          {renderMenuItems(item.children || [])}
        </SubMenu>
      )
    }

    const renderMenuItem = (item: IMenuItem): React.ReactNode => {
      const newItemCount = counters[item.url] || 0;
      const childrenTotal = calculateTotalNewItems(item.children || [])
      const totalCounter = newItemCount + childrenTotal

      return (
        <Menu.Item key={item.id} icon={item.icon ? <IconComponent iconName={item.icon} /> : null}>
          <Link to={item.url} onClick={preventLink}>
            {item.title}
          </Link>
          <Badge count={totalCounter > 0 ? totalCounter : newItemCount} offset={[10, 0]}/>
        </Menu.Item>
      )
    }

    return (menuItems: IMenuItem[] = [], parentKeys: string[] = []) => {
      return menuItems.map((item) => {
        if (item.children && item.children.length) {
          return renderSubMenu(item)
        } else {
          return renderMenuItem(item)
        }
      })
    }
  }, [counters])

  return (
    <Sider collapsible collapsed={isCollapsed} onCollapse={handleCollapse} width={260}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'stretch' }}>
        <UserProfile/>
        <Menu
          mode='inline'
          theme='dark'
          selectedKeys={selectedKeys}
          onClick={handleClick}
          style={{ flex: '1 1 auto' }}
          openKeys={openKeys}
          onOpenChange={handleSubMenuClick}
        >
          {renderMenuItems(menu)}
        </Menu>
        {!isCollapsed && (
          <Copyright>
            &copy; 2020-{currentYear} ООО «Аксиома». Все права защищены.
            {/*<Button onClick={updateCounters}>counter</Button>*/}
          </Copyright>
        )}
      </div>
    </Sider>
  )
}