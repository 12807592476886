import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import B2BLayout from '../../../layouts/b2b'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { DefaultJsonForm } from '../../../components/v2/default-json-form'
import TabGeneral from './tab-general'
import TableCallLog from './table-call-log'
import TableContacts from './table-contacts'
import { Form, Alert, Skeleton, Button, Tabs, Space, Modal } from 'antd'
import { SaveOutlined, PlusOutlined } from '@ant-design/icons'
import { MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper } from './styles'
import defaultSelectedSchema from './json-schema.json'
import { setEnumFields } from '../../utils'
import { MargeModal } from './components/marge-modal'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

const breadcrumbs = [
  {
    href: '/',
    title: 'Контактный центр',
  },
  {
    href: `/call-center/new-clients`,
    title: 'Список клиентов',
  },
]

function setDisabledFields(data, schema) {
  const disabledFields = data.filter(obj => obj.disabled === true)
  disabledFields.forEach(obj => {
    if (schema.properties[obj.name]) {
      schema.properties[obj.name].options = {...schema.properties[obj.name].options, disabled: true}
    }
  })
}

export default function AdminNewObjectPage() {
  const { id } = useParams() as any
  const history = useHistory()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [pageSuccess, setPageSuccess] = useState<string[]>([])
  const formDataRef = useRef<any>({})
  const [form] = Form.useForm()
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)

  const saveCase = useCallback(
    async () => {
      const formData = {...formDataRef.current}
      await fetchAPI(`/api/customers/${id}`, {
        method: 'POST', body: JSON.stringify(formData)
      }).then(result => {
        if (result?.error) {
          const errors =
            Array.isArray(result['errors']) && result['errors']?.length > 0
              ? result['errors'].map((error) => error['detail'])
              : ['Неизвестная ошибка при выполнении запроса']
          setPageSuccess([])
          setPageErrors(errors)
        } else {
          setPageSuccess(['Запись успешно обновлена.'])
          setPageErrors([])
        }
      })
    },
    [history, id],
  )

  const getData = useCallback(async () => {
    {
      const clientData = await fetchAPI(`/api/customers/${id}`)
      const enums = await fetchAPI('/api/enum/customers')
      const formData = clientData.reduce((obj, cur) => ({...obj, [cur.name]: cur.value}), {})

      setDisabledFields(clientData, defaultSelectedSchema)
      setEnumFields(enums, defaultSelectedSchema)

      formDataRef.current = {...formData}
      setMatchedJSONSchema(defaultSelectedSchema)
    }
    setPageErrors([])
    setIsDataLoading(false)
  }, [id])

  useEffect(() => {
    getData().catch(e => {
      console.error({e})
    })
  }, [getData])

  const onFormData = useCallback(({formData}) => {
    formDataRef.current = formData
  }, [])

  const createOrder = useCallback(async () => {
    await fetchAPI(`/api/create-appeal-by-customer/${id}`)
      .then(result => {
        if (result?.errors) {
          Modal.error({
            title: 'Ошибка',
            content: <>
              При выполнении операции возникла ошибка:<br/><br/>
              {result.errors.join('\n')}
            </>,
          })
          return
        }
        if (result.id) {
          history.push(`/appeal/${result.id}`)
        }
      })
      .catch(err => {
        Modal.error({
          title: 'Ошибка',
          content: <>
            Проверте подключение к интернету и повторите попытку<br /><br />
            {err.join('\n')}
          </>,
        })
      })
  }, [history, id])

  const checkUnfinished = useCallback(async () => {
    const unfinished = await fetchAPI(`/api/case/get-unfinished/${id}`)
    if (unfinished && Boolean(unfinished?.id)) {
      const caseId = unfinished?.id
      const cardName = unfinished?.card === 'order' ? 'заказ' : 'обращение'
      const cardUrl = unfinished?.card === 'order' ? `/order/${caseId}` : `/appeal/${caseId}`
      return Modal.confirm({
        title: 'Внимание!',
        content: (<>
          У пользователя есть незавершенн{cardName === 'заказ' ? 'ый' : 'ое'} {cardName} <b>№{unfinished.id}</b><br/>
          <a href={cardUrl} target='_blank'>Открыть {cardName}</a>
        </>),
        okText: 'Создать заказ',
        cancelText: 'Назад',
        onOk: () => {createOrder()},
      })
    } else {
      await createOrder()
    }
  },[id, createOrder])

  const warningMessage = <>
    Все контакты и обращения, заказы, замеры, монтажи, если таковые имеются будут переданы выбранному контакту.<br/>
    Действие невозможно отменить.<br/>
    После подтверждения действия вы будете переадресованы в объединенную карточку.
  </>

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>Клиент <span>ID:{id}</span></MapTitle>
          <Space size={'small'}>
            <MargeModal
              customerId={id}
              disabled={false}
              warningContent={warningMessage}
            />
            <Button
              type='primary'
              icon={<PlusOutlined />}
              onClick={checkUnfinished}
            >
              Создать заказ
            </Button>
          </Space>
        </TaskWrapper>
        <MapContent>
          <Form {...formItemLayout} layout='horizontal' form={form} scrollToFirstError={true}>
            {pageErrors.length > 0 ? (
              <AlertWrapper>
                <Alert
                  message='При выполнении операции возникла ошибка:'
                  showIcon
                  type='error'
                  description={pageErrors.join('. ')}
                />
              </AlertWrapper>
            ) : null}
            {pageSuccess.length > 0 ? (
              <div style={{marginBottom: 40}}>
                <Alert
                  message={pageSuccess.join('. ')}
                  showIcon
                  type='success'
                />
              </div>
            ) : null}
            {!isDataLoading ? (
              <DefaultJsonForm
                formData={formDataRef.current}
                schema={matchedJSONSchema}
                onChange={onFormData}
                orientation='horizontal'
              />
            ) : (
              <Skeleton active/>
            )}
          </Form>
          <Tabs type='card'>
            <Tabs.TabPane tab='История заказов' key='1'>
              <TabGeneral clientId={id}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab='Журнал звонков' key='2'>
              <TableCallLog clientId={id}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab='Дополнительные контакты' key='3'>
              <TableContacts userId={id}/>
            </Tabs.TabPane>
          </Tabs>
          <Button onClick={saveCase} style={{ marginBottom: 16, marginTop: 16 }} type='primary'>
            <SaveOutlined />
            Сохранить
          </Button>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}

