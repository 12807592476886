import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { Form, Switch } from 'antd'
import { debounce } from 'lodash'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import InputNumber from '../../input-number'
import InputInteger from '../../input-integer'
import InputCheckbox from '../../Input-checkbox'
import InputDateTime from '../../input-date-time'
import InputDate from '../../input-date'
import InputTime from '../../input-time'
import InputTimeRange from '../../input-time-range'
import InputDateRange from '../../input-date-range'
import InputPhone from '../../input-phone'
import InputPhoneClient from '../../input-phone-client'
import InputText from '../../input-text'
import ArrayMultiField from '../../array-multi-field'
import SelectFromEnum from '../../select-from-enum'
import CollectionSelect from 'src/react-app/connectors/json-form-select-object'
import CollectionSelectTypeAdvertising from 'src/react-app/connectors/json-form-select-object-type-advertising'
import SelectFromRoles from 'src/react-app/connectors/select-from-roles'
import UserSelect from '../../app-user-select'
import AdaptorObjectField from './adaptor-field'
import FileS3 from 'src/react-app/connectors/multi-file-s3-json-form/multi-file-s3-json-form'
import UploadFilesS3 from 'src/react-app/connectors/multi-file-s3-new/multi-file-s3-new'
import SelectFromOrganizationTypes from 'src/react-app/connectors/select-from-organization-types'
import SelectFromUser from 'src/react-app/connectors/select-from-user'
import SelectFromOrganizationInn from 'src/react-app/connectors/select-from-organization-inn'
import SelectFromUsers from 'src/react-app/connectors/select-from-users'
import SelectFromOrganizations from 'src/react-app/connectors/select-from-organizations'
import SelectFromOrganizationsV2 from 'src/react-app/connectors/select-from-organizations-v2'
import ContractOrderPrice from 'src/react-app/connectors/contract-order-price'
import ContractOrderPricePay from 'src/react-app/connectors/contract-order-price-pay'
import ContractDebt from 'src/react-app/connectors/contract-debt'
import InputRadioGroup from 'src/react-app/components/input-radio-group'
import InputTextAddress from 'src/react-app/components/input-text-address'
import InputTextDadata from 'src/react-app/components/input-text-dadata'
import SelectFromRegions from 'src/react-app/connectors/select-from-regions'
import SelectFromCity from 'src/react-app/connectors/select-from-city'
import MultiSelect from 'src/react-app/connectors/multi-select'
import InputPassword from 'src/react-app/components/input-password'
import { InputPaymentAmount } from '../../input-payment-amount/ui/input-payment-amount'
import { FormWrapper } from './styles'
import './index.css'
import InputAdditionalContacts from '../../input-additional-contacts'

export function getInputNode(value, UISchema?: any, data?: unknown){
  if (value?.type === 'string') {
    if (value?.view?.name === 'input-radio-group') {
      return <InputRadioGroup schema={value} UISchema={UISchema} />
    }
    if (value?.view?.name === 'input-text-address') {
      return <InputTextAddress schema={value} UISchema={UISchema} />
    }
    if (value?.view?.name === 'input-text-dadata') {
      return <InputTextDadata schema={value} UISchema={UISchema} />
    }
    if(value?.format === 'date-time'){
      return <InputDateTime UISchema={UISchema} />
    }
    if(value?.format === 'date'){
      return <InputDate UISchema={UISchema} schema={value} value={data} onChange={() => {}}/>
    }
    if(value?.format === 'time'){
      return <InputTime UISchema={UISchema} />
    }
    if (value?.format === 'phone') {
      return <InputPhone UISchema={UISchema} />
    }
    if (value?.format === 'phone-client') {
      return <InputPhoneClient UISchema={UISchema} />
    }
    if (value?.format === 'additional-contacts') {
      return <InputAdditionalContacts value={value} />
    }
    if(value?.format === 'platform-collection'){
      return <CollectionSelect schema={value} UISchema={UISchema} />
    }
    if(value?.format === 'platform-user'){
      return <UserSelect schema={value} UISchema={UISchema} />
    }
    if(value?.format === 'file-s3'){
      if (value?.options?.isNewUrl) {
        return <UploadFilesS3 value={data} schema={value} UISchema={UISchema} />
      }
      return <FileS3 value={data} onChange={() => {}} schema={value} UISchema={UISchema} />
    }
    if (value?.format === 'time-range') {
      return <InputTimeRange schema={value} UISchema={UISchema} />
    }
    if (value?.format === 'date-range') {
      return <InputDateRange schema={value} UISchema={UISchema} />
    }
    if (value?.view?.name === 'select-from-enum'){
      return <SelectFromEnum schema={value} UISchema={UISchema} />
    }
    if (value?.view?.name === 'password') {
      return <InputPassword schema={value} UISchema={UISchema} />
    }
    /* Проблем не обнаружено */
    return <InputText schema={value} UISchema={UISchema} />
  }
  if (value?.type === 'pointer'){
    if (value?.view?.name === 'select-from-organization-types') {
      return <SelectFromOrganizationTypes UISchema={UISchema} />
    }
    else if (value?.view?.name === 'select-from-organizations'){
      return <SelectFromOrganizations UISchema={UISchema} />
    }
    else if (value?.view?.name === 'select-from-organizations-v2'){
      return <SelectFromOrganizationsV2 UISchema={UISchema} />
    }
    else if (value?.view?.name === 'select-from-regions') {
      return <SelectFromRegions UISchema={UISchema} />
    }
    else if (value?.view?.name === 'select-from-city') {
      return <SelectFromCity UISchema={UISchema} />
    }
    else if (value?.view?.name === 'select-from-user') {
      return <SelectFromUser UISchema={UISchema} />
    }
    else if (value?.view?.name === 'select-from-users') {
      return <SelectFromUsers UISchema={UISchema} />
    }
    else if (value?.view?.name === 'select-from-organization-inn') {
      return <SelectFromOrganizationInn UISchema={UISchema} />
    }
    else if(value?.view?.name === 'type-advertising') {
      return <CollectionSelectTypeAdvertising schema={value} UISchema={UISchema}/>
    }
    else {
      return <MultiSelect schema={value} value={data} UISchema={UISchema}/>
    }
  }
  if(value?.type === 'boolean'){
    return <InputCheckbox UISchema={UISchema} />
  }
  if(value?.type === 'number'){
    if (value?.view?.name === 'contract-order-price') {
      return <ContractOrderPrice UISchema={UISchema} />
    }
    if (value?.view?.name === 'contract-order-price-pay') {
      return <ContractOrderPricePay UISchema={UISchema} />
    }
    return <InputNumber UISchema={UISchema} />
  }
  if(value?.type === 'integer'){
    return <InputInteger UISchema={UISchema} />
  }
  if (value?.type === 'array') {
    if (value?.view?.name === 'select-from-roles') {
      return <SelectFromRoles schema={value} UISchema={UISchema} />
    }
    return <ArrayMultiField schema={value} UISchema={UISchema} />
  }
  if (value?.type === 'object') {
    return <AdaptorObjectField schema={value} />
  }
  if (value?.type === 'devider') {
    return value?.content
  }
  if (value?.type === 'switch') {
    return <Switch onChange={() => {}} defaultChecked={!!data} />
  }
  return null
}

function getInputNodeCustom(schema, UISchema?: any, data?: unknown){
  if (UISchema?.render) {
    return <UISchema.render schema={schema} UISchema={UISchema} />
  }
  return getInputNode(schema, UISchema, data)
}

function getFiledRules(props, requiredUISchema): any[] {
  const { required, type, format }: { required: boolean, type: string, format: string | undefined } = props
  const isString = type === 'string'
  if(isString && (format === 'email')){
    return [{ type: 'email', required, message: 'Неверный формат' }]
  }
  return [{ required: requiredUISchema, message: 'Обязательное поле' }]
}

function getUISchemaValue(UISchema, key){
  return getDataOfType(UISchema, key, Object, null)
}

function FormDataEditor({ formData = {}, schema, UISchema, onChange, orientation = 'vertical' }: { formData, schema, UISchema?, onChange, orientation? }, ref){
  const [form] = Form.useForm()
  const [required, setRequired] = useState<string[]>(schema?.required || [])

  useImperativeHandle(ref, () => ({
    validateFields: params => form.validateFields(params)
  }))

  useEffect(() => {
    const antFromData = form.getFieldsValue()
    const formDataKeys = Object.keys(formData || {})
    const nextAntFormData = {}

    if( schema?.required ) {
      setRequired(schema.required.filter((key: string) => !formData[key] || formData[key].length === 0))
    }

    formDataKeys.forEach(key => {
      if (typeof formData[key] === 'object') {
        return
      }
      if (Array.isArray(formData[key])) {
        nextAntFormData[key] = formData[key]
      }
      if (!(formData[key] === antFromData[key])) {
        nextAntFormData[key] = formData[key]
      }
    })
    form.setFieldsValue(nextAntFormData)
    return () => {}
  }, [form, formData])

  const onValuesChange = useCallback(debounce((_, nextFormData = {}) => {
    Object.assign(formData ?? {}, nextFormData)
    onChange({ formData: formData ?? ({}) , schema })
    //setRequired(schema.required.filter( (key) => !formData[key] || formData[key].length === 0 ) )
  }, 200), [schema, onChange, formData])

  const properties: any = useMemo(() => {
    const propertiesSchema = schema['properties'] || {}
    const requiredSchema: any[] = schema['required'] || []

    const properties = Object.entries(propertiesSchema).map(([key, value]: [string, any]) => {
      const nextValue = Object.assign({ key }, value)
      const UISchemaValue = getUISchemaValue(UISchema, key)
      if(UISchemaValue?.required) {
        UISchemaValue.required = required.includes(key)
      }
      const InputNode: any = getInputNodeCustom(nextValue, UISchemaValue, formData?.[nextValue?.['key']])
      return { ...value, title: value['title'] || key, name: key, render: InputNode, required: requiredSchema.includes(key) }
    })

    return properties.filter(({ render }) => render !== null)
  }, [schema, formData, UISchema])

  useEffect(() => {
    form.validateFields()
  }, [UISchema])

  return (
    <Form layout='vertical' form={form} onValuesChange={onValuesChange} initialValues={formData}>
      <FormWrapper orientation={orientation}>
        {(() => {
          const mergedFieldsMap = new Map()

          properties.forEach(({ name, merge_fields }) => {
            if (merge_fields) {
              merge_fields.forEach(field => {
                mergedFieldsMap.set(field, name)
              })
            }
          })

          return properties
            .filter(({ name }) => getDataOfType(UISchema, [name, 'hidden'], Boolean, false) === false)
            .map(({ title, name, render, merge_fields, ...props }) => {
              if (mergedFieldsMap.has(name)) {
                return null
              }

              const requiredField = (props?.required || props?.options?.required) && (!formData?.[name] || formData?.[name]?.length === 0)
              const rules = getFiledRules(props, requiredField)
              const style = props?.style

              const additionalFields = merge_fields?.map(fieldName =>
                properties.find(prop => prop.name === fieldName)
              ).filter(Boolean) || []

              return (
                <div key={name} style={{ ...style, background: 'none' }}>
                  {name === 'order-price' ? (
                    props.view === 'new' ? (
                      <InputPaymentAmount
                        name={name}
                        title={title}
                        render={render}
                        UISchema={UISchema}
                        otherProps={props}
                        rules={rules}
                        required={requiredField}
                      />
                    ) : (
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Form.Item label={title} name={name}>
                          {render}
                        </Form.Item>
                        <Form.Item label='Задолженность' name={name}>
                          <ContractDebt />
                        </Form.Item>
                        <Form.Item label='Предоплата' name='contract-order-price-pay'>
                          <ContractOrderPricePay UISchema={UISchema} />
                        </Form.Item>
                      </div>
                    )
                  ) : (
                    <div style={{ display: 'flex', gap: '16px', width: '100%', alignItems: 'flex-end', height: '100%' }}>
                      <Form.Item
                        style={{ marginBottom: 0, flex: 1, maxWidth: '100%' }}
                        label={props?.type !== 'devider' && props?.type !== 'boolean' ? title : null}
                        name={name}
                        rules={rules}
                        required={requiredField}
                      >
                        {render}
                      </Form.Item>

                      {additionalFields.map(field => (
                        <Form.Item
                          style={{ marginBottom: 0, flex: 1 }}
                          key={field.name}
                          label={field?.options?.hide_title ? '' : field.title}
                          name={field.name}
                          rules={getFiledRules(field, field.required)}
                          required={field.required}
                          className={field?.options?.highlight ? 'highlight' : ''}
                        >
                          {field.render}
                        </Form.Item>
                      ))}
                    </div>
                  )}
                </div>
              )
            })
        })()}
      </FormWrapper>
    </Form>
  )
}

export default forwardRef(FormDataEditor)
