import { calcOrders, OrderByMonthItemCalc, sumStatistics } from './calcOrders'
import {
  IOrdersForUnitByMonth,
  IOrderByMonthItemCalc,
  IOrdersByMonth,
  OrdersForUnit,
  IOrderByMonthItemTotal,
} from '../types/types'

export const manyTablesData = (
  monthManagersOrders: IOrdersForUnitByMonth,
  monthOfficeOrders: IOrdersForUnitByMonth | undefined,
  monthKeys: string[],
  selectedMonths: string[],
  selectedYears: string[],
  showOffices: boolean,
): IOrdersByMonth => {
  const ordersByMonthResult = {} as IOrdersByMonth
  monthKeys.forEach((month: string) => {
    if (!ordersByMonthResult[month]) {
      ordersByMonthResult[month] = { units: [], total: {} as IOrderByMonthItemTotal }
    }

    Object.keys(monthManagersOrders[month]).forEach(managerId => {
      const unitStats = monthManagersOrders[month][managerId]
      
      const calc = calcOrders(
        unitStats,
        selectedMonths,
        selectedYears,
        `${unitStats.managerName || managerId}`,
        (showOffices && (unitStats.officeName ?? '-')) || undefined,
      )

      ordersByMonthResult[month].units.push(calc)
    })
    ordersByMonthResult[month].total = sumStatistics(
      ordersByMonthResult[month].units,
      'Всего',
      showOffices ? '' : undefined,
    ).toTotal()

    showOffices &&
      monthOfficeOrders &&
      Object.keys(monthOfficeOrders[month]).forEach(office => {
        const unitStats = monthOfficeOrders[month][office]
        const calc = calcOrders(unitStats, selectedMonths, selectedYears, `Итог`, office)

        ordersByMonthResult[month].units.push(calc)
      })
  })
  return ordersByMonthResult
}
