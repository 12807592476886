import React, { memo, useCallback, useEffect, useState } from 'react'
import { Form, Tabs } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { classNames } from '../../../../../lib/classNames/classNames'
import { HStack } from '../../../../../ui/Stack'
import { CardTimeline } from '../tab-timeline'
import { setTimelineClear } from '../../../../../store/timeline/actions'
import { getTimeLineData } from '../../../../../store/timeline/action-creators'
import { setStagePlanClear } from '../../../../../store/stagePlan/actions'
import { createSession, getCardID } from '../../selectors'
import { StagePlan } from '../stage-plan'
import cls from '../../../../appeals/card/card-case.module.css'
import AppChat from '../../../../../components/app-chat'
import { getDataOfType } from '../../../../../../lib/utils/get-data-of-type'
import fetchAPI from '../../../../../../lib/utils/fetch-api'

interface CardTabsProps {
  className?: string
  onChange?: (key: string) => void
  onTabClick?: (key: string) => void
}

export const CardTabs: React.FC<CardTabsProps> = memo((props: CardTabsProps) => {
  const { className, onChange, onTabClick } = props
  const dispatch = useDispatch()
  const id = useSelector(getCardID)

  useEffect(() => {
    let mounted = true
    dispatch(setStagePlanClear())

    if (mounted && id) {
      dispatch(getTimeLineData('claim_request', id))
    }
    return () => {
      mounted = false
      dispatch(setTimelineClear())
      dispatch(setStagePlanClear())
    }
  }, [id, dispatch])

  const [chatData, setChatData] = useState<any>([])
  const [formChat] = Form.useForm()
  const { sessionData } = useSelector(createSession)

  const getChatData = useCallback(async () => {
    const resultChat = await fetchAPI(`/api/claims_request_chat/?filter[claims_request_id]=${id}`)
    setChatData( resultChat || [])
  }, [id])

  const onChatSend = async (values: any) => {
    const userId = getDataOfType(
      sessionData,
      'user.id',
      Number,
      null,
    )

    await fetchAPI(`/api/claims_request_chat/`, {
      method: 'POST',
      body: JSON.stringify({
        claims_request_id: id,
        manager_id: userId,
        msg: values.message
      })
    })

    getChatData()
    formChat.resetFields()
  }

  const handleOnChange = useCallback(key => {
    if (onChange) {
      onChange(key)
    }
  }, [onChange])

  const handleOnTabClick = useCallback(key => {
    if (onTabClick) {
      onTabClick(key)
    }
    if (key === '3') {
      getChatData()
    }
  }, [getChatData, onTabClick])

  if (!id) {
    return null
  }
  return (
    <Tabs
      onChange={handleOnChange}
      type='card'
      style={{ marginTop: '2rem', backgroundColor: '#fff', paddingBottom: '2rem' }}
      className={classNames('mapContent', {}, [className])}
      onTabClick={handleOnTabClick}
    >
      <Tabs.TabPane tab='Лента событий' key='1'>
        <HStack align={'start'} justify={'between'}>
          <div className={cls.appealFilesTimeline}>
            <StagePlan id={id} />
          </div>
          <div className={cls.appealTimeline}>
            <CardTimeline id={id} />
          </div>
        </HStack>
      </Tabs.TabPane>
      {/*
      <Tabs.TabPane tab='Прикрепленные файлы' key='2'>
        <HStack align={'start'} justify={'between'}>
          <div className={cls.appealFilesTimeline}>
            <CardFiles id={id} />
          </div>
        </HStack>
      </Tabs.TabPane>
      */}
      <Tabs.TabPane tab='Обсуждения по заявке' key='3'>
        <HStack align={'start'} justify={'between'}>
          <div className={cls.appealFilesTimeline}>
            <AppChat
              data={chatData}
              form={formChat}
              onSubmit={onChatSend}
            />
          </div>
        </HStack>
      </Tabs.TabPane>
    </Tabs>
  )
})

export default Tabs
