import {ActivityItemFile, ActivityItemFileWDate, ActivityItems, ActivityUpdateItem, FileFolderData, ITimeline} from "../../components/v2/activity-timeline/types/types";
import {
  TIMELINE_ADD_FILE_TO_FOLDER,
    TIMELINE_ADD_ITEM,
    TIMELINE_CLEAR,
    TIMELINE_CLEAR_FILE_FOLDER,
    TIMELINE_DATA,
    TIMELINE_DELETE_ITEM,
    TIMELINE_FAILED,
    TIMELINE_LOADING,
    TIMELINE_UPDATE_ITEM
} from "./actions";
import {setTimelineItem} from "../../components/v2/activity-timeline/services/setTimelineItem";
import {updateTimelineItemByIndex} from "../../components/v2/activity-timeline/services/updateTimelineItemByIndex";
import { updateTimelineItemByItemId} from "../../components/v2/activity-timeline/services/updateTimelineItemByItemId";
import {deleteTimelineItem} from "../../components/v2/activity-timeline/services/deleteTimelineItem";
import { clearTimelineFileFolders, updateTimelineFileFolders } from 'src/react-app/components/v2/activity-timeline/services/updateTimelineFileFolders';


const initialState: ITimeline = {
    isLoading: false,
    error: '',
    data: {
        items: [],
        itemsDates: [],
        total: undefined,
        offset: undefined,
        page: undefined,
        limit: undefined,
    }
}

export const timelineReducer = (
    state = initialState,
    {type, payload},
) => {
    switch (type) {
        case TIMELINE_LOADING:
            return {
                ...state,
                isLoading: true,
                error: '',
            }
        case TIMELINE_DATA:
            return {
                ...state,
                isLoading: false,
                error: '',
                data: payload
            }
        case TIMELINE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
                data: {
                    ...initialState
                }
            }
        case TIMELINE_CLEAR:
            return {
                ...state,
                isLoading: false,
                error: '',
                data: {
                    ...initialState
                }
            }
        case TIMELINE_ADD_ITEM:
            const newActivityItems = payload as ActivityItems[];
            let timelineItems: ActivityItems[] = state.data?.items || [];
            let timelineDaysItems = state.data?.itemsDates || [];

            if (!newActivityItems.length) {
                return state;
            }

            const {
                timelineItems: timelineItemsMod,
                timelineDates: timelineDaysItemsMod
            } = setTimelineItem(newActivityItems, timelineItems, timelineDaysItems);

            return {
                ...state,
                data: {
                    ...state.data,
                    items: [...timelineItemsMod],
                    itemsDates: [...timelineDaysItemsMod]
                }
            }
        case TIMELINE_UPDATE_ITEM: {
            const newDataItem = payload as ActivityUpdateItem;
            let timelineItems: ActivityItems[] = state.data?.items || [];
            let timelineDaysItems = state.data?.itemsDates || [];
            
            const timelineItemsMod = newDataItem?.index || newDataItem?.index === 0
                ? updateTimelineItemByIndex(newDataItem, timelineItems, timelineDaysItems)
                : updateTimelineItemByItemId(newDataItem, timelineItems, timelineDaysItems);
            return {
                ...state,
                data: {
                    ...state.data,
                    items: [...timelineItemsMod],
                }
            }
        }
        case TIMELINE_DELETE_ITEM: {
            const deletedItem = payload as ActivityUpdateItem;
            let timelineItems: ActivityItems[] = state.data?.items || [];
            let timelineDaysItems = state.data?.itemsDates || [];

            const {
                timelineItems: timelineItemsMod,
                timelineDates: timelineDaysItemsMod
            } = deleteTimelineItem(deletedItem, timelineItems, timelineDaysItems);

            return {
                ...state,
                data: {
                    ...state.data,
                    items: [...timelineItemsMod],
                    itemsDates: [...timelineDaysItemsMod]
                }
            }
        }
        case TIMELINE_ADD_FILE_TO_FOLDER: {
            
            const newDataItem = payload as {items: ActivityItemFileWDate[], folder: FileFolderData};
            let timelineItems: ActivityItems[] = state.data?.items || [];
            let timelineDaysItems = state.data?.itemsDates || [];
            
            const timelineItemsMod = updateTimelineFileFolders(newDataItem, timelineItems, timelineDaysItems);
            return {
                ...state,
                data: {
                    ...state.data,
                    items: [...timelineItemsMod],
                }
            }
        }
        case TIMELINE_CLEAR_FILE_FOLDER: {
            
            const itemsToClear = payload as {items: ActivityItemFileWDate[]};
            let timelineItems: ActivityItems[] = state.data?.items || [];
            let timelineDaysItems = state.data?.itemsDates || [];
            
            const timelineItemsMod = clearTimelineFileFolders(itemsToClear, timelineItems, timelineDaysItems);
            return {
                ...state,
                data: {
                    ...state.data,
                    items: [...timelineItemsMod],
                }
            }
        }
        default:
            return state
        }
    }
