import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import fetchAPI from '../../../../../lib/utils/fetch-api'
import dayjs from 'dayjs'
import { DarkLink } from '../../../../components/dark-link'
import { formatCurrency } from '../../../../components/v2/product-detail/model/services/formatCurrency'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (id: string | number) => <DarkLink to={`/order/${id}`}>{id}</DarkLink>,
  },
  {
    title: 'Стадия',
    dataIndex: 'stage',
    key: 'stage',
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text: string) => dayjs(text).format('DD.MM.YYYY HH:mm'),
  },
  {
    title: 'Сумма',
    dataIndex: 'sum',
    key: 'sum',
    render: (value: number) => formatCurrency(value),
  },
  {
    title: 'Адрес объекта',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Номер производственного заказа',
    dataIndex: 'production_number',
    key: 'production_number',
  },
]

interface IOrderData {
  id: number
  stage: string
  created_at: string
  sum: number
  address: string
  production_number: string
}

function TabGeneral({ clientId }){
  const [data, setData] = useState<IOrderData[]>([])
  useEffect(() => {
    (async () => {
      const result = await fetchAPI(`/api/order-list?customer=${clientId}`)
      setData(() => result.map(item => ({
        id: item?.id,
        stage: item?.stage,
        created_at: item?.created_at,
        sum: item?.sum,
        address: item?.address,
        production_number: item?.production_number,
      })))
      const unfinishedCount = result.filter(item => !['done', 'cancelled'].includes(item.stage_code))?.length
    })()
  }, [clientId])

  return <Table dataSource={data} columns={columns} pagination={false} key='id' />
}

export default TabGeneral
