import dayjs from 'dayjs'

export const filtersList = (currentFilters, availableFilters) => {
  return [
    {
      name: 'date_application',
      label: 'Дата запуска заказа',
      type: 'dates',
      value: currentFilters?.date_application,
      skipReset: currentFilters?.date_application
        ? dayjs(currentFilters?.date_application.split('-')[0]).isSame(dayjs(currentFilters?.date_application.split('-')[1]))
        : false
    },
    {
      name: 'stage',
      label: 'Стадия',
      type: 'select_multiple',
      value: currentFilters?.stage?.split(',').filter(Boolean),
      options: {
        enum: availableFilters?.stage || [],
      },
    },
    {
      name: 'dealer_code',
      label: 'Код клиента дилера',
      type: 'input-integer',
      value: currentFilters?.dealer_code,
    },
    {
      name: 'address',
      label: 'Адрес объекта',
      type: 'input-string',
      value: currentFilters?.address,
    },
    {
      name: 'name',
      label: 'ФИО клиента',
      type: 'input-string',
      value: currentFilters?.name,
    },
    {
      name: 'phone',
      label: 'Телефон клиента',
      type: 'phone',
      value: currentFilters?.phone,
    },
    {
      name: 'classification_id',
      label: 'Классификация',
      type: 'select_multiple',
      value: currentFilters?.classification_id?.split(',').map(Number).filter(Boolean),
      options: {
        enum: availableFilters?.classification || [],
      }
    },
    {
      name: 'dealer_requirement_id',
      label: 'Требования дилера',
      type: 'select_multiple',
      value: currentFilters?.dealer_requirement_id?.split(',').map(Number).filter(Boolean),
      options: {
        enum: availableFilters?.dealer_requirement || [],
      }
    },
    {
      name: 'num_tech',
      label: 'Номер тех. задания',
      type: 'input-string',
      value: currentFilters?.num_tech,
    },
  ]
}
