import dayjs from 'dayjs'

interface FilterOption {
  label: string
  value: string
}

interface Filter {
  name: string
  label: string
  type: string
  value?: any
  options?: {
    enum?: FilterOption[] | string[]
  }
  skipReset?: boolean
}

export const getFilters = ({ currentFilters, availableFilters }): Filter[] => [
  {
    name: 'date',
    label: 'Дата поездки',
    type: 'dates',
    value: currentFilters?.date,
    skipReset: currentFilters?.date
      ? dayjs(currentFilters.date.split('-')[0]).isSame(dayjs(currentFilters.date.split('-')[1]))
      : false
  },
  {
    name: 'vehicle_id',
    label: 'Машина',
    type: 'select',
    value: currentFilters?.vehicle_id,
    options: {
      enum: availableFilters?.vehicle
    }
  },
  {
    name: 'driver_name',
    label: 'Водитель',
    type: 'input-string',
    value: currentFilters?.driver_name
  },
]