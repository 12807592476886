import dayjs from 'dayjs'
import { formatPrice } from 'src/react-app/pages_v2/utils'
import { SortOrder } from 'antd/lib/table/interface'

export const getOrdersByManagerColumns = (showOffice: boolean) => {
  return [
    // {
    //   title: '№',
    //   key: 'rowIndex',
    //   // dataIndex: 'rowIndex',
    //   // width: '3rem',
    //   // defaultSortOrder: 'ascend' as SortOrder,
    // },
    {
      title: 'Менеджер',
      dataIndex: 'manager',
      key: 'manager',
      sorter: {
        compare: (a, b) => a.manager.localeCompare(b.manager),
        multiple: 4,
      },
    },
    ...(showOffice
      ? [
          {
            title: 'Офис',
            dataIndex: 'office',
            key: 'office',
            sorter: {
              compare: (a, b) => a.office.localeCompare(b.office),
              multiple: 6,
            },
            
            defaultSortOrder: 'ascend' as SortOrder,
          },
        ]
      : []),
    {
      title: 'Дата оплаты',
      dataIndex: 'orderDate',
      key: 'orderDate',
      sorter: {
        //@ts-ignore
        compare: (a, b) => new Date(a.orderDate) - new Date(b.orderDate),
        multiple: 1,
      },
      render: v => (v ? dayjs(v).format('DD/MM/YY') : '-'),
    },
    {
      title: 'Дата заявки',
      dataIndex: 'caseDate',
      key: 'caseDate',
      sorter: {
        //@ts-ignore
        compare: (a, b) => new Date(a.caseDate) - new Date(b.caseDate),
        multiple: 2,
      },
      render: v => (v ? dayjs(v).format('DD/MM/YY') : '-'),
    },
    {
      title: 'Договор',
      dataIndex: 'contractNum',
      key: 'contractNum',
    },
    {
      title: 'Сделка, дни',
      dataIndex: 'days',
      key: 'days',
      sorter: {
        compare: (a, b) => a.days - b.days,
        multiple: 3,
      },
      width: '3rem'
    },
    {
      title: 'Клиент',
      dataIndex: 'clientName',
      key: 'clientName',
      sorter: {
        compare: (a, b) => a.manager.localeCompare(b.manager),
        multiple: 4,
      },
    },
    {
      title: 'Продажи',
      dataIndex: 'sum',
      key: 'sum',
      sorter: {
        compare: (a, b) => a.sum - b.sum,
        multiple: 5,
      },
      render: v => formatPrice(v),
    },
  ]
}

export const getOrdersByMonthColumns = (showOffice: boolean) => {
  return [
    {
      title: 'Менеджер',
      dataIndex: 'manager',
      key: 'manager',
      sorter: {
        compare: (a, b) => a.manager?.localeCompare?.(b.manager),
        multiple: 2,
      },
    },
    ...(showOffice
      ? [
          {
            title: 'Офис',
            dataIndex: 'office',
            key: 'office',
            sorter: {
              compare: (a, b) => a.office?.localeCompare?.(b.office),
              multiple: 1,
            },
            defaultSortOrder: 'ascend' as SortOrder,
          },
        ]
      : []),
    {
      title: 'ЦО',
      dataIndex: 'co',
      key: 'co',
      sorter: {
        compare: (a, b) => a.co - b.co,
        multiple: 1,
      },
    },
    {
      title: 'Отказы до КП',
      dataIndex: 'cancel_before_kp',
      key: 'cancel_before_kp',
      width: '6rem',
      sorter: {
        compare: (a, b) => a.cancel_before_kp - b.cancel_before_kp,
        multiple: 3,
      },
    },
    {
      title: 'Отказы после КП',
      dataIndex: 'cancel_after_kp',
      key: 'cancel_after_kp',
      width: '6rem',
      sorter: {
        compare: (a, b) => a.cancel_after_kp - b.cancel_after_kp,
        multiple: 4,
      },
    },
    {
      title: 'Заказы в работе',
      dataIndex: 'workOrders',
      key: 'workOrders',
      width: '6rem',
      sorter: {
        compare: (a, b) => a.workOrders - b.workOrders,
        multiple: 5,
      },
    },
    {
      title: 'Замеров выполнено',
      dataIndex: 'measurements',
      key: 'measurements',
      width: '6rem',
      sorter: {
        compare: (a, b) => a.measurements - b.measurements,
        multiple: 6,
      },
    },
    {
      title: 'Договоры оплаченные в данном месяце',
      dataIndex: 'contracts',
      key: 'contracts',
      width: '6rem',
      sorter: {
        compare: (a, b) => a.contracts - b.contracts,
        multiple: 9,
      },
    },
    // Позже может вернуться с другой фромулой
    // {
    //   title: '% зам/дог',
    //   dataIndex: 'percent_md',
    //   key: 'percent_md',
    //   sorter: {
    //     compare: (a, b) => a.percent_md - b.percent_md,
    //     multiple: 8,
    //   },
    //   render: (v) => (typeof v === 'number' ? v?.toFixed(2) + '%' : v),
    // },
    {
      title: 'Сумма заказов в работе',
      dataIndex: 'workOrdersSum',
      key: 'workOrdersSum',
      sorter: {
        compare: (a, b) => a.workOrdersSum - b.workOrdersSum,
        multiple: 14,
      },
      render: v => (v ? formatPrice(v) : '0'),
    },
    {
      title: 'Средний чек заявки',
      dataIndex: 'workOrders_average_score',
      key: 'workOrders_average_score',
      sorter: {
        compare: (a, b) => a.workOrders_average_score - b.workOrders_average_score,
        multiple: 15,
      },
      render: v => (v ? formatPrice(v) : '0'),
    },
    {
      title: '% ЦО/дог',
      dataIndex: 'percent_cc',
      key: 'percent_cc',
      sorter: {
        compare: (a, b) => a.percent_cc - b.percent_cc,
        multiple: 10,
      },
      render: v => (typeof v === 'number' ? v?.toFixed(2) + '%' : v),
    },
    {
      title: 'СЧ продаж',
      dataIndex: 'average_score',
      key: 'average_score',
      sorter: {
        compare: (a, b) => a.average_score - b.average_score,
        multiple: 11,
      },
      render: v => (v ? formatPrice(v) : ''),
    },
    {
      title: 'Cумма продаж',
      dataIndex: 'sum',
      key: 'sum',
      sorter: {
        compare: (a, b) => a.sum - b.sum,
        multiple: 13,
      },
      render: v => (v ? formatPrice(v) : ''),
    },
  ]
}
