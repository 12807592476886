import React, { memo, useMemo } from 'react'
import { Table, Typography } from 'antd'
import { IOrderByMonthItemCalc, IOrdersByMonth, IOrdersByMonthColumns } from '../../model/types/types'
import './styles.css';

const { Title, Text } = Typography
export interface OrdersByManagersTableProps {
  data?: IOrdersByMonth
  columns?: IOrdersByMonthColumns
}

const OrdersByMonthTable = (props: OrdersByManagersTableProps) => {
  const { data, columns } = props
  if (!data || !columns) {
    return <Typography.Text>Сервер не может найти запрошенный ресурс. </Typography.Text>
  }
  const months = useMemo(() => Object.keys(data), [data])
  return (
    <div className='orders-by-months-tables'>
      {months.map((month) => {
        
        
        return (
          <Table
            title={() => <Title level={5}>{month}</Title>}
            key={month}
            dataSource={data[month].units}
            columns={columns}
            pagination={false}
            size='small'
            style={{ marginBottom: '40px' }}
            rowClassName={(record, i) => {
              return record?.manager?.includes('Итог') ? 'row-bold' : ''
            }}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  {
                  Object.entries(data[month].total).map(([key, val], index) => {
                    if (key.startsWith('_') || val == undefined) {
                      return
                    }
                    return (
                      <Table.Summary.Cell key={key} index={index}>
                        <Text strong>{val}</Text>
                      </Table.Summary.Cell>
                    )
                  })}
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        )
      })}
    </div>
  )
}

export default memo(OrdersByMonthTable)
