import React, { memo, useCallback } from "react"
import fetchAPI from "../../../../../lib/utils/fetch-api"
import { ExportExcelButton, format2Print } from "src/react-app/components/v2/export-excel-button"
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export interface DeliveriesPrintButtonProps {
    path: string
    columns: any[]
    stages: any[]
}

const excludeColumns = ['stage', 'approval_mark', 'payment_verified', 'order_status_in_wd', 'operation']
const timeZone = 'Europe/Moscow'

export const DeliveriesPrintButton: React.FC<DeliveriesPrintButtonProps> = memo((props) => {
    const { path, columns, stages } = props

    const localColumns = columns
      .filter(column => !excludeColumns.includes(column.key))
      .map(column => {
          if (column.dataIndex === "sequence_in_run") {
              return {
                  ...column,
                  title: "Номер очередности в заказе"
              }
          }
          if (column.dataIndex === "delivery_complexity") {
              return {
                  ...column,
                  title: "Сложность доставки"
              }
          }
          if (column.dataIndex === "cost") {
              return {
                  ...column,
                  title: "Количество изделий"
              }
          }
          if (column.dataIndex === "old_delivery_date") {
              return {
                  ...column,
                  dataIndex: "initial_delivery_date",
                  key: "initial_delivery_date",
              }
          }
          return column
      })

    const formatTime = (timeString: string): string => {
        if (!timeString) return ''
        if (timeString.includes('T') && timeString.endsWith('Z')) {
            return dayjs.utc(timeString).tz(timeZone).format('HH:mm')
        }
        return dayjs(timeString).format('HH:mm')
    }

    const handlePrintClick = useCallback(async () => {
        try {
            const url = new URL(path, window.location.origin)
            url.searchParams.set('excel', 'y')

            const response = await fetchAPI(url.toString())
            const data = response.data
            if (!data) {
                return
            }

            const viewColumnNames = localColumns.map((column) => column.dataIndex).filter(key => !excludeColumns.includes(key))
            const count = data.length

            for (let i = 0; i < count; i++) {
                let keys = Object.keys(data[i])
                keys.forEach((key) => {
                    if (!viewColumnNames.includes(key)) {
                        return
                    }
                    if (key === 'stage') {
                        data[i][key] = stages.find((stage) => stage.code === data[i][key])?.name || data[i][key]
                        return
                    }
                    if (key === 'drop_window_start' || key === 'drop_window_end') {
                        data[i][key] = formatTime(data[i][key])
                        return
                    }
                    if (typeof data[i][key] === 'string' && data[i][key].includes('.') && !isNaN(data[i][key])) {
                        data[i][key] = data[i][key].replace('.', ',')
                    }
                    data[i][key] = format2Print(key, data[i][key])
                })
            }

            return data
        } catch (e) {
            console.log('handlePrintClick', e)
        }
    }, [columns, localColumns, stages, path])

    return (
      <ExportExcelButton
        fileName={'Доставки'}
        path={path}
        columns={localColumns}
        onPrintClick={handlePrintClick}
      />
    )
})