import React, { memo, useCallback, useEffect } from 'react'
import { Alert, Form, message, Skeleton } from 'antd'
import { useSelector } from 'react-redux'
import {
  getCardCurrentStage,
  getCardData,
  getCardError,
  getCardID,
  getCardStagesHistory,
  getCardStagesItems,
  getDataLoading,
  getVisibleFormData,
} from '../../selectors'
import { useDebounce } from 'src/react-app/hooks/useDebounce/useDebounce'
import { HStack, VStack } from '../../../../../ui/Stack'
import { HSteps } from '../../../../../ui/Steps'
import { DefaultJsonForm } from '../../../../../components/v2/default-json-form'
import { FormHeader } from './form-header/form-header'
import { useSaveResult } from '../../hooks/useSaveResult'
import { useHistory } from 'react-router-dom'
import { isFormDataRequired } from '../../../../../../lib/utils/collections'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

export const DataForm: React.FC = memo(() => {
  const [form] = Form.useForm()
  const formData = useSelector(getCardData)
  const formIsLoading = useSelector(getDataLoading)
  const formError = useSelector(getCardError)
  const visibleFormData = useSelector(getVisibleFormData)
  const { saveResult } = useSaveResult()
  const history = useHistory()
  const id = useSelector(getCardID)
  const stages = useSelector(getCardStagesItems)
  const current_stage = useSelector(getCardCurrentStage)
  const stages_history = useSelector(getCardStagesHistory)

  const saveFormData = useCallback(async (data) => {
    const schema = visibleFormData?.resultSchema
    const form = data?.resultObject
    if (form && schema) {
      const isRequiredFields = isFormDataRequired(form, schema)
      if (!isRequiredFields) {
        return
      }
      await saveResult(id, data)
        .then(() => {
          message.success('Данные успешно сохранены')
        })
    }
  }, [id, visibleFormData, history])

  useEffect(() => {
    const redirectUrl = formData?.redirectUrl
    if (redirectUrl) {
      history.push(redirectUrl)
    }
  }, [formData])

  const debounceSaveCard = useDebounce(
    (data) => { saveFormData(data) }, 1000
  )

  const onChangeForm = useCallback( async ({ formData }) => {
    const current = visibleFormData && visibleFormData?.resultObject
    if (current && formData && JSON.stringify(formData) !== JSON.stringify(visibleFormData?.resultObject)) {
      const updatedData = { ...visibleFormData, resultObject: formData }
      debounceSaveCard(updatedData)
    }
  }, [visibleFormData, debounceSaveCard])

  if (formError && !formIsLoading && !formData) {
    return (
      <HStack gap={'8'} className={'mapWrapper'}>
        При выполнении запроса возникла ошибка.
        Попробуйте перезагрузить страницу или открыть карточку повторно.
      </HStack>
    )
  }

  return (
    <>
      <FormHeader />
      <HStack gap={'8'} className={'mapContent'} max>
        {formError ? (
          <div className={'alertWrapper'}>
            <Alert
              message='При выполнении операции возникла ошибка:'
              showIcon
              type='error'
              description={formError}
            />
          </div>
        ) : null}
        <Form
          {...formItemLayout}
          layout='horizontal'
          form={form}
          scrollToFirstError={true}
          style={{ width: '100%' }}
        >
          {!formIsLoading ? (
            <VStack gap={'16'} max align={'stretch'} className={'appealsForm'}>
              {current_stage !== undefined && stages !== undefined && stages_history !== undefined && (
                <HSteps
                  items={Object.values(stages)}
                  current={current_stage}
                  history={stages_history}
                />)
              }
              {
                visibleFormData && <DefaultJsonForm
                  formData={{...visibleFormData.resultObject}}
                  schema={visibleFormData.resultSchema}
                  onChange={onChangeForm}
                  orientation='horizontal'
                />
              }
            </VStack>
          ) : (
            <Skeleton active/>
          )}
        </Form>
      </HStack>
    </>
  )
})
