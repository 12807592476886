import styled from 'styled-components'

export const TableContainer = styled.div`
  width: 100%;
  .sticky-footer .ant-table-body::-webkit-scrollbar {
    display: none;
  }

  .sticky-footer .ant-table-body::-webkit-scrollbar-track {
    display: none;
  }

  .ant-table.ant-table-small .ant-table-tbody > tr > td:first-child {
    padding: 0;
  }

  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 4px;
  }

  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td {
    border-color: #000;
  }

  .cell-content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
`

export const TableScroller = styled.div<{ scrollWidth?: number }>`
  position: sticky;
  z-index: 1002;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  overflow: auto hidden;
  background: rgb(240, 240, 240);
  border-top: 1px solid rgb(221, 221, 221);
  margin-bottom: 10px;
  
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: ${props => props.scrollWidth + 'px' || '100%'};
  }
`

export const TableCheckboxWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex; 
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
`