import React, { memo, useCallback } from 'react'
import fetchAPI from '../../../../../lib/utils/fetch-api'
import { ExportExcelButton, format2Print } from 'src/react-app/components/v2/export-excel-button'
import dayjs from 'dayjs'

export interface OrderPrintButtonProps {
  path: string
  columns: any[]
  stages: any[]
}

export const PrintButton: React.FC<OrderPrintButtonProps> = memo(props => {
  const { path, columns, stages } = props

  const handlePrintClick = useCallback(async event => {
    try {
      const response = await fetchAPI(path)

      const data = response?.data
      if (response?.errors) {
        event.preventDefault()
        return false
      }
      if (!data) {
        return false
      }

      const viewColumnNames = columns.map(column => column.dataIndex).filter(column => column !== 'operation')
      const count = data.length

      for (let i = 0; i < count; i++) {
        let keys = Object.keys(data[i])
        keys.forEach(key => {
          if (!viewColumnNames.includes(key)) {
            return
          }
          if (key === 'stage') {
            data[i][key] = stages.find(stage => stage.value === data[i][key])?.label || data[i][key]
            return
          }
          if (key === 'task_type') {
            data[i][key] = data[i]['type']
            return
          }
          if (key === 'responsible' && Array.isArray(data[i][key])) {
            data[i][key] = data[i][key].join(', ')
            return
          }
          if (key === 'created_at') {
            data[i][key] = dayjs(data[i][key]).fromNow() || data[i][key]
            return
          }
          data[i][key] = format2Print(key, data[i][key])
        })
      }

      return data
    } catch (e) {
      console.error('handlePrintClick error', e)
    }
  }, [columns, path])

  return (
    <ExportExcelButton
      fileName={'Задачи'}
      path={path}
      columns={columns.filter(column => column.dataIndex !== 'operation')}
      onPrintClick={handlePrintClick}
    />
  )
})
