import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Alert, Form, Modal, Skeleton } from 'antd'
import { useSelector } from 'react-redux'
import {
  getCustomersData, getOrdersFormAlert,
  getOrdersFormData,
  getOrdersFormError,
  getOrdersFormLoading,
  getVisibleFormData,
} from '../../selectors'
import { useDebounce } from 'src/react-app/hooks/useDebounce/useDebounce'
import { HStack, VStack } from '../../../../../ui/Stack'
import { HSteps } from '../../../../../ui/Steps'
import { DefaultJsonForm } from '../../../../../components/v2/default-json-form'
import { FormsHeader } from './form-header'
import { useSaveResult } from '../../hooks/useSaveResult'
import { useHistory } from 'react-router-dom'
import { MeasurementModal } from '../modals/modal-measurement'
import { ManufactureModal } from '../modals/modal-manufacture'
import { DeliveryModal } from '../modals/modal-delivery'
import { StepItem } from '../../../../../ui/Steps/Steps/Steps'
import { isEqual } from 'lodash'
import { useDispatch } from 'react-redux'
import { clearOrdersFormData } from '../../../../../store/order/actions'

interface OrdersFormsProps {
  className?: string
  id: string | number
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

export const Forms: React.FC<OrdersFormsProps> = memo((props: OrdersFormsProps) => {
  const { id } = props
  const [form] = Form.useForm()
  const ordersFormData = useSelector(getOrdersFormData)
  const ordersFormIsLoading = useSelector(getOrdersFormLoading)
  const ordersFormError = useSelector(getOrdersFormError)
  const ordersFormAlert = useSelector(getOrdersFormAlert)
  const customers = useSelector(getCustomersData)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isCustomer, setIsCustomer] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const visibleFormData = useSelector(getVisibleFormData)
  const { saveResult, createClaim } = useSaveResult()
  const history = useHistory()
  const formRef = useRef<typeof visibleFormData>()
  const [needMounting, setNeedMounting] = useState<boolean>(false)
  const [withoutMontage, setWithoutMontage] = useState<boolean>(false)
  const [prevFormData, setPrevFormData] = useState<any>(undefined)
  const dispatch = useDispatch()

  useEffect(() => {
    if (visibleFormData && ordersFormData) {
      const isCancelled = ordersFormData?.stages?.items[ordersFormData?.stages?.current].code === 'cancelled'
      setWithoutMontage(Boolean(ordersFormData?.without_montage))
      formRef.current = visibleFormData
      setIsDisabled(isCancelled)
    }
  }, [visibleFormData, ordersFormData])

  const getModalSchema = useMemo(() => {
    const modalName = visibleFormData?.modalName
    if (modalName === 'measurement') {
      if (isCustomer) {
        const required = visibleFormData?.modalFieldsSchema?.required?.filter(item => item !== 'comment' && item !== 'measurement_time')
        const props = visibleFormData?.modalFieldsSchema?.properties as Record<string, any>
        if (props && required && 'send_tg_notification' in props && 'measurement_time' in props && 'comment' in props) {
          const { send_tg_notification, measurement_time, comment, ...rest } = props
          return { required, properties: rest }
        } else {
          return visibleFormData?.modalFieldsSchema
        }
      }
      const required = visibleFormData?.modalFieldsSchema?.required?.filter(item => item !== 'measurement_scan')
      const props = visibleFormData?.modalFieldsSchema?.properties as Record<string, any>
      if (props && required && 'measurement_scan' in props) {
        const { measurement_scan, ...rest } = props
        return { required, properties: rest }
      } else {
        return visibleFormData?.modalFieldsSchema
      }
    }
    if (modalName === 'manufacture') {
      if (!needMounting) {
        const required = visibleFormData?.modalFieldsSchema?.required?.filter(item => !['montage_specification_scan', 'mounting_responsible', 'production_number', 'mounting_date', 'mounting_time', 'comment'].includes(item))
        const props = visibleFormData?.modalFieldsSchema?.properties as Record<string, any>
        if (props && required
          && 'montage_specification_scan' in props
          && 'mounting_responsible' in props
          && 'production_number' in props
          && 'mounting_date' in props
          && 'mounting_time' in props
          && 'comment' in props
        ) {
          const { montage_specification_scan, mounting_responsible, production_number, mounting_date, mounting_time, comment, ...rest } = props
          return { required, properties: rest }
        } else {
          return visibleFormData?.modalFieldsSchema
        }
      } else {
        return visibleFormData?.modalFieldsSchema
      }
    }
  }, [isCustomer, visibleFormData, needMounting])

  const saveCase = useCallback(async (isNextStage = false, stageCode?: string) => {
    await saveResult(
      id,
      formRef.current,
      ordersFormData?.stages?.current,
      isOpenModal,
      setIsOpenModal,
      isNextStage,
      stageCode
    )
  }, [history, id, formRef])

  useEffect(() => {
    const redirectUrl = ordersFormData?.redirectUrl
    if (redirectUrl) {
      history.push(redirectUrl)
    }
  }, [ordersFormData])

  const compareObjects = (obj1: any, obj2: any): string[] => {
    const diff = [];
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        if (!isEqual(obj1[key], obj2[key])) {
          // @ts-ignore
          diff.push(key)
        }
      }
    }
    return diff
  }
  const [isProgrammaticChange, setIsProgrammaticChange] = useState(false)
  const debounceSaveCase = useDebounce(saveCase, 1000)

  const onChangeForm = useCallback(async ({ formData }) => {
    if (isProgrammaticChange) {
      return
    }
    if (!formRef.current) {
      return
    }
    // проверяет, являются ли данные идентичными
    if (isEqual(prevFormData, formData)) {
      return
    }
    // если инициализация - заполняет промежуточное состояние
    if (!prevFormData && !isEqual(prevFormData, formData)) {
      setPrevFormData({ ...formData })
      return
    }

    try {
      await debounceSaveCase()
    } finally {
      setPrevFormData({ ...formData })
      formRef.current.resultObject = formData
    }
  }, [formRef, debounceSaveCase, prevFormData, isProgrammaticChange])

  useEffect(() => {
    return () => {
      // очищает промежуточное состояние
      setPrevFormData(undefined)
      // очищает стор
      dispatch(clearOrdersFormData())
    }
  }, [])

  const handleSetDone = useCallback(async () => {
    setIsDisabled(true)
    await saveCase(true, 'done')
    setIsDisabled(false)
  }, [saveCase])

  const handleOnClick = useCallback(async () => {
    try {
      setIsDisabled(true)
      setIsProgrammaticChange(true)
      await saveCase(true)
    } finally {
      setPrevFormData(undefined)
      setIsProgrammaticChange(false)
      setIsDisabled(false)
    }
  }, [saveCase, visibleFormData])

  if (ordersFormError && !ordersFormIsLoading && !ordersFormData) {
    return (
      <HStack gap={'8'} className={'mapWrapper'}>
        При выполнении запроса возникла ошибка.
        Попробуйте перезагрузить страницу или открыть карточку повторно.
      </HStack>
    )
  }

  const handleToClaim = useCallback(async () => {
    setIsDisabled(true)
    await createClaim(id)
    setIsDisabled(false)
  }, [isDisabled, createClaim])

  const onFormDataModal = useCallback(
    async ({ formData }) => {
      const modalName = visibleFormData?.modalName
      if (modalName === 'measurement') {
        setIsCustomer(
          formData?.measurement_responsible?.length == 1
          && customers.map(item => item.id).includes(formData?.measurement_responsible?.[0]),
        )
        if (!formRef.current) {
          return
        }
        const isFilesEdit = JSON.stringify(formData) === JSON.stringify(formRef.current?.modal)
        if (isFilesEdit) {
          return
        }
        formRef.current.modal = { ...formData }
      }
      if (modalName === 'manufacture') {
        setNeedMounting(formData.mounting)
        if (!formRef.current) {
          return
        }
        const isEqual = JSON.stringify(formData) === JSON.stringify(formRef.current?.modal)
        if (isEqual) {
          return
        }
        formRef.current.modal = { ...formData }
      }
    }, [formRef, customers, visibleFormData]
  )

  const handleCancel = useCallback(() => {
    setIsOpenModal(false)
  }, [setIsOpenModal])

  const handleOk = useCallback(async (modalFormData) => {
    if (!formRef.current) {
      return
    }
    formRef.current.modalFieldsSchema = modalFormData
    formRef.current.modalValid = true
    setIsDisabled(true)
    await saveCase(true)
    setIsDisabled(false)
    setIsOpenModal(false)
  }, [setIsOpenModal, setIsDisabled, saveCase, formRef, getModalSchema])

  useEffect(() => {
    if (ordersFormAlert) {
      Modal.warning({
        title: 'Предупреждение',
        content: ordersFormAlert,
      })
    }
  },[ordersFormAlert])

  const steps = useMemo(() => {
    const stepsArr = Object.values(ordersFormData?.stages?.items || {}) as StepItem[]
    const current = ordersFormData?.stages?.current
    const lastStage = stepsArr[stepsArr.length - 1]
    if (current === 7 && withoutMontage && lastStage?.code === 'done') {
      stepsArr[6].icon = <div
        style={{
          height: 24,
          width: 24,
          borderRadius: '50%',
          backgroundColor: '#a9a9a9',
        }}
      ></div>
    }
    return stepsArr
  }, [ordersFormData, withoutMontage])

  return (
    <>
      <FormsHeader
        id={id}
        stage={ordersFormData?.stages?.current}
        nextStageOnClick={handleOnClick}
        isDisabled={isDisabled}
        toClaim={handleToClaim}
        withoutMontage={withoutMontage}
        setDone={handleSetDone}
      />
      <HStack gap={'8'} className={'mapContent'} max>
        <Form
          {...formItemLayout}
          layout='horizontal'
          form={form}
          scrollToFirstError={true}
          style={{ width: '100%' }}
        >
          {ordersFormError && !ordersFormIsLoading ? (
            <div className={'alertWrapper'}>
              <Alert
                message='При выполнении операции возникла ошибка:'
                showIcon
                type='error'
                description={ordersFormError}
              />
            </div>
          ) : null}
          {!ordersFormIsLoading ? (
            <VStack gap={'16'} max align={'stretch'} className={'ordersForm'}>
              {ordersFormData?.stages && (
                <HSteps
                  items={steps}
                  current={ordersFormData?.stages?.current || 0}
                  history={ordersFormData?.stages?.history}
                />)
              }
              {
                visibleFormData && <DefaultJsonForm
                  formData={visibleFormData.resultObject}
                  schema={visibleFormData.resultSchema}
                  onChange={onChangeForm}
                  orientation='horizontal'
                />
              }
            </VStack>
          ) : (
            <Skeleton active />
          )}
        </Form>
        {
          visibleFormData && !ordersFormIsLoading && visibleFormData?.modalName === 'measurement' && (
            <MeasurementModal
              id={id}
              isModalOpen={isOpenModal}
              onCancel={handleCancel}
              onOk={handleOk}
            />
          )
        }
        {
          visibleFormData && !ordersFormIsLoading && visibleFormData?.modalName === 'manufacture' && (
            <ManufactureModal
              id={id}
              isModalOpen={isOpenModal}
              onCancel={handleCancel}
              onOk={handleOk}
            />
          )
        }
        {
          visibleFormData && !ordersFormIsLoading && visibleFormData?.modalName === 'delivery' && (
            <DeliveryModal
              id={id}
              isModalOpen={isOpenModal}
              onCancel={handleCancel}
              onOk={handleOk}
            />
          )
        }
      </HStack>
    </>
  )
})
