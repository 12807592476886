import { Tooltip } from 'antd'
import { ToolTwoTone, DeleteTwoTone, ToolOutlined, DeleteOutlined, FolderTwoTone, FileTextTwoTone, FolderOpenTwoTone } from '@ant-design/icons'
import React from 'react'

import theme from '../../styles/theme'
import dayjs from '../../../lib/utils/configured-dayjs'
import SchemaCell from './schema-cell'
import { IAfterDataColumns, IBeforeDataColumns, IGroupsColumns } from './interface'
import { ActionButton, ActionsWrapper, EditGroupWrapper, SelectedFlexLabel, SelectedLabel } from './styles'
import { TitleWrapper } from '../../pages/list-admin-collections/styles'
import { DarkLink } from '../dark-link'

const getDate = date => dayjs(date).format('YYYY-MM-DD HH:mm:ss')

const getCreateGroupURL = () => '/api/collections/objects-groups/'
const getUpdateGroupURL = id => `/api/collections/objects-groups/${id}`

const iconColor = (recordId: number, selectedId: number | null) => {
  return recordId === selectedId ? theme.colors.white : theme.colors.blueIcon
}

const NameColumn = ({ id, name, selectedId }) => (
  <>
    <SelectedFlexLabel htmlFor={id}>
      <FileTextTwoTone twoToneColor={iconColor(id, selectedId)} />&nbsp;
    </SelectedFlexLabel>
    <SelectedLabel htmlFor={id} style={{ flex: '1 0 0' }}>{name}</SelectedLabel>
  </>
)

export const groupsColumns: IGroupsColumns = (props) => {
  const { sortCallback, handleGroupUpdate, groups, onAfterGroupCreate, expandedList, selectedId, isModalView } = props

  return [
    {
      title: 'Наименование',
      width: 280,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      sorter: sortCallback && sortCallback('name'),
      onCell: record => ({
        record,
        editable: record['isGroup'],
        dataIndex: 'name',
        title: record['isGroup'] ? 'Нажмите, чтобы отредактировать' : '',
        handleSave: handleGroupUpdate,
        groups,
        onAfterGroupCreate,
        getCreateGroupURL,
        getUpdateGroupURL,
      }),
      render: (text, { id, 'collection-name': collectionName, groupId, isGroup, name, key }) =>
        isGroup ? (
          <Tooltip title="Нажмите, чтобы отредактировать">
            <EditGroupWrapper>
                {expandedList && expandedList[key] ? (
                  <FolderOpenTwoTone twoToneColor={theme.colors.grayIcon}/>
                ) : (
                  <FolderTwoTone twoToneColor={theme.colors.grayIcon}/>
                )}
                {name}
            </EditGroupWrapper>
          </Tooltip>
        ) : (
          <TitleWrapper>
            {isModalView ? (
              <NameColumn id={id} name={name} selectedId={selectedId} />
            ) : (
              <DarkLink to={`/admin/collections/${collectionName}/objects/${id}/${groupId}/edit`} title="Отредактировать запись">
                <NameColumn id={id} name={name} selectedId={selectedId} />
              </DarkLink>
            )}
          </TitleWrapper>
        ),
    },
  ]
}

export const beforeDataColumns: IBeforeDataColumns = (onSort) => {
  return [
    {
      title: 'ID',
      width: 60,
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      sorter: onSort && onSort('id'),
      render: (name, { id, 'collection-name': collectionName, groupId }) => (
        <DarkLink to={`/admin/collections/${collectionName}/objects/${id}/${groupId}/edit`} title="Отредактировать запись">
          <SelectedLabel htmlFor={id} key={`schema-${id}`}>{name}</SelectedLabel>
        </DarkLink>
      )
    }
  ]
}

const DateColumn = ({ id, updatedAt }) => (
  <SelectedLabel htmlFor={id} key={`updated-at-${id}`}>
    <Tooltip placement="top" title={getDate(updatedAt)}>
      <span>{dayjs(updatedAt).fromNow()}</span>
    </Tooltip>
  </SelectedLabel>
)

export const afterDataColumns: IAfterDataColumns = (props) => {
  if (!props) {
    return []
  }

  const { onEdit, onDelete, selectedId, onSort, isModalView } = props

  return [
  {
    title: 'Схема',
    width: 180,
    dataIndex: 'schema',
    key: 'schema',
    fixed: 'left',
    render: (name, { id, isGroup }) => !isGroup && (
      <SelectedLabel htmlFor={id} key={`schema-${id}`}>{SchemaCell(name)}</SelectedLabel>
    )
  },
  {
    title: 'Дата обновления',
    dataIndex: 'updatedAt',
    key: 'updated-at',
    sorter: onSort && onSort('updated-at'),
    defaultSortOrder: 'descend',
    render: (text, { id, 'collection-name': collectionName, groupId, isGroup, updatedAt }) => !isGroup && (
      <>
        {isModalView ? (
          <SelectedLabel htmlFor={id} key={`updated-at-${id}`}>
            <DateColumn id={id} updatedAt={updatedAt} />
          </SelectedLabel>
        ) : (
          <DarkLink
            to={`/admin/collections/${collectionName}/objects/${id}/${groupId}/edit`}
            title="Отредактировать запись"
          >
            <DateColumn id={id} updatedAt={updatedAt} />
          </DarkLink>
        )}
      </>
    ),
    width: 100,
  }, {
    title: 'Действие',
    key: 'operation',
    fixed: 'right',
    width: 100,
    render: (text, { id, 'collection-name': collectionName, groupId, isGroup }) =>
      !isGroup && (
        <ActionsWrapper key={`operation-${id}`}>
          <Tooltip placement="top" title="Отредактировать запись">
            <ActionButton
              onClick={onEdit && onEdit({ id, collectionName, groupId })}
            >
              {id === selectedId ? (
                <ToolOutlined/>
              ) : (
                <ToolTwoTone/>
              )}
            </ActionButton>
          </Tooltip>
          <Tooltip placement="top" title="Удалить запись">
            <ActionButton
              onClick={onDelete(id)}
            >
              {id === selectedId ? (
                <DeleteOutlined/>
              ) : (
                <DeleteTwoTone/>
              )}
            </ActionButton>
          </Tooltip>
        </ActionsWrapper>
      ),
  },
]};
