import dayjs from 'dayjs'
import React, { useCallback } from 'react'
import {Popconfirm, Tag, Tooltip} from 'antd'
import { DataType } from './interface'
import { ButtonActionWrapper } from '../../../components/list-operations/styles'
import {DeleteTwoTone, QuestionCircleOutlined} from '@ant-design/icons'
import { Stage } from '../../../components/activity-timeline/styles'
import { Value } from '../../elements'
import {EditableColumnType} from "../../../components/data-table/interface"
import { PaperClipOutlined } from '@ant-design/icons'

const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`
  }
  return phoneNumber
}

const renderYesNoTag = (val: boolean | string | null) => {
  const cleanedVal = typeof val === 'string' ? val.trim() : val

  return (
    <Tag
      color={cleanedVal === true || cleanedVal === 'Да' ? '#87d068' : '#f50'}
      style={{
        border: '2px solid #fff',
        fontWeight: 'bold',
        padding: '0 8px',
      }}
    >
      {cleanedVal === true || cleanedVal === 'Да' ? 'Да' : 'Нет'}
    </Tag>
  )
}

const ColumnId: EditableColumnType<DataType>[] = [
  /*{
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 70,
    fixed: 'left'
  },*/
  {
    title: 'Стадия',
    dataIndex: 'stage',
    key: 'stage',
    width: 120,
    render: (stage: string, record: DataType) => {
      return <StageContainer stage={stage} hasFiles={record?.has_files} />
    }
  },
]

const ColumnsGeneral: EditableColumnType<DataType>[] = [
  {
    title: '№ ТЗ',
    dataIndex: 'order_reference',
    key: 'order_reference',
    width: 100,
    sorter: true,
    editable: true,
  },
  {
    title: 'Плановая дата доставки',
    dataIndex: 'delivery_date',
    key: 'delivery_date',
    width: 100,
    sorter: true,
    editable: true,
    render: val => val ? dayjs(val).format('DD.MM.YYYY') : <Value data={val}/>
  },
  {
    title: 'Склад',
    dataIndex: 'distribution_centre_name',
    key: 'distribution_centre_name',
    width: 70,
  },
  {
    title: 'Дилер',
    dataIndex: 'dealer',
    key: 'dealer',
    width: 130,
  },
  {
    title: 'Имя клиента',
    dataIndex: 'client_name',
    key: 'client_name',
    width: 130,
    sorter: true,
  },
  {
    title: 'Телефон клиента',
    dataIndex: 'client_phone',
    key: 'client_phone',
    width: 140,
    editable: true,
    render: val => formatPhoneNumber(val)
  },
  {
    title: 'Адрес доставки',
    dataIndex: 'customer_location_address',
    key: 'customer_location_address',
    width: 220,
    editable: true,
  },
  {
    title: 'Территория',
    dataIndex: 'zone',
    key: 'zone',
    width: 100,
    editable: true,
  },
  {
    title: 'Группа',
    dataIndex: 'zone_group',
    key: 'zone_group',
    width: 100,
  },
  {
    title: 'Интервал',
    dataIndex: 'drop_duration',
    key: 'drop_duration',
    width: 40,
    editable: true,
  },
  {
    title: 'Вес',
    dataIndex: 'capacity',
    key: 'capacity',
    width: 60,
    editable: true,
  },
  {
    title: 'Объем',
    dataIndex: 'volume',
    key: 'volume',
    width: 60,
    editable: true,
  },
  {
    title: (
      <Tooltip title="Количество изделий">
        <span>
          Кол-во <QuestionCircleOutlined style={{ marginLeft: 0 }} />
        </span>
      </Tooltip>
    ),
    dataIndex: 'cost',
    key: 'cost',
    width: 40,
    editable: true,
  },
  {
    title: 'Дополнительные инструкции к заказу',
    dataIndex: 'additional_instructions',
    key: 'additional_instructions',
    width: 150,
    editable: true,
    render: (text: string, record: DataType) => {
      const style: React.CSSProperties = record.highlight_comment
        ? { backgroundColor: '#fff', color: '#f00', fontWeight: 700, padding: '4px 8px' }
        : {}
      return <span style={style}>{text || ''}</span>
    }
  },
  {
    title: 'Примечание на конструкцию',
    dataIndex: 'note',
    key: 'note',
    width: 150,
    editable: true,
  },
  {
    title: 'Примечание по петлям',
    dataIndex: 'hinge_note',
    key: 'hinge_note',
    width: 150,
    editable: true,
  },
  {
    title: 'Наличие тяжелых СП',
    dataIndex: 'heavy_glass_presence',
    key: 'heavy_glass_presence',
    width: 150,
    editable: true,
  },
  {
    title: 'Время С',
    dataIndex: 'drop_window_start',
    key: 'drop_window_start',
    width: 56,
    editable: true,
    render: val => val ? dayjs(val).format('HH:mm') : <Value data={val}/>
  },
  {
    title: 'Время До',
    dataIndex: 'drop_window_end',
    key: 'drop_window_end',
    width: 56,
    editable: true,
    render: val => val ? dayjs(val).format('HH:mm') : <Value data={val}/>
  },
  {
    title: 'Приоритет',
    dataIndex: 'priority',
    key: 'priority',
    width: 50,
  },
  {
    title: (
      <Tooltip title="Очередность заказа в маршруте">
        <span>
          Номер <QuestionCircleOutlined style={{ marginLeft: 0 }} />
        </span>
      </Tooltip>
    ),
    dataIndex: 'sequence_in_run',
    key: 'sequence_in_run',
    width: 50,
    editable: true,
    //sorter: true,
  },
  {
    title: 'Пирамида',
    dataIndex: 'pyramid_number',
    key: 'pyramid_number',
    width: 110,
    editable: true,
    sorter: true,
    render: (val: string | null) => `${/^[0-9]+$/.test(val ?? '') ? 'Пирамида №' : ''}${val || ''}`,
  },
  {
    title: 'Машина',
    dataIndex: 'vehicle',
    key: 'vehicle',
    width: 130,
    editable: true,
    sorter: true,
  },
  {
    title: 'Дата отгрузки',
    dataIndex: 'old_delivery_date',
    key: 'old_delivery_date',
    width: 100,
    sorter: true,
    render: val => val ? dayjs(val).format('DD.MM.YYYY') : <Value data={val}/>
  },
  {
    title: 'Сумма доставки',
    dataIndex: 'delivery_amount',
    key: 'delivery_amount',
    width: 80,
  },
  {
    title: 'Оплата проверена',
    dataIndex: 'payment_verified',
    key: 'payment_verified',
    width: 85,
    editable: true,
    render: val => renderYesNoTag(val),
  },
  {
    title: 'Диспетчер',
    dataIndex: 'responsible_manager',
    key: 'responsible_manager',
    width: 90,
  },
  {
    title: (
      <Tooltip title="Сложность доставки">
        <span>
          Сложность <QuestionCircleOutlined style={{ marginLeft: 0 }} />
        </span>
      </Tooltip>
    ),
    dataIndex: 'delivery_complexity',
    key: 'delivery_complexity',
    width: 50,
  },
  {
    title: 'Проезд',
    dataIndex: 'passage',
    key: 'passage',
    width: 80,
  },
  {
    title: 'Телефон дилера',
    dataIndex: 'dealer_phone',
    key: 'dealer_phone',
    width: 140,
    render: val => formatPhoneNumber(val)
  },
  {
    title: 'Отметка о согласовании',
    dataIndex: 'approval_mark',
    key: 'approval_mark',
    width: 80,
    editable: true,
    render: val => renderYesNoTag(val),
  },
  {
    title: 'Тип',
    dataIndex: 'type',
    key: 'type',
    width: 75,
  },
  {
    title: 'Телефон менеджера',
    dataIndex: 'manager_phone',
    key: 'manager_phone',
    width: 140,
    render: val => formatPhoneNumber(val)
  },
  {
    title: 'ФИО менеджера',
    dataIndex: 'manager_name',
    key: 'manager_name',
    width: 85,
  },
  {
    title: 'Возможность проезда',
    dataIndex: 'passage_availability',
    key: 'passage_availability',
    width: 90,
  },
  {
    title: 'Комментарий',
    dataIndex: 'floor_elevator',
    key: 'floor_elevator',
    width: 150,
    editable: true,
    render: (val: string) => {
      const style: React.CSSProperties = val
        ? {
          backgroundColor: '#fff',
          color: '#000',
          padding: '4px 6px',
          fontWeight: 700,
          display: 'block',
          wordWrap: 'normal',
          wordBreak: 'break-word',
        }
        : {}
      return <span style={style}>{val || ''}</span>
    }
  },
  {
    title: 'Статус заказа в WD',
    dataIndex: 'order_status_in_wd',
    key: 'order_status_in_wd',
    width: 130,
  },
  {
    title: 'Что не готово? Когда?',
    dataIndex: 'what_is_not_ready_and_when',
    key: 'what_is_not_ready_and_when',
    width: 130,
    editable: true,
  },
  {
    title: 'Сложность',
    dataIndex: 'delivery_complexity',
    key: 'delivery_complexity',
    width: 85,
  },
  {
    title: 'Подъем',
    dataIndex: 'lifting',
    key: 'lifting',
    width: 80,
    editable: true,
    render: (val: string) => {
      const style: React.CSSProperties = val
        ? {
          backgroundColor: '#57ff26',
          color: '#000',
          padding: '4px 6px',
          fontWeight: 700,
          display: 'block',
          wordWrap: 'normal',
          wordBreak: 'normal'
        }
        : {}
      return <span style={style}>{val || ''}</span>
    }
  },
  {
    title: 'Превышение по допам',
    dataIndex: 'excess_for_additionals',
    key: 'excess_for_additionals',
    width: 95,
    editable: true,
    render: (val: string) => {
      const style: React.CSSProperties = val
        ? {
          backgroundColor: '#57ff26',
          color: '#000',
          padding: '4px 6px',
          fontWeight: 700,
          display: 'block',
          wordWrap: 'normal',
          wordBreak: 'normal'
        }
        : {}
      return <span style={style}>{val || ''}</span>
    }
  },
  {
    title: '№ ТЗ (оригинальный)',
    dataIndex: 'order_reference_original',
    key: 'order_reference_original',
    width: 100,
  },
  {
    title: 'Почта дилера',
    dataIndex: 'dealer_email',
    key: 'dealer_email',
    width: 100,
  },
  {
    title: 'Дата создания',
    width: 140,
    sorter: true,
    dataIndex: 'created_at',
    key: 'created_at',
    render: val => val ? dayjs(val).format('DD.MM.YYYY HH:mm') : <Value data={val}/>
  },
  {
    title: 'Дата обновления',
    width: 140,
    sorter: true,
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: val => val ? dayjs(val).format('DD.MM.YYYY HH:mm') : <Value data={val}/>
  }
]

const ColumnAction: EditableColumnType<DataType>[] = [
  {
    title: 'Действие',
    key: 'operation',
    dataIndex: 'operation',
    className: 'no-edit',
    fixed: 'right',
    width: 100,
    align: 'center',
    render: (_, {id, deleteRecord}) => {
      return (
        <span onClick={e => {
          e.stopPropagation()
        }}>
          <DeleteButton id={id} deleteRecord={deleteRecord}/>
        </span>
      )
    }
  }
]

export function DeleteButton({id, deleteRecord}) {
  const localDeleteRecord = useCallback(() => deleteRecord(id), [deleteRecord, id])
  return (
    <Popconfirm
      title="Вы уверены, что хотите удалить эту запись?"
      okText="Да"
      cancelText="Нет"
      onConfirm={() => localDeleteRecord()}
    >
      <ButtonActionWrapper title="Удалить">
        <DeleteTwoTone />
      </ButtonActionWrapper>
    </Popconfirm>
  )
}

export const getColumns = (leader, sorter) => {
  const columns = leader ? ColumnId.concat(ColumnsGeneral/*, ColumnAction*/) : ColumnId.concat(ColumnsGeneral)
  return columns.map(item => {

    if (item.key === sorter.sort_field)
      item.sortOrder = sorter.sort_order
    else
      delete item.sortOrder
    return item
  })
}


export function StageContainer({ stage, hasFiles }) {
  if (stage) {
    const showAttachment = stage.code === 'done' && hasFiles;

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <Stage
          style={{
            ...stage?.style,
            display: 'block',
            overflow: 'unset',
            padding: '0 8px'
          }}
          wrap
        >
          {stage.name}
          {showAttachment && (
            <PaperClipOutlined style={{ fontSize: 12, color: '#fff', marginLeft: 2 }} />
          )}
        </Stage>
      </div>
    );
  }
  return <>...</>;
}
