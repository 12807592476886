import React from 'react'
import { Button, Row, Col } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import moment from 'moment'
import InputDate from "../../../../components/input-date";

interface DateFilterProps {
  selectedDate: string
  onChange: (newDate: string) => void
}

const DateFilter: React.FC<DateFilterProps> = ({ selectedDate, onChange }) => {
  const handleDateChange = (date) => {
    onChange(date ? moment(date).format('YYYY.MM.DD') : '')
  }

  const goToPreviousDay = () => {
    const prevDate = moment(selectedDate).subtract(1, 'day').format('YYYY.MM.DD')
    onChange(prevDate)
  }

  const goToNextDay = () => {
    const nextDate = moment(selectedDate).add(1, 'day').format('YYYY.MM.DD')
    onChange(nextDate)
  }

  return (
    <Row align="middle">
      <Col>
        <Button
          icon={<LeftOutlined />}
          onClick={goToPreviousDay}
          disabled={!selectedDate}
          style={{
            marginRight: '2px',
            width: '31px',
            height: '31px',
          }}
        />
      </Col>
      <Col>
        <InputDate
          UISchema={{
            type: 'string',
            format: 'date',
            options: { isDisabledDatePrev: false },
          }}
          value={selectedDate}
          onChange={handleDateChange}
        />
      </Col>
      <Col>
        <Button
          icon={<RightOutlined />}
          onClick={goToNextDay}
          disabled={!selectedDate}
          style={{
            marginLeft: '2px',
            width: '31px',
            height: '31px',
          }}
        />
      </Col>
    </Row>
  )
}

export default DateFilter
