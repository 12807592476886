import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Button, Space, Table } from 'antd'
import { useHistory } from 'react-router-dom'
import fetchAPI from '../../../../lib/utils/fetch-api'
import B2BLayout from '../../../layouts/b2b'
import FiltersDrawer from '../../../components/v2/filters-drawer'
import { TablePaginationPosition } from '../../deliveries/list/interface'
import { objectToUrl } from '../../utils'
import { currentFiltersProps, IData, IEnumsList, ISorterProps } from './interfaces'
import { filtersList } from './filters'
import { columns } from './columns'
import { HStack } from '../../../ui/Stack'
import { PlusOutlined } from '@ant-design/icons'

const ClaimsRequestsList = () => {
  const [data, setData] = useState<IData[]>([])
  const [loading, setLoading] = useState(false)
  const [sorter, setSorter] = useState<ISorterProps>({})
  const history = useHistory()
  const [availableFilters, setAvailableFilters] = useState<IEnumsList>({})
  const [urlParams] = useState(Object.fromEntries(new URLSearchParams(location.search)))
  const [bottomCenter] = useState<TablePaginationPosition>('bottomCenter')
  const [pagination, setPagination] = useState({
    current: Number(urlParams?.page) || 1,
    pageSize: 10,
    position: [bottomCenter],
    showSizeChanger: true,
    total: 0,
  })
  const [currentFilters, setCurrentFilters] = useState<currentFiltersProps>({})

  const getData = useCallback(() => {
    try {
      setLoading(true)
      const urlParamsObj = Object.fromEntries(new URLSearchParams(location.search))
      fetchAPI(`/api/list/claims_request?${objectToUrl(urlParamsObj)}`)
        .then(({ data, pagination, sorter, enums }) => {
          if (data) {
            setData(data)
          }
          if (pagination) {
            setPagination(prevState => ({
              ...prevState,
              ...pagination,
            }))
          }
          if (sorter) {
            setSorter(sorter)
          }
          if (enums) {
            setAvailableFilters(enums)
          }
          setLoading(false)
        })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [location.search])

  useEffect(() => {
    getData()
  }, [])

  const breadcrumbs = [
    {
      href: '/sales',
      title: 'Продажи',
    },
    {
      href: '/claims-requests',
      title: 'Заявки на рекламацию',
    }
  ]

  const parseUrlSearchParams = (search: string): currentFiltersProps => {
    const urlParams = new URLSearchParams(search)
    return {
      date_application: urlParams.get('date_application') || undefined,
      stage: urlParams.get('stage') || undefined,
      dealer_code: urlParams.get('dealer_code') || undefined,
      address: urlParams.get('address') || undefined,
      name: urlParams.get('name') || undefined,
      phone: urlParams.get('phone') || undefined,
      classification_id: urlParams.get('classification_id') || undefined,
      dealer_requirement_id: urlParams.get('dealer_requirement_id') || undefined,
      num_tech: urlParams.get('num_tech') || undefined,
    }
  }

  const changeFiltersHandler = useCallback(async (filters: any) => {
    let tmpFilter = { ...(filters || {}) }
    if (tmpFilter?.stage?.length === 0) {
      delete tmpFilter.stage
    }
    if (tmpFilter?.classification_id?.length === 0) {
      delete tmpFilter.classification_id
    }
    if (tmpFilter?.dealer_requirement_id?.length === 0) {
      delete tmpFilter.dealer_requirement_id
    }
    if (pagination?.pageSize !== 10) {
      tmpFilter.pageSize = pagination?.pageSize
    }
    const newUrlParams = new URLSearchParams(tmpFilter).toString()
    {
      history.push({ search: newUrlParams })
    }
    getData()
  }, [pagination.pageSize, getData, history])

  useEffect(() => {
    const newFilters = parseUrlSearchParams(location.search)
    setCurrentFilters(newFilters)
  }, [location.search])

  const handleTableChange = (pagination, _filters, sorter) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    const current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
      page: pagination.current,
      pageSize: pagination.pageSize,
    }

    if (parseInt(current?.pageSize) === 10) {
      delete current.pageSize
    }
    if (current?.page === 1) {
      delete current.page
    }
    if (current?.sort_order === undefined) {
      delete current.sort_order
      delete current.sort_field
    }
    const urlParams = new URLSearchParams(current).toString()
    history.push({ search: urlParams })
    getData()
  }

  const getFilters = useMemo(() => {
    return filtersList(currentFilters, availableFilters)
  }, [currentFilters, availableFilters])

  const getColumns = useMemo(() => {
    return columns(sorter)
  }, [sorter])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <HStack>
        <Button onClick={() => {history.push('/claim-request/new')}}>
          <PlusOutlined /> Создать завяку
        </Button>
        <Space style={{ marginBottom: '20px', width: '100%', justifyContent: 'end' }} align={'end'}>
          <FiltersDrawer
            filters={getFilters}
            onChange={changeFiltersHandler}
            currentFilters={currentFilters}
          />
        </Space>
      </HStack>
      <Table
        columns={getColumns}
        dataSource={data}
        loading={loading}
        rowKey='id'
        onRow={record => ({
          onClick: () => {
            history.push(`/claim-request/${record['id']}`)
          },
        })}
        pagination={pagination}
        onChange={handleTableChange}
        scroll={{ x: 'max-content', y: '73vh' }}
        size={'small'}
      />
    </B2BLayout>
  )
}

export default ClaimsRequestsList
