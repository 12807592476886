import dayjs from 'dayjs'
import React from 'react'
import { DataType } from './interface'
import { Value } from '../../elements'
import {EditableColumnType} from "../../../components/data-table/interface"

const ColumnId: EditableColumnType<DataType>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 50,
    fixed: 'left',
    render: (val: string) => {
      const style: React.CSSProperties = val
        ? {
          textAlign: 'center',
          fontWeight: 'bold'
        }
        : {}
      return <div style={style}>{val || ''}</div>
    }
  },
]

const ColumnsGeneral: EditableColumnType<DataType>[] = [
  {
    title: 'Дата поездки',
    dataIndex: 'date',
    key: 'date',
    width: 100,
    render: val => val ? dayjs(val).format('DD.MM.YYYY') : <Value data={val}/>
  },
  {
    title: 'Машина',
    dataIndex: 'vehicle',
    key: 'vehicle',
    width: 130,
  },
  {
    title: 'Водитель',
    dataIndex: 'driver_name',
    key: 'driver_name',
    width: 130,
  },
  {
    title: 'Расстояние',
    dataIndex: 'distance',
    key: 'distance',
    width: 130,
  },
  {
    title: 'Кол-во изделий',
    dataIndex: 'total_items_count',
    key: 'total_items_count',
    width: 130,
  },
  {
    title: 'Вес',
    dataIndex: 'total_weight',
    key: 'total_weight',
    width: 130,
    render: val => val ? val + ' кг' : ''
  },
  {
    title: 'Объем',
    dataIndex: 'total_volume',
    key: 'total_volume',
    width: 70,
    render: val => val ? val + ' м³' : ''

  },
  {
    title: 'Сумма доставки',
    dataIndex: 'total_delivery_amount',
    key: 'total_delivery_amount',
    width: 70,
    render: val => val ? val + ' руб.' : ''
  },
  {
    title: 'Загрузка',
    dataIndex: 'loading_time',
    key: 'loading_time',
    width: 70,
  },
  {
    title: 'Кол-во заказов',
    dataIndex: 'orders_count',
    key: 'orders_count',
    width: 70,
  },
  {
    title: 'Кол-во адресов',
    dataIndex: 'unique_addresses_count',
    key: 'unique_addresses_count',
    width: 70,
  },
  {
    title: 'Склад',
    dataIndex: 'center_name',
    key: 'center_name',
    width: 70,
  },
]

export const getColumns = (leader, sorter) => {
  const columns = leader ? ColumnId.concat(ColumnsGeneral/*, ColumnAction*/) : ColumnId.concat(ColumnsGeneral)
  return columns.map(item => {

    if (item.key === sorter.sort_field)
      item.sortOrder = sorter.sort_order
    else
      delete item.sortOrder
    return item
  })
}