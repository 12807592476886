import React, { memo, Suspense, useCallback, useState } from 'react'
import { Button, message, Modal } from 'antd'
import { CustomersSearchForm } from './customers-search-form'
import { ResultTable } from './result-table'
import fetchAPI from '../../../../../../lib/utils/fetch-api'
import { BoxClient } from '../../../../appeals/card/ui/components/marge-modal/box-client'

interface MargeModalProps {
  appealId?: string | number
  customerId?: string | number
  disabled: boolean
  warningContent?: string | JSX.Element
}

interface ICustomer {
  id: number
  name: string
  details: string
}

export const MargeModal: React.FC<MargeModalProps> = memo((props: MargeModalProps) => {
  const { appealId, customerId, disabled, warningContent } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState<ICustomer[]|undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openClientId, setOpenClientId] = useState<number | null>(null)

  const handleOnClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleOnSearch = useCallback(async data => {
    setSearchResults(undefined)
    setIsLoading(true)
    try {
      const response = await fetchAPI('/api/customers/search', {
        method: 'POST',
        body: JSON.stringify(data),
      })

      if (response && response?.data) {
        if (response?.data?.length) {}
        setSearchResults(response.data)
      } else {
        message.error('Не удалось выполнить поиск. Пожалуйста, попробуйте еще раз.')
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error)
      message.error('Произошла ошибка при выполнении запроса.')
    } finally {
      setIsLoading(false)
    }
  }, [])


  const handleOpenClientBox = (id: number) => {
    setOpenClientId(id)
  }

  const handleCloseClientBox = () => {
    setOpenClientId(null)
  }

  return(
    <Suspense fallback={''}>
      <Button
        onClick={() => setIsOpen(true)}
        disabled={disabled}
      >
        Объединить клиентов
      </Button>
      { isOpen &&
        <Modal
          title='Поиск клиентов для объединения'
          visible={isOpen}
          onCancel={handleOnClose}
          width={700}
        >
          <CustomersSearchForm
            loading={isLoading}
            onSearch={handleOnSearch}
          />
          {searchResults && (
            <ResultTable
              data={searchResults}
              loading={isLoading}
              appealId={appealId}
              customerId={customerId}
              warningContent={warningContent}
              onSelect={id => handleOpenClientBox(id)}
            />
          )}
        </Modal>
      }
      {openClientId && (
        <BoxClient
          isOpen={true}
          setOpen={handleCloseClientBox}
          id={openClientId}
          appealId={appealId}
          customerId={customerId}
          warningContent={warningContent}
          onComplete={() => {
            setOpenClientId(null)
            setSearchResults(undefined)
            setIsOpen(false)
            message.success('Контакты успешно объединены')
          }}
        />
      )}
    </Suspense>
  )
})
