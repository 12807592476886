import { ITaskCalendarFilter } from './interfaces'
import {
  setCalendarData,
  setCalendarDataLoading,
  setCalendarFailed,
  setCalendarOptions,
  setCalendarCaseTypes,
  setCalendarCaseTypesFailed,
} from './actions'
import fetchAPI from '../../../lib/utils/fetch-api'

export function getTasksListToCalendar(filter?: ITaskCalendarFilter) {
  return async dispatch => {
    dispatch(setCalendarDataLoading())
    try {
      const params = new URLSearchParams(filter as URLSearchParams)
      params.set('pageSize', 'all')
      const response = await fetchAPI(`/api/v2/tasks?${params.toString()}`) || []

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setCalendarFailed(error))
      }

      dispatch(setCalendarOptions(response?.options || []))
      return dispatch(setCalendarData(response.data))
    } catch (err) {
      return dispatch(setCalendarFailed(err as string))
    }
  }
}

export function getTaskCaseEnum() {
  return async dispatch => {
    try {
      const response = await fetchAPI(`/api/v2/task/case/enum`)

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setCalendarCaseTypesFailed(error))
      }

      return dispatch(setCalendarCaseTypes(response || []))
    } catch (err) {
      return dispatch(setCalendarCaseTypesFailed('ошибка загрузки типов задач: ' + err))
    }
  }
}