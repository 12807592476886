import React, { useEffect, useMemo, memo } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import B2BLayout from '../../../layouts/b2b'
import { getDataLoading, getCardError } from './selectors'
import {
  DefaultJsonFormContextProps
} from '../../../components/v2/default-json-form/context/default-json-form-context-props'
import { getClaimRequestData } from '../../../store/claim_request/action-creators'
import { HStack } from '../../../ui/Stack'
import DefaultJsonFormContext from '../../../components/v2/default-json-form/context/default-json-form-context'
import { DataForm } from './ui/form/form'
import { CardTabs } from './ui/tabs/tabs'
import { clearClaimRequestData } from '../../../store/claim_request/actions'

const breadcrumbs = [
  {
    href: '/sales',
    title: 'Продажи',
  },
  {
    href: '/claims-requests',
    title: 'Заявки на рекламацию',
  }
]

function ClaimRequestPage() {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const entityType = 'claim_request'
  const dataIsLoading = useSelector(getDataLoading)
  const cardError = useSelector(getCardError)

  const defaultProps = useMemo(
    (): DefaultJsonFormContextProps => ({
      entityId: id,
      entityType: entityType,
    }), [id])

  useEffect(() => {
    let mounted = true
    if (mounted && id) {
      const getInitPage = async id => {
        dispatch(getClaimRequestData(id))
      }
      getInitPage(id).catch(console.error)
    }
    return () => {
      dispatch(clearClaimRequestData())
      mounted = false
    }
  }, [id, dispatch])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      {
        (cardError && !dataIsLoading) ? (
          <HStack gap={'8'} className={'mapWrapper'}>
            При выполнении запроса возникла ошибка.
            <br/>
            {cardError}
            <br/>
            Попробуйте перезагрузить страницу или открыть обращение повторно.
          </HStack>
        ) : (
          <DefaultJsonFormContext.Provider value={defaultProps}>
            <DataForm/>
            <CardTabs/>
          </DefaultJsonFormContext.Provider>
        )
      }
  </B2BLayout>
  )
}

export default memo(ClaimRequestPage)
