import {isDateInRange} from "./isDateInRange";

const validStages = ['Договор','Производство', 'Доставка','Монтаж', 'Выполнено']
export const isValidOrderByManager = (order, selectedMonths: string[], selectedYears: string[]): boolean => {
    return (validStages.includes(order["stage-code"])
        && order["contract-sum"]
        // 02.04.25 Возможно нужно будет вернуть и добавить в isValidPrepayment
        // && order["production-order-number"]
        && order["prepayment_date"]
        && isDateInRange(order["prepayment_date"], selectedMonths, selectedYears)
    )
}