import React, { memo, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectStagePlanData,
  selectStagePlanError,
  selectStagePlanIsLoading,
  StagePlan as ClaimStagePlan
} from '../../../../../components/v2/stage-plan'
import { getStagePlanData } from '../../../../../store/stagePlan/action-creators'
import { setStagePlanClear } from '../../../../../store/stagePlan/actions'
import { getCardCurrentStage } from '../../selectors'

interface StagePlanProps {
  className?: string
  id: string | number
}

export const StagePlan: React.FC<StagePlanProps> = memo((props: StagePlanProps) => {
  const { className, id } = props
  const dispatch = useDispatch()
  const stagePlanIsLoading = useSelector(selectStagePlanIsLoading)
  const stagePlanError = useSelector(selectStagePlanError)
  const stagePlanFiles = useSelector(selectStagePlanData)
  const stage = useSelector(getCardCurrentStage)

  useEffect(() => {
    let mounted = true
    if (stage !== undefined) {
      if (mounted) {
        dispatch(getStagePlanData('claim_request', id))
      }
    }
    return () => {
      mounted = false
      dispatch(setStagePlanClear())
    }
  }, [stage, id, dispatch])

  return (
    <Suspense fallback={''}>
      <ClaimStagePlan
        isLoading={stagePlanIsLoading}
        items={stagePlanFiles}
        error={stagePlanError}
        className={className}
        title={'План исполнения рекламации'}
        emptyMessage={'Прогноз исполнения пуст'}
        typeItem={'claim_request'}
      />
    </Suspense>
  )
})
