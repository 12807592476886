import {
  CLAIM_REQUEST_FORM_LOADING,
  CLAIM_REQUEST_FORM_FAILED,
  CLAIM_REQUEST_DATA,
  CLAIM_REQUEST_ENUMS_LOADING,
  CLAIM_REQUEST_ENUMS_FAILED,
  CLAIM_REQUEST_ENUMS_DATA,
  CLAIM_REQUEST_TIMELINE_LOADING,
  CLAIM_REQUEST_TIMELINE_DATA,
  CLAIM_REQUEST_TIMELINE_FAILED,
  CLAIM_REQUEST_TIMELINE_CLEAR,
  CLAIM_REQUEST_TIMELINE_ADD_ITEM,
  CLAIM_REQUEST_TIMELINE_UPDATE_ITEM,
  CLAIM_REQUEST_TIMELINE_DELETE_ITEM,
  CLEAR_CLAIM_REQUEST_DATA,
  CLAIM_REQUEST_FORM_ID_UPDATE,
} from './actions'
import { IClaimRequestState } from './interfaces'
import { ActivityItems, ActivityUpdateItem } from '../../components/v2/activity-timeline'
import { setTimelineItem } from '../../components/v2/activity-timeline/services/setTimelineItem'
import { updateTimelineItemByIndex } from '../../components/v2/activity-timeline/services/updateTimelineItemByIndex'
import { deleteTimelineItem } from '../../components/v2/activity-timeline/services/deleteTimelineItem'
import { updateTimelineItemByItemId } from '../../components/v2/activity-timeline/services/updateTimelineItemByItemId'

const initialState: IClaimRequestState = {
  form: {
    id: undefined,
    current_stage: undefined,
    data: null,
    redirectUrl: undefined,
    isLoading: false,
    error: '',
  },
  stages: {
    items: undefined,
    current: undefined,
    history: undefined,
  },
  timeline: {
    isLoading: false,
    error: '',
    data: {
      items: [],
      itemsDates: [],
      total: undefined,
      offset: undefined,
      page: undefined,
      limit: undefined,
    }
  },
}

export const claimRequestReducer = (
  state = initialState,
  {type, payload},
) => {
  switch (type) {
    case CLEAR_CLAIM_REQUEST_DATA:
      return initialState
    case CLAIM_REQUEST_FORM_LOADING:
      return {
        ...state,
        id: null,
        form: {
          ...state['form'],
          isLoading: true,
          data: null,
          error: ''
        }
      }
    case CLAIM_REQUEST_DATA:
      return {
         ...state,
        stages: payload.stages,
        form: {
          ...state['form'],
          isLoading: false,
          data: payload.data,
          id: payload.id,
          redirectUrl: payload?.redirectUrl,
          error: '',
        }
      }
    case CLAIM_REQUEST_FORM_FAILED:
      return {
        ...state,
        form: {
          ...state['form'],
          isLoading: false,
          error: payload
        }
      }
    case CLAIM_REQUEST_FORM_ID_UPDATE:
      return {
        ...state,
        form: {
          ...state['form'],
          id: payload
        },
      }
    case CLAIM_REQUEST_ENUMS_LOADING:
      return {
        ...state,
        enums: {
          ...state['enums'],
          isLoading: true,
          data: null,
          error: ''
        }
      }
    case CLAIM_REQUEST_ENUMS_DATA:
      return {
        ...state,
        enums: {
          ...state['enums'],
          isLoading: false,
          data: payload,
          error: ''
        }
      }
    case CLAIM_REQUEST_ENUMS_FAILED:
      return {
        ...state,
        enums: {
          ...state['enums'],
          isLoading: false,
          error: payload
        }
      }
    case CLAIM_REQUEST_TIMELINE_LOADING:
      return {
        ...state,
        timeline: {
          isLoading: true,
          error: '',
          data: {
            items: [],
            itemsDates: [],
            total: undefined,
            offset: undefined,
            page: undefined,
            limit: undefined,
          }
        },
      }
    case CLAIM_REQUEST_TIMELINE_DATA:
      return {
        ...state,
        timeline: {
          isLoading: false,
          error: '',
          data: payload
        },
      }
    case CLAIM_REQUEST_TIMELINE_FAILED:
      return {
        ...state,
        timeline: {
          ...state['timeline'],
          isLoading: false,
          error: payload,
          data: {
            items: [],
            itemsDates: [],
            total: undefined,
            offset: undefined,
            page: undefined,
            limit: undefined,
          }
        },
      }
    case CLAIM_REQUEST_TIMELINE_CLEAR:
      return {
        ...state,
        timeline: {
          isLoading: false,
          error: '',
          data: {
            items: [],
            itemsDates: [],
            total: undefined,
            offset: undefined,
            page: undefined,
            limit: undefined,
          }
        },
      }
    case CLAIM_REQUEST_TIMELINE_ADD_ITEM: {
      const newActivityItems = payload as ActivityItems[]
      let timelineItems: ActivityItems[] = state.timeline.data?.items || []
      let timelineDaysItems = state.timeline.data?.itemsDates || []

      if (!newActivityItems.length) {
        return state
      }

      const {
        timelineItems: timelineItemsMod,
        timelineDates: timelineDaysItemsMod
      } = setTimelineItem(newActivityItems, timelineItems, timelineDaysItems)

      return {
        ...state,
        timeline: {
          ...state.timeline,
          data: {
            ...state.timeline.data,
            items: [...timelineItemsMod],
            itemsDates: [...timelineDaysItemsMod]
          }
        },
      }
    }
  case CLAIM_REQUEST_TIMELINE_UPDATE_ITEM: {
    const newDataItem = payload as ActivityUpdateItem
    let timelineItems: ActivityItems[] = state.timeline.data?.items || []
    let timelineDaysItems = state.timeline.data?.itemsDates || []
    const timelineItemsMod = newDataItem?.index || newDataItem?.index === 0
      ? updateTimelineItemByIndex(newDataItem, timelineItems, timelineDaysItems)
      : updateTimelineItemByItemId(newDataItem, timelineItems, timelineDaysItems)

    return {
      ...state,
      timeline: {
      ...state.timeline,
        data: {
          ...state.timeline.data,
          items: [...timelineItemsMod],
        }
      },
    }
  }
  case CLAIM_REQUEST_TIMELINE_DELETE_ITEM: {
    const deletedItem = payload as ActivityUpdateItem
    let timelineItems: ActivityItems[] = state.timeline.data?.items || []
    let timelineDaysItems = state.timeline.data?.itemsDates || []

    const {
      timelineItems: timelineItemsMod,
      timelineDates: timelineDaysItemsMod
    } = deleteTimelineItem(deletedItem, timelineItems, timelineDaysItems)

    return {
      ...state,
      timeline: {
        ...state.timeline,
        data: {
          ...state.timeline.data,
          items: [...timelineItemsMod],
          itemsDates: [...timelineDaysItemsMod]
        }
      },
    }
  }
  default:
    return state
  }
}
