import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button, Input  } from 'antd'

export const TimeLineContentWrapper = styled.div`
  box-shadow: 0 2px 4px 0 #cacaca80;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: ${props => props.color ? props.color: '#fdfdfd'};
  display: flex;
  margin-right: 3px;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`
export const TimeLineContentHeaderWrapper = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
`
export const TimeLineContentHeaderSpaceWrapper = styled.div`
  box-shadow: 0 2px 4px 0 #cacaca80;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  margin-right: 3px;
  position: relative;
  flex-direction: column;
  font-weight: bold;
  font-size: 12px;
  color: ${props => props.slot ? props.slot: '#6e7273'};
  background-color: ${props => props.color};
`
export const DotStyle = styled.div`
  font-size: 16px;
  padding: 7px;
  border-radius: 50%;
  background-color: darkgray;
  color: #fff;
  background-color: ${props => props.color};
`
export const AvitoStyle = styled.div`
    padding: 2px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #2fc4f5;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const ShowWrapper = styled.button`
  border-radius: 50%;
  padding: 10px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f0f0f0;
  svg {
    transform: rotate(${props => props.color}deg);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`
export const LinkButton = styled(Link)`
  display: inline-block;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  white-space: nowrap;
  transition: color 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    color: #40a9ff;
  }
`

export const NotLinkButton = styled.span`
  display: inline-block;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  white-space: nowrap;
  transition: color 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    color: #40a9ff;
  }
`

export const Avatar = styled.img`
  width: 21px;
  height: 21px;
  border-radius: 50%;
`

export interface IStyledPropsStage {
  color?: string;
  fontWeight?: string;
  fontColor?: string;
  bgColor?: string;
  wrap?: boolean;
}

export const CountBall = styled.span<IStyledPropsStage>`
    display: inline-block;
    border-radius: 50%;
    border: 0;
    background-color: #f00;
    color: #fff;
    text-align: center;
    height: 22px;
    width: 22px;
    margin-left: 10px;
    position: absolute;
    right: 0;
`

export const CountBallInline = styled.span<IStyledPropsStage>`
    display: inline-block;
    border-radius: 50%;
    border: 0;
    background-color: #f00;
    color: #fff;
    text-align: center;
    height: 22px;
    width: 22px;
    margin-left: 10px;
`

export const CountBallAbsoluteRight = styled.span<IStyledPropsStage>`
    display: inline-block;
    border-radius: 50%;
    border: 0;
    background-color: #f00;
    color: #fff;
    text-align: center;
    height: 22px;
    width: 22px;
    margin-left: 10px;
    position: absolute;
    right: 0;
`

export const Stage = styled.span<IStyledPropsStage>`
    display: inline-block;
    overflow: hidden;
    padding: 0 12px;
    border-radius: 9px;
    background-color: ${props => props.color ? props.color: '#e8f1fb'};
    color: ${props => props.fontColor ? props.fontColor: '#333'};
    font-weight: ${props => props.fontWeight ? props.fontWeight: 'unset'};
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: ${props => props.wrap ? 'wrap' : 'nowrap'};
    margin-bottom: 4px;
`

export const NoteEditButton = styled(Button)`
  background-color: unset !important;
  font-size: 14px;
  padding: 0;
  height: min-content;
  border: none;
  &:hover {
    color: #40a9ff;
  }
`

export const NoteTextArea = styled(Input.TextArea)`
  width: 100%;
  height: 100%;
`

export const NoteSaveButton = styled(Button)`
  background-color: #97c807;
  margin-top: 10px;
  margin-bottom: 5px;
  width: min-content;
  color: #fff;
  &:hover {
    color: #fff;
    background-color: #97c807;
  }
`

export const TaskButtonCloseWrapper = styled.div`
    height: min-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    button {
      width: max-content;
    }
`