import { createSelector } from 'reselect'


const selectStagePlan = state => state.stagePlan

export const selectStagePlanData = createSelector(
    selectStagePlan,
    ({ items }) => items
)

export const selectStagePlanIsLoading = createSelector(
    selectStagePlan,
    ({ isLoading }) => isLoading
)

export const selectStagePlanError = createSelector(
    selectStagePlan,
    ({ error }) => error
)
