import {
  ORDERS_FORM_LOADING,
  ORDERS_FORM_DATA,
  ORDERS_FORM_FAILED,
  ORDERS_ENUMS_LOADING,
  ORDERS_ENUMS_DATA,
  ORDERS_ENUMS_FAILED,
  ORDERS_FORM_ID_UPDATE,
  ORDERS_FORM_ALERT,
  CLEAR_ORDERS_FORM_DATA,
} from './actions'
import {IOrdersState} from './interfaces'

const initialState: IOrdersState = {
  form: {
    data: null,
    isLoading: false,
    error: '',
    alert: null,
  },
  enums: {
    data: null,
    isLoading: false,
    error: '',
  },
  redirectUrl: undefined,
}

export const order = (
    state = initialState,
    {type, payload},
) => {
  switch (type) {
    case ORDERS_FORM_LOADING:
      return {
        ...state,
        id: null,
        form: {
          ...state['form'],
          isLoading: true,
          data: null,
          error: ''
        }
      }
    case ORDERS_FORM_DATA:
      return {
        ...state,
        form: {
          ...state['form'],
          isLoading: false,
          data: payload,
          error: ''
        }
      }
    case ORDERS_FORM_FAILED:
      return {
        ...state,
        form: {
          ...state['form'],
          isLoading: false,
          error: payload
        }
      }
    case ORDERS_FORM_ALERT:
      return {
        ...state,
        form: {
          ...state['form'],
          isLoading: false,
          alert: payload
        }
      }
    case ORDERS_FORM_ID_UPDATE:
      return {
        ...state,
        id: payload,
      }
    case ORDERS_ENUMS_LOADING:
      return {
        ...state,
        enums: {
          ...state['enums'],
          isLoading: true,
          data: null,
          error: ''
        }
      }
    case ORDERS_ENUMS_DATA:
      return {
        ...state,
        enums: {
          ...state['enums'],
          isLoading: false,
          data: payload,
          error: ''
        }
      }
    case ORDERS_ENUMS_FAILED:
      return {
        ...state,
        enums: {
          ...state['enums'],
          isLoading: false,
          error: payload
        }
      }
    case CLEAR_ORDERS_FORM_DATA:
      return initialState
    default:
      return state
  }
}
