import React from 'react'
import B2BLayout from '../../layouts/b2b'
import { ReportIframe } from './components/report'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { State } from '../../interfaces/index'

const createSession = createSelector(
  (state: State) => state.session,
  sessionData => sessionData,
)

export const PowerbiReport = () => {
  const breadcrumbs = [
    {
      href: '/admin',
      title: 'Админка',
    },
    {
      href: '/powerbi-report',
      title: 'Отчёт powerbi',
    }]
  const { sessionData } = useSelector(createSession)
  const hasAccess = sessionData &&  sessionData?.roles.includes(16)
  
  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      {hasAccess ? <ReportIframe /> : <div>У Вас нет доступа к данному разделу</div>}
    </B2BLayout>
  )
}
