import fetchAPI from '../../../lib/utils/fetch-api'
import {
  setTimelineLoading,
  setTimelineFailed,
  setTimelineData,
} from './actions'
import { EntityType } from '../../shared/types/EntityType'

export function getTimeLineData(entityType: EntityType, entityId: string | number) {
  return async dispatch => {
    dispatch(setTimelineLoading())
    try {
      const response = await fetchAPI(`/api/get-activity/${entityType}/${entityId}`) || []

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setTimelineFailed(error))
      }

      if (response['items']) {
        response['itemsDates'] = response['items']?.map(itemsByDays => itemsByDays.date)
      }

      return dispatch(setTimelineData(response))
    } catch (err) {
      return dispatch(setTimelineFailed(err as string))
    }
  }
}



