import React, { useMemo, useState } from 'react'
import { Colorpicker } from 'antd-colorpicker'
import { Button } from 'antd'
import { IInputColorPicker } from './interface'

function InputColorPicker({ value, onChange, schema, UISchema }: IInputColorPicker) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const style = UISchema?.style
  const isEmptyValue = !value || value === 'null'
  const [isEditing, setIsEditing] = useState(false)

  const handleChange = (color: { hex: string }) => {
    onChange?.(color.hex)
    setIsEditing(false)
  }

  const handleButtonClick = () => {
    if (!disabled) {
      setIsEditing(true)
    }
  }

  if (isEmptyValue && !isEditing) {
    return (
      <Button
        disabled={disabled}
        style={style}
        onClick={handleButtonClick}
      >
        Выбрать цвет
      </Button>
    )
  }

  return (
    <Colorpicker
      value={value && value !== 'null' ? { hex: value } : undefined}
      onChange={handleChange}
      disabled={disabled}
      style={style}
    />
  )
}

export default InputColorPicker