export enum UserRoles {
  ADMIN = 1,
  PROGRAMMER = 2,
  SALES_MANAGER = 3,
  SERVICE_DEPARTMENT_MANAGER = 4,
  LOGISTICS_DEPARTMENT_MANAGER = 5,
  MARKETER = 6,
  MEASUREMENT_MANAGER = 7,
  PRODUCTION_MANAGER = 8,
  HEAD_OFFICE_SALES_MANAGER = 9,
  DISPATCHER = 10,
  CREW = 11,
  INSTALLATION_FOREMAN = 12,
  INSTALLER = 13,
  AUDITOR = 14,
  HEAD_OFFICE_ACCOUNTANT = 15,
  HEAD_OFFICE_MANAGER = 16,
  PRODUCTION_ACCOUNTANT = 17,
  LOGISTICS_ACCOUNTANT = 18,
  INSTALLATION_ACCOUNTANT = 19,
  DEALER_ACCOUNTANT = 20,
  AGENT_ACCOUNTANT = 21,
  PRODUCTION_LEADER = 22,
  LOGISTICS_LEADER = 23,
  INSTALLATION_LEADER = 24,
  DEALER_LEADER = 25,
  AGENT_LEADER = 26,
  CUSTOMER = 27,
  MAIL_ACCESS = 28,
  CLAIMS_ACCESS = 29,
  HR = 30,
  INTERVIEW_RESPONSIBLE = 31,
  BRAND_LEADER = 32,
}