import {message, Modal} from 'antd'
import {DataType, IStage} from '../interface'
import React from 'react'
import fetchAPI from "../../../../../lib/utils/fetch-api"
import {declension} from "../../../utils";

/**
 * Новая доставка
 */
export function handleCreate(history: any) {
  return () => {
    history.push('/logistics/deliveries/new')
  }
}

/**
 * Открытие модалки "Выгрузить заказы из WD"
 */
export function handleUnloadFromWD(setIsOpenUnloadFromWDModal: (val: boolean) => void) {
  return () => {
    setIsOpenUnloadFromWDModal(true)
  }
}

/**
 * Загрузка CSV
 */
export function handleUpload(
  getData: () => void,
  setLoading: (val: boolean) => void
) {
  return async (file: File) => {
    const formData = new FormData()
    formData.append('file', file)

    try {
      setLoading(true)
      const response = await fetch('/api/logistics/deliveries/upload-csv', {
        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        const result = await response.json()
        if (result.success_rows?.length > 0) {
          const successCount = result.success_rows?.length
          Modal.success({
            content: <div>
              {successCount} {declension(successCount, 'заказ', 'заказа', 'заказов')} успешно {declension(successCount, 'загружен', 'загружены', 'загружены')}</div>,
          })
        }
        if (result.failed_rows?.length > 0) {
          const errorMessages = result.failed_rows.map((order: any) => (
            <div key={order.id}>
              <b>{order.order_reference}</b>
              {`: ${order.errors.join(', ')}`}
            </div>
          ))

          Modal.error({
            title: result.message,
            content: <div>{errorMessages}</div>,
          })
        }
        getData()
      } else {
        const error = await response.text()
        message.error(`Ошибка загрузки файла: ${error}`)
      }
    } catch (error) {
      console.error('Ошибка загрузки файла', error)
      message.error('Ошибка загрузки файла')
    } finally {
      setLoading(false)
    }
  }
}

/**
 * Отправить в Шедекс
 */
interface IHandleSendToSchedexParams {
  selectedRowKeys: React.Key[]
  setSelectedRowKeys: (val: React.Key[]) => void
  setLoading: (val: boolean) => void
  getData: () => void
}

export function handleSendToSchedex({
                                      selectedRowKeys,
                                      setSelectedRowKeys,
                                      setLoading,
                                      getData,
                                    }: IHandleSendToSchedexParams) {
  return async () => {
    try {
      setLoading(true)
      const response = await fetchAPI('/api/schedex/orders/export', {
        method: 'POST',
        body: JSON.stringify({ orders: selectedRowKeys }),
        headers: { 'Content-Type': 'application/json' },
      })

      if (response.success) {
        if (response.result.success_orders?.length > 0) {
          const successCount = response.result.success_orders?.length
          Modal.success({
            content: <div>
              {successCount} {declension(successCount, 'заказ', 'заказа', 'заказов')} успешно {declension(successCount, 'отравлен', 'отправлены', 'отправлены')} в
              Шедекс</div>,
          })
        }
        if (response.result.failed_orders?.length > 0) {
          const errorMessages = response.result.failed_orders.map((order: any) => (
            <div key={order.id}>
              <a href={`/logistics/deliveries/${order.id}`} target="_blank" rel="noopener noreferrer">
                <b>{order.order_reference}</b>
              </a>
              {`: ${order.errors.join(', ')}`}
            </div>
          ))

          Modal.error({
            title: 'Ошибки при выгрузке',
            content: <div>{errorMessages}</div>,
          })
        }
        setSelectedRowKeys([])
        getData()
      } else {
        if (response.status === 429) {
          message.warning(response.error || 'Экспорт в Шедекс уже выполняется. Подождите и попробуйте снова.', 5)
        } else {
          message.error(response.error || 'Ошибка отправки в Шедекс', 5)
        }
      }
    } catch (error) {
      console.error('Ошибка отправки:', error)
      message.error('Ошибка отправки в Шедекс')
    } finally {
      setLoading(false)
    }
  }
}

/**
 * Выгрузить из Шедекс
 */
interface IHandleUnloadFromSchedexParams {
  selectedRowKeys: React.Key[]
  setSelectedRowKeys: (val: React.Key[]) => void
  setLoading: (val: boolean) => void
  getData: () => void
}

export function handleUnloadFromSchedex({
                                          selectedRowKeys,
                                          setSelectedRowKeys,
                                          setLoading,
                                          getData,
                                        }: IHandleUnloadFromSchedexParams) {
  return async () => {
    try {
      setLoading(true)
      const response = await fetchAPI('/api/schedex/orders/import', {
        method: 'POST',
        body: JSON.stringify({ orders: selectedRowKeys }),
        headers: { 'Content-Type': 'application/json' },
      })

      if (response.success) {
        if (response.result.failed_orders?.length > 0) {
          const errorMessages = response.result.failed_orders.map((order: any) => (
            <div key={order.id}>
              <b>{`Заказ `}{order.id}</b>
              {`: ${order.errors.join(', ')}`}
            </div>
          ))
          Modal.error({
            title: 'Ошибки при выгрузке',
            content: <div>{errorMessages}</div>,
          })
        }
        setSelectedRowKeys([])
        getData()
      } else {
        message.error(response.error || 'Ошибка выгрузки из Шедекс', 5)
      }
    } catch (error) {
      console.error('Ошибка выгрузки из Шедекс:', error)
      message.error('Ошибка выгрузки из Шедекс')
    } finally {
      setLoading(false)
    }
  }
}

/**
 * Запланировать
 */
interface IHandleSetPlannedParams {
  selectedRowKeys: React.Key[]
  setSelectedRowKeys: (val: React.Key[]) => void
  setLoading: (val: boolean) => void
  getData: () => void
}

export function handleSetPlanned({
                                   selectedRowKeys,
                                   setSelectedRowKeys,
                                   setLoading,
                                   getData,
                                 }: IHandleSetPlannedParams) {
  return async () => {
    try {
      setLoading(true)
      const response = await fetchAPI('/api/logistics/deliveries/planned', {
        method: 'POST',
        body: JSON.stringify({ orders: selectedRowKeys }),
        headers: { 'Content-Type': 'application/json' },
      })

      if (response.success) {
        message.success('Заказы успешно обновлены')
        getData()
      } else if (response.failed_orders) {
        const errorMessages = response.failed_orders.map((order: any) => (
          <div key={order.id}>
            <a href={`/logistics/deliveries/${order.id}`} target="_blank" rel="noopener noreferrer">
              <b>{order.order_reference}</b>
            </a>
            {`: ${order.missing_fields.join(', ')}`}
          </div>
        ))

        Modal.error({
          title: 'Некоторые заказы содержат пустые обязательные поля',
          content: <div>{errorMessages}</div>,
        })
      }
      setSelectedRowKeys([])
    } catch (error) {
      console.error('Ошибка при назначении статуса Planned:', error)
      message.error('Ошибка при назначении статуса Planned')
    } finally {
      setLoading(false)
    }
  }
}

/**
 * Открыть модалку "Назначить машину"
 */
export function handleSetVehicleModal(setIsOpenVehicleModal: (val: boolean) => void) {
  return () => {
    setIsOpenVehicleModal(true)
  }
}

/**
 * Открыть модалку "Изменить пирамиду и очередность"
 */
export function handleSetPyramidModal(setIsOpenPyramidModal: (val: boolean) => void) {
  return () => {
    setIsOpenPyramidModal(true)
  }
}

/**
 * Проверка доступности кнопок (enabled/disabled)
 */
export function isActionButtonEnabled(selectedRowKeys: React.Key[], data: DataType[]) {
  return (stage: string) => {
    return (
      selectedRowKeys.length > 0 &&
      selectedRowKeys.every((key) => {
        const selectedItem = data.find((item) => Number(item.id) === Number(key))
        return selectedItem?.stage?.code === stage
      })
    )
  }
}


/**
 * Закрыть модалку "Назначить машину"
 */
export function handleCancelVehicleModal(setIsOpenVehicleModal: (val: boolean) => void) {
  return () => {
    setIsOpenVehicleModal(false)
  }
}

/**
 * Закрыть модалку "Изменить пирамиду и очередность"
 */
export function handleCancelPyramidModal(setIsOpenPyramidModal: (val: boolean) => void) {
  return () => {
    setIsOpenPyramidModal(false)
  }
}

/**
 * Подтвердить в модалке "Назначить машину"
 */
interface IHandleOkVehicleModalParams {
  selectedRowKeys: React.Key[]
  setLoading: (val: boolean) => void
  getData: () => void
  setSelectedRowKeys: (val: React.Key[]) => void
  setIsOpenVehicleModal: (val: boolean) => void
}

export function handleOkVehicleModal({
                                       selectedRowKeys,
                                       setLoading,
                                       getData,
                                       setSelectedRowKeys,
                                       setIsOpenVehicleModal
                                     }: IHandleOkVehicleModalParams) {
  return async (modalFormData: any) => {
    try {
      setLoading(true)
      const response = await fetchAPI('/api/logistics/deliveries/vehicle', {
        method: 'POST',
        body: JSON.stringify({
          orders: selectedRowKeys,
          vehicle_id: modalFormData.vehicle_id,
          //pyramid_number: modalFormData.pyramid_number
        }),
        headers: { 'Content-Type': 'application/json' }
      })

      if (response.success) {
        setSelectedRowKeys([])
        await getData()
      } else {
        if (response.failed_orders?.length > 0) {
          const errorMessages = response.failed_orders.map((order: any) => (
            <div key={order.id}>
              <a href={`/logistics/deliveries/${order.id}`} target="_blank" rel="noopener noreferrer">
                <b>{order.order_reference}</b>
              </a>
              {`: ${order.errors.join(', ')}`}
            </div>
          ))

          Modal.error({
            title: 'Ошибки при выгрузке',
            content: <div>{errorMessages}</div>,
          })
        } else {
          message.error(response.error || 'Ошибка назначения машины', 5)
        }
      }
    } catch (error) {
      console.error('Ошибка назначения машины:', error)
      message.error('Ошибка назначения машины')
    } finally {
      setIsOpenVehicleModal(false)
      setLoading(false)
    }
  }
}


/**
 * Подтвердить в модалке "Изменить пирамиду и очередность"
 */
interface IHandleOkPyramidModalParams {
  selectedRowKeys: React.Key[]
  setLoading: (val: boolean) => void
  getData: () => void
  setSelectedRowKeys: (val: React.Key[]) => void
  setIsOpenPyramidModal: (val: boolean) => void
}

export function handleOkPyramidModal({
                                       selectedRowKeys,
                                       setLoading,
                                       getData,
                                       setSelectedRowKeys,
                                       setIsOpenPyramidModal
                                     }: IHandleOkPyramidModalParams) {
  return async (modalFormData: any) => {
    try {
      setLoading(true)
      const response = await fetchAPI('/api/logistics/deliveries/pyramid', {
        method: 'POST',
        body: JSON.stringify({
          orders: selectedRowKeys,
          sequence_in_run: modalFormData.sequence_in_run,
          pyramid_number: modalFormData.pyramid_number
        }),
        headers: { 'Content-Type': 'application/json' }
      })

      if (response.success) {
        setSelectedRowKeys([])
        await getData()
      } else {
        message.error(response.error || 'Ошибка назначения назначения пирамиды и очередности заказа', 5)
      }
    } catch (error) {
      console.error('Ошибка назначения назначения пирамиды и очередности заказа:', error)
      message.error('Ошибка назначения назначения пирамиды и очередности заказа')
    } finally {
      setIsOpenPyramidModal(false)
      setLoading(false)
    }
  }
}

/**
 * Закрыть модалку "Выгрузить заказы из WD"
 */
export function handleCancelUnloadFromWDModal(setIsOpenUnloadFromWDModal: (val: boolean) => void) {
  return () => {
    setIsOpenUnloadFromWDModal(false)
  }
}

/**
 * Подтвердить в модалке "Выгрузить заказы из WD"
 */
interface IHandleOkUnloadFromWDModalParams {
  setLoading: (val: boolean) => void
  getData: () => void
  setSelectedRowKeys: (val: React.Key[]) => void
  setIsOpenUnloadFromWDModal: (val: boolean) => void
}

export function handleOkUnloadFromWDModal({
                                            setLoading,
                                            getData,
                                            setSelectedRowKeys,
                                            setIsOpenUnloadFromWDModal,
                                          }: IHandleOkUnloadFromWDModalParams) {
  return async (modalFormData: any) => {
    try {
      setLoading(true)
      const response = await fetchAPI('/api/windraw/export/', {
        method: 'POST',
        body: JSON.stringify({
          region_id: modalFormData.region_id,
          delivery_date: modalFormData.delivery_date
        }),
        headers: { 'Content-Type': 'application/json' }
      })

      if (response.error) {
        message.error('Ошибка выгрузки из WD')
      } else {
        if (response.success_rows?.length > 0) {
          const successCount = response.success_rows?.length
          Modal.success({
            content: <div>
              {successCount} {declension(successCount, 'заказ', 'заказа', 'заказов')} успешно {declension(successCount, 'загружен', 'загружены', 'загружены')}</div>,
          })
        }
        if (response.failed_rows?.length > 0) {
          const errorMessages = response.failed_rows.map((order: any) => (
            <div key={order.id}>
              <b>{order.order_reference}</b>
              {`: ${order.errors.join(', ')}`}
            </div>
          ))

          Modal.error({
            title: response.message,
            content: <div>{errorMessages}</div>,
          })
        }
      }

      setIsOpenUnloadFromWDModal(false)
      setSelectedRowKeys([])
      await getData()
    } catch (error) {
      console.error('Ошибка при выгрузке из WD:', error)
      message.error('Ошибка при выгрузке из WD')
    } finally {
      setLoading(false)
    }
  }
}

/**
 * Редактирование заказов в списке
 */
interface IEditedRow {
  id: React.Key
  [key: string]: any
}

interface IHandleSaveEditParams {
  setLoading: (val: boolean) => void
  getData: () => void
  setSelectedRowKeys: (val: React.Key[]) => void
}

export function handleSaveEdit({
                                 setLoading,
                                 getData,
                                 setSelectedRowKeys
                               }: IHandleSaveEditParams) {
  return async (editedRows: IEditedRow[]) => {
    try {
      setLoading(true)
      const response = await fetchAPI('/api/logistics/deliveries/update-group', {
        method: 'POST',
        body: JSON.stringify(editedRows),
        headers: {
          'Content-Type': 'application/json'
        }
      })

      message.success('Данные успешно обновлены')

      getData()
      setSelectedRowKeys([])
    } catch (error) {
      console.error('Error updating deliveries:', error)
      message.error('Ошибка при обновлении данных')
    } finally {
      setLoading(false)
    }
  }
}