import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import B2BLayout from '../../../layouts/b2b'
import {message, Modal, Space} from 'antd'
import {getColumns} from './columns'
import fetchAPI from '../../../../lib/utils/fetch-api'
import {useHistory, useLocation} from 'react-router-dom'
import {Sorter} from '../../../interfaces'
import {objectToUrl} from '../../utils'
import {currentFiltersProps, DataType, Enums, IStage, TablePaginationPosition} from './interface'
import {createSelector} from 'reselect'
import {useSelector} from 'react-redux'
import './index.css'
import ActionButtons from "./action-buttons"
import {VehicleModal} from "../card/modals/modal-vehicle"
import FiltersDrawer from "../../../components/v2/filters-drawer"
import {getJsonSettingsByKey, TableSettingsButton, TransferItem} from "../../../components/v2/table-settings-button"
import {HStack} from 'src/react-app/ui/Stack'
import {DeliveriesPrintButton} from "../ui/deliveries-print-button/deliveries-print-button"
import {getPrintPath} from "../../../components/v2/export-excel-button"
import {UnloadFromWDModal} from "../card/modals/modal-unload-from-wd"
import {VehicleSummaryTiles} from './vehicle-summary'

import {
  handleCancelUnloadFromWDModal,
  handleCancelVehicleModal,
  handleCreate,
  handleOkPyramidModal,
  handleOkUnloadFromWDModal,
  handleOkVehicleModal, handleSaveEdit,
  handleSendToSchedex,
  handleSetPlanned,
  handleSetPyramidModal,
  handleSetVehicleModal,
  handleUnloadFromSchedex,
  handleUnloadFromWD,
  handleUpload,
  isActionButtonEnabled
} from './actions'
import DateFilter from "./filter-date";
import {getDataOfType} from "../../../../lib/utils/get-data-of-type"
import {FilterFastButtons} from "../ui/filter-buttons/filter-fast-buttons"
import FixedHeader from "../../../layouts/b2b/fixed-header"
import DataTable from "../../../components/data-table";
import dayjs from "dayjs";
import {PyramidModal} from "../card/modals/modal-pyramid";
import {FastFiltersDropdown} from "../ui/fast-filters-dropdown/fast-filters-dropdown";
import {getFilters} from "./filters";

const createMenuCounters = createSelector(
  (state: any) => state.menuV2,
  counters => counters,
)

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

function parseUrlSearchParams(search: string): currentFiltersProps {
  const urlParams = new URLSearchParams(search)
  return {
    delivery_date: urlParams.get('delivery_date') || undefined,
    client_phone: urlParams.get('client_phone') || undefined,
    client_name: urlParams.get('client_name') || undefined,
    dealer: urlParams.get('dealer') || undefined,
    id: Number(urlParams.get('id')) || undefined,
    stage: urlParams.get('stage') || undefined,
    distribution_centre_name: urlParams.get('distribution_centre_name') || undefined,
    old_delivery_date: urlParams.get('old_delivery_date') || undefined,
    order_reference: urlParams.get('order_reference') || undefined,
    vehicle_id: Number(urlParams.get('vehicle_id')) || undefined,
    pyramid_number: Number(urlParams.get('pyramid_number')) || undefined,
    lifting: urlParams.get('lifting') || undefined,
    payment_verified: urlParams.get('payment_verified') || undefined,
    delivery_complexity: Number(urlParams.get('delivery_complexity')) || undefined,
    capacity: urlParams.get('capacity') == null || isNaN(Number(urlParams.get('capacity'))) ? undefined : Number(urlParams.get('capacity')),
    volume: urlParams.get('volume') == null || isNaN(Number(urlParams.get('volume'))) ? undefined : Number(urlParams.get('volume')),
    cost: urlParams.get('cost') == null || isNaN(Number(urlParams.get('cost'))) ? undefined : Number(urlParams.get('cost')),
    type: urlParams.get('type') || undefined,
  }
}

const DeliveriesList = () => {
  const history = useHistory()
  const location = useLocation()

  const { sessionData } = useSelector(createSession)
  const isLeader = getDataOfType(sessionData, 'organization.id', Number, null) === 1
  const isManager = sessionData?.roles.includes(5)

  const [data, setData] = useState<DataType[]>([])
  const [availableFilters, setAvailableFilters] = useState<Enums>({
    manager: [],
    stage: [],
    organization: [],
    vehicle: [],
    pyramid_number: [],
    order_status_in_wd: [],
    distribution_centre_name: [],
  })
  const [urlParams] = useState(Object.fromEntries(new URLSearchParams(location.search)))
  const [currentFilters, setCurrentFilters] = useState<currentFiltersProps>({})

  const [loading, setLoading] = useState(false)
  const [bottomCenter] = useState<TablePaginationPosition>('bottomCenter')
  const [stages, setStages] = useState<any>(null)
  const [sorter, setSorter] = useState<Sorter>({})
  const [pagination, setPagination] = useState({
    current: Number(urlParams?.page) || 1,
    pageSize: 100,
    position: [bottomCenter],
    showSizeChanger: true,
    total: 0,
  })

  const { counters } = useSelector(createMenuCounters)
  const [localCounter, setLocalCounter] = useState<null | number>(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [isOpenVehicleModal, setIsOpenVehicleModal] = useState(false)
  const [isOpenPyramidModal, setIsOpenPyramidModal] = useState(false)
  const [isOpenUnloadFromWDModal, setIsOpenUnloadFromWDModal] = useState(false)
  const [enums, setEnums] = useState<any>({})
  const [fieldTypes, setFieldTypes] = useState<any>({})
  const [selectedDate, setSelectedDate] = useState<string>('')

  const headerRef = useRef<HTMLDivElement | null>(null)

  const jsonSettings = useSelector((state) => getJsonSettingsByKey(state, location.pathname))

  useEffect(() => {
    const newFilters = parseUrlSearchParams(location.search)

    setCurrentFilters(newFilters)
  }, [location.search])

  useEffect(() => {
    const newCounterData = counters?.['/deliveries']
    if (newCounterData && !localCounter) {
      setLocalCounter(newCounterData)
    }
    if (newCounterData && localCounter && newCounterData !== localCounter) {
      setLocalCounter(newCounterData)
      getData()
    }
  }, [counters])

  const breadcrumbs = useMemo(() => {
    return [
      {
        href: '/logistics/deliveries',
        title: 'Логистика',
      },
      {
        href: '',
        title: 'Доставки',
        back: true,
      }
    ]
  }, [history])

  const getStages = useCallback(() => {
    if (!stages) {
      fetchAPI('/api/get-stages/delivery')
        .then(data => {
          setStages(data)
        })
        .catch(() => {
          console.error('Не удалось получить стадии')
        })
    }
  }, [stages])

  const deleteRecord = useCallback((currentId: number) => {
    fetchAPI(`/api/logistics/deliveries/${currentId}`, {
      method: 'DELETE'
    })
      .then(() => {
        getData()
      })
      .catch(() => {
        console.error('Не удалось удалить запись')
      })
  }, [getStages])


  const getAvailableFilters = useCallback(() => {
    fetchAPI(`/api/logistics/deliveries/enum-list`)
      .then(data => {
        setEnums(data)

        const filters = {
          stage: stages?.map((i: IStage) => ({ label: i.name, value: i.code })) || [],
          vehicle: data?.logistics_vehicle?.map((i: any) => ({ label: i.value, value: i.id })) || [],
          order_status_in_wd: data?.logistics_status?.map((i: any) => ({ label: i.value, value: i.id })) || [],
          pyramid_number: data?.pyramid_number?.map((i: any) => ({ label: i.value, value: i.id })) || [],
        }

        setAvailableFilters(filters)
      })
      .catch(() => {
        console.error('Не удалось получить список доступных фильтров')
      })
  }, [stages])

  const getFieldTypes = useCallback(() => {
    fetchAPI(`/api/logistics/deliveries/field-types`)
      .then(data => {
        setFieldTypes(data)
      })
      .catch(() => {
        console.error('Не удалось получить список доступных фильтров')
      })
  }, [])

  const getData = useCallback(() => {
    if (!stages) {
      getStages()
    }
    try {
      setLoading(true)
      const urlParamsObj = Object.fromEntries(new URLSearchParams(location.search))

      fetchAPI(`/api/list/delivery?${objectToUrl(urlParamsObj)}`).then(({ data, pagination, sorter }) => {
        if (data) {
          setData(
            data.map((item: any) => ({
              ...item,
              deleteRecord,
              stage: stages && stages.find((stage: any) => stage.code === item.stage),
            })),
          )

          pagination.hide = !!selectedDate

          setPagination((prevState) => ({
            ...prevState,
            ...pagination
          }))
          setSorter(sorter)
        }
        setLoading(false)
      })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [deleteRecord, stages, location.search])

  const handleDeleteSelected = useCallback(() => {
    if (selectedRowKeys.length > 0) {
      Modal.confirm({
        title: 'Подтвердите действие',
        content: 'Вы уверены, что хотите удалить выбранные записи?',
        okText: 'Да',
        cancelText: 'Нет',
        onOk: async () => {
          await fetchAPI('/api/logistics/deliveries/bulk-delete', {
            method: 'DELETE',
            body: JSON.stringify({ ids: selectedRowKeys })
          })
            .then(() => {
              message.success('Записи успешно удалены')
              setSelectedRowKeys([])
              getData()
            })
            .catch(() => {
              message.error('Ошибка при удалении записей')
            })
        }
      })
    }
  }, [selectedRowKeys, getData])

  useEffect(() => {
    try {
      getStages()
    } catch (e) {
      alert('Не удалось получить данные, пожалуйста, перезагрузите страницу')
    }
  }, [])

  useEffect(() => {
    if (stages) {
      getData()
      getAvailableFilters()
      getFieldTypes()
    }
  }, [stages, getData, getAvailableFilters, getFieldTypes])

  const handleTableChange = (pagination: any, _filters: any, sorter: any) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    setPagination((prevState) => ({ ...prevState, ...pagination }))

    const current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
    } as any

    if(pagination.current) {
      current.page = pagination.current
    }
    if(pagination.pageSize) {
      current.pageSize = pagination.pageSize
    }
    if (current?.page === 1) {
      delete current.page
    }
    if (current?.sort_order === undefined) {
      delete current.sort_order
      delete current.sort_field
    }

    const urlParams = new URLSearchParams(current).toString()
    history.push({ search: urlParams })
    getData()
  }

  const handleRowClick = (record: DataType) => {
    const { id } = record
    history.push(`/logistics/deliveries/${id}`)
  }

  const onSelectChange = (selectedKeys: React.Key[]) => {
    setSelectedRowKeys(selectedKeys)
  }

  const changeFiltersHandler = useCallback((filters: any) => {
    let tmpFilter = { ...(filters || {}), pageSize: pagination.pageSize || 100 }
    const newUrlParams = new URLSearchParams(tmpFilter).toString()
    history.push({ search: newUrlParams })
    //getData()
  }, [pagination.pageSize, getData, history])

  const uploadProps = useMemo(() => {
    return {
      name: 'file',
      accept: '.csv',
      beforeUpload: handleUpload(getData, setLoading),
      showUploadList: false
    }
  }, [getData, setLoading])

  const columns = useMemo(() => getColumns(isLeader || isManager, sorter), [isLeader, isManager, sorter])

  const viewColumns = useMemo(() => {
    const defaultInput = { type: 'string' }

    const enumFieldsMapping = {
      'pyramid_number': 'pyramid_number',
      'vehicle': 'vehicle_id',
      'zone': 'zone_id',
    }

    const enhanceColumn = (column: any) => {
      if (!('dataIndex' in column) || !column.editable) {
        return column
      }

      const dataIndex = enumFieldsMapping[column.dataIndex] || column.dataIndex

      const fieldType = fieldTypes[dataIndex]
      let input = fieldType || defaultInput

      if (fieldType?.view?.name === 'select-from-enum' && enums[dataIndex]) {
        const enumValues = enums[dataIndex]
        input = {
          ...input,
          key: dataIndex,
          enum: enumValues.map((item: { id: string; value: string }) => item.id),
          oneOf: enumValues.map((item: { id: string; value: string }) => ({
            const: item.id,
            title: item.value
          }))
        }
      }

      return {
        ...column,
        input: {
          ...input,
          key: dataIndex,
        }
      }
    }

    if (jsonSettings && jsonSettings?.length > 0) {
      const enhancedColumns = columns.map(enhanceColumn)

      let result: any[] = []
      for (let i = 0; i < jsonSettings.length; i++) {
        const item = enhancedColumns.find(
          (column) => jsonSettings[i] === column.dataIndex
        )
        if (item) {
          result.push(item)
        }
      }
      return result
    }

    return columns.map(enhanceColumn)
  }, [columns, jsonSettings, fieldTypes, enums])

  const editableFields = useMemo(() => {
    return viewColumns
      .filter(column => column.editable)
      .map(column => column.dataIndex as string)
  }, [viewColumns])

  const excelPath = useMemo(() => {
    return getPrintPath('/api/list/delivery', new URLSearchParams(location.search))
  }, [location.search])

  const settingsColumns = useMemo(() => {
    return columns.map((column) => ({
      key: 'dataIndex' in column ? column.dataIndex : undefined,
      title: column.title,
    }))
  }, [columns]) as TransferItem[]

  const onCancelVehicleModal = useMemo(() => handleCancelVehicleModal(setIsOpenVehicleModal), [])
  const onOkVehicleModal = useMemo(() => handleOkVehicleModal({
    selectedRowKeys,
    setLoading,
    getData,
    setSelectedRowKeys,
    setIsOpenVehicleModal
  }), [selectedRowKeys, setLoading, getData])

  const onCancelPyramidModal = useMemo(() => handleCancelVehicleModal(setIsOpenPyramidModal), [])
  const onOkPyramidModal = useMemo(() => handleOkPyramidModal({
    selectedRowKeys,
    setLoading,
    getData,
    setSelectedRowKeys,
    setIsOpenPyramidModal
  }), [selectedRowKeys, setLoading, getData])

  const onCancelUnloadFromWDModal = useMemo(() => handleCancelUnloadFromWDModal(setIsOpenUnloadFromWDModal), [])
  const onOkUnloadFromWDModal = useMemo(() => handleOkUnloadFromWDModal({
    setLoading,
    getData,
    setSelectedRowKeys,
    setIsOpenUnloadFromWDModal
  }), [])

  const onCreate = useMemo(() => handleCreate(history), [history])

  const onUnloadFromWD = useMemo(() => handleUnloadFromWD(setIsOpenUnloadFromWDModal), [])

  const onSendToSchedex = useMemo(() => handleSendToSchedex({
    selectedRowKeys,
    setSelectedRowKeys,
    setLoading,
    getData
  }), [selectedRowKeys, getData])

  const onUnloadFromSchedex = useMemo(() => handleUnloadFromSchedex({
    selectedRowKeys,
    setSelectedRowKeys,
    setLoading,
    getData
  }), [selectedRowKeys, getData])

  const onSetPlanned = useMemo(() => handleSetPlanned({
    selectedRowKeys,
    setSelectedRowKeys,
    setLoading,
    getData
  }), [selectedRowKeys, getData])

  const onSetVehicle = useMemo(() => handleSetVehicleModal(setIsOpenVehicleModal), [])

  const onSetPyramid = useMemo(() => handleSetPyramidModal(setIsOpenPyramidModal), [])

  const checkIsActionButtonEnabled = useMemo(
    () => isActionButtonEnabled(selectedRowKeys, data),
    [selectedRowKeys, data]
  )

  // Определять какая пирамида больше всего выбрана
  const mostCommonPyramidNumber = useMemo(() => {
    if (selectedRowKeys.length === 0) return undefined

    const pyramidCount = selectedRowKeys
      .map((key) => data.find((item) => item.id === key))
      .filter((delivery) => delivery?.pyramid_number != null)
      .map((delivery) => delivery!.pyramid_number)
      .reduce((acc, pyramid) => {
        if (pyramid != null) {
          acc[pyramid] = (acc[pyramid] || 0) + 1
        }
        return acc;
      }, {} as { [key: number]: number })

    if (Object.keys(pyramidCount).length === 0) return undefined

    return Object.keys(pyramidCount)
      .map(Number)
      .reduce((a, b) => (pyramidCount[a] > pyramidCount[b] ? a : b))
  }, [selectedRowKeys, data])

  // Определять выбрана ли одна дата доставки в фильтре
  useEffect(() => {
    if (currentFilters?.delivery_date) {
      const [startDate, endDate] = currentFilters.delivery_date.split('-')

      if (startDate === endDate) {
        setSelectedDate(startDate)
      } else {
        setSelectedDate('')
      }
    }
  }, [currentFilters])

  // Событие изменение фильтров
  const changeDateFilterHandler = (newDate: string) => {
    setLoading(true)
    setData([])
    const urlParams = new URLSearchParams(location.search)

    if (newDate) {
      urlParams.set('delivery_date', `${newDate}-${newDate}`)
    } else {
      urlParams.delete('delivery_date')
    }

    history.push({ search: urlParams.toString() })
    setSelectedDate(newDate)
  }

  // Событие пагинации
  const handlePageChange = (page: number, pageSize: number) => {
    setPagination((prevState) => ({
      ...prevState,
      current: Number(page) || 1,
      pageSize: pageSize,
    }))

    const currentUrlParams = new URLSearchParams(location.search)
    currentUrlParams.set('page', page.toString())
    currentUrlParams.set('pageSize', pageSize.toString())
    history.push({ search: currentUrlParams.toString() })
  }

  const onSaveEdit = handleSaveEdit({
    setLoading,
    getData,
    setSelectedRowKeys
  })

  const totalItemsCount = useMemo(() => {
    return data.filter(item => item?.delivery_date).reduce((sum, record) => Number(sum) + (Number(record.cost) || 0), 0)
  }, [data])

  return (
    <B2BLayout breadcrumbs={breadcrumbs} isViewScrollButton>
      <FixedHeader ref={headerRef}>
        <Space style={{ marginBottom: '20px', width: '100%', justifyContent: 'space-between' }} align={'start'}>
          <Space>
            <ActionButtons
              onUnloadFromWD={onUnloadFromWD}
              uploadProps={uploadProps}
              onSendToSchedex={onSendToSchedex}
              onUnloadFromSchedex={onUnloadFromSchedex}
              onSetPlanned={onSetPlanned}
              onSetVehicle={onSetVehicle}
              onSetPyramid={onSetPyramid}
              isSendToSchedexEnabled={checkIsActionButtonEnabled('new')}
              isUnloadFromSchedexEnabled={checkIsActionButtonEnabled('sent_to_schedex')}
              isSetPlannedEnabled={checkIsActionButtonEnabled('uploaded_from_schedex')}
              isSetVehicleEnabled={checkIsActionButtonEnabled('planned') || checkIsActionButtonEnabled('exported_to_wd')}
              isSetPyramidEnabled={checkIsActionButtonEnabled('uploaded_from_schedex')}
            />
            {/*<FastFiltersDropdown
              filters={[
                {
                  name: 'type',
                  label: 'Доставка Долг',
                  value: 'Долг'
                }
              ]}
              currentFilters={currentFilters}
              onChange={changeFiltersHandler}
            />*/}
          </Space>
          <FiltersDrawer
            filters={getFilters({ currentFilters, availableFilters })}
            onChange={changeFiltersHandler}
            currentFilters={currentFilters}
          />
        </Space>

        <Space align={'start'} wrap>
          <DateFilter selectedDate={selectedDate} onChange={changeDateFilterHandler}/>
          <FilterFastButtons
            groupName="distribution_centre_name"
            filters={[
              {
                "name": "Рязань",
                "code": "Рязань",
              },
              {
                "name": "Калуга",
                "code": "Калуга",
              },
            ]}
            onChange={changeFiltersHandler}
          />
          <FilterFastButtons
            filters={stages}
            onChange={changeFiltersHandler}
          />
        </Space>
      </FixedHeader>
      <VehicleSummaryTiles data={data} currentFilters={currentFilters}/>
      <Space style={{ marginBottom: '10px', width: '100%', justifyContent: 'flex-end' }} align={'start'}>
        <HStack gap={'8'} align={'start'}>
          <DeliveriesPrintButton
            path={excelPath}
            columns={viewColumns}
            stages={stages}
          />
          <TableSettingsButton items={settingsColumns}/>
        </HStack>

      </Space>
      <DataTable
        columns={viewColumns}
        data={data}
        loading={loading}
        rowKey={(r) => String(r.id)}
        highlightLastRow
        rowClassName={(record) => {
          if (record.type === 'Сбор') return 'blue'
          if (record.type === 'Довоз') return 'pink'
          if (record.type === 'Долг') return 'pink'
          if (record.pyramid_number != null) {
            const pyramidClass = String(record.pyramid_number)
              .toLowerCase()
              .replace(/\s+/g, '-')
            return `pyramid-${pyramidClass}`
          }
          return ''
        }}
        pagination={pagination}
        selectedRowKeys={selectedRowKeys}
        onSelectChange={onSelectChange}
        onRowClick={handleRowClick}
        onCreate={onCreate}
        onDelete={handleDeleteSelected}
        onPageChange={handlePageChange}
        showDelete
        onTableChange={handleTableChange}
        className={'delivery-table'}
        headerRef={headerRef}
        editableFields={editableFields}
        onSaveEdit={onSaveEdit}
        maxLines={1}
        bordered
        extraFooterInfo={
          selectedDate ? (
            <span>
              Кол-во изделий: <b>{totalItemsCount}</b>
            </span>
          ) : null
        }
      />

      {/* Модалка "Назначить машину" */}
      <VehicleModal
        isModalOpen={isOpenVehicleModal}
        onCancel={onCancelVehicleModal}
        onOk={onOkVehicleModal}
        enums={enums}
        selectedPyramid={mostCommonPyramidNumber}
      />

      {/* Модалка "Изменить пирамиду и очередность" */}
      <PyramidModal
        isModalOpen={isOpenPyramidModal}
        onCancel={onCancelPyramidModal}
        onOk={onOkPyramidModal}
        enums={enums}
      />

      {/* Модалка "Выгрузить заказы из WD" */}
      <UnloadFromWDModal
        isModalOpen={isOpenUnloadFromWDModal}
        onCancel={onCancelUnloadFromWDModal}
        onOk={onOkUnloadFromWDModal}
      />
    </B2BLayout>
  )
}

export default DeliveriesList
