import React, {useState, useEffect} from 'react'
import {Button, Layout, Row, Col, Skeleton} from 'antd'
import {useHistory} from 'react-router-dom'
import YearFilter from './components/year-filter'
import MonthFilter from './components/month-filter'
import ResourceFilter from './components/resource-filter'
import DepartmentFilter from './components/department-filter'
import VacancyFilter from './components/vacancy-filter'
import ManagerFilter from './components/manager-filter'
import ResourcesTable from './components/resources-table'
import StagesTable from './components/stages-table'
import BarChart from './components/bar-chart'
import Statistics from './components/statistics'
import B2BLayout from '../../layouts/b2b'
import {
  MapContent,
  MapTitle,
  MapWrapper,
  TaskWrapper,
  FiltersContainer,
  TableContainer,
  TableTitle,
} from './styles'
import {IStatisticsProps, IBarChartData} from './interfaces/interfaces'
import {useFetchData} from './hooks/useFetchData'
import {monthOrder} from './constants'
import {SplittedByYearsTable} from './components/splitted-tables'

const {Content, Sider} = Layout

const breadcrumbs = [
  {
    href: '/hr',
    title: 'Отдел персонала',
  },
  {
    //href: '/',
    title: 'Дашборды',
  },
]

export default function AdminNewObjectPage() {
  const history = useHistory()
  const [selectedYears, setSelectedYears] = useState<string[]>([])
  const [selectedMonths, setSelectedMonths] = useState<string[]>([])
  const [selectedResources, setSelectedResources] = useState<string[]>([])
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([])
  const [selectedVacancies, setSelectedVacancies] = useState<string[]>([])
  const [selectedManagers, setSelectedManagers] = useState<string[]>([])
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)

  const {
    candidateData,
    stagesData,
    paymentsData,
    resourcesColumns,
    stagesColumns,
    years,
    resources,
    departments,
    vacancies,
    managers,
    loading,
    refetchData,
  } = useFetchData(
    selectedYears,
    selectedMonths,
    selectedResources,
    selectedDepartments,
    selectedVacancies,
    selectedManagers,
  )

  useEffect(() => {
    if (years.length > 0 && isFirstLoad) {
      const currentYear = new Date().getFullYear().toString()
      setSelectedYears([currentYear])
      // setSelectedYears([])
      setIsFirstLoad(false)
    }
  }, [years, isFirstLoad])

  const handleYearChange = (years: string[]) => {
    setSelectedYears(years)
  }

  const handleMonthChange = (months: string[]) => {
    setSelectedMonths(months)
  }

  const handleResourceChange = (resources: string[]) => {
    setSelectedResources(resources)
  }

  const handleDepartmentChange = (departments: string[]) => {
    setSelectedDepartments(departments)
  }

  const handleVacancyChange = (vacancies: string[]) => {
    setSelectedVacancies(vacancies)
  }

  const handleManagerChange = (managers: string[]) => {
    setSelectedManagers(managers)
  }

  const handlePaymentsClick = () => {
    history.push('/hr/payments')
  }

  const handleClearAllFilters = () => {
    const currentYear = new Date().getFullYear().toString()
    setSelectedYears([currentYear])
    setSelectedMonths([])
    setSelectedResources([])
    setSelectedDepartments([])
    setSelectedVacancies([])
    setSelectedManagers([])
    //refetchData()
  }

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  const filteredPaymentsData =
    selectedMonths.length > 0 || selectedYears.length > 0
      ? paymentsData.filter((payment) => {
          const paymentDate = new Date(payment.payment_date)
          const paymentMonth = capitalizeFirstLetter(
            paymentDate.toLocaleString('en-EN', {month: 'long'}),
          )
          const paymentYear = paymentDate.getFullYear().toString()

          const isMonthSelected = selectedMonths.includes(paymentMonth)
          const isYearSelected = selectedYears.includes(paymentYear)

          return (
            (selectedMonths.length === 0 || isMonthSelected) &&
            (selectedYears.length === 0 || isYearSelected)
          )
        })
      : paymentsData

  const filteredPaymentsDataByResources =
    selectedResources.length > 0
      ? filteredPaymentsData.filter((payment) => selectedResources.includes(payment.resource_id))
      : filteredPaymentsData

  const totalPayments = filteredPaymentsDataByResources.reduce(
    (acc, payment) => acc + parseFloat(payment.payment_fact),
    0,
  )

  const totalResponses = stagesData.reduce((acc, data) => acc + data.total, 0)
  const totalInterviews = stagesData.reduce((acc, data) => acc + data.interview, 0)
  const totalRejected = stagesData.reduce((acc, data) => acc + data.rejected, 0)
  const totalHired = stagesData.reduce((acc, data) => acc + data.hired, 0)
  const totalAccepted = stagesData.reduce((acc, data) => acc + data.accepted, 0)

  const pricePerResponse = totalResponses > 0 ? Math.round(totalPayments / totalResponses) : 0
  const pricePerCandidate = totalAccepted > 0 ? Math.round(totalPayments / totalAccepted) : 0

  const statistics: IStatisticsProps = {
    totalPayments,
    totalResponses,
    pricePerResponse,
    totalInterviews,
    totalHired,
    totalAccepted,
    totalRejected,
    pricePerCandidate,
    loading,
  }

  const pricePerResponseChartData: IBarChartData = {
    labels: monthOrder.map((month) => month.label),
    datasets: [
      {
        label: 'Цена за отклик',
        data: monthOrder.map((month) => {
          const responsesForMonth = candidateData
            .filter((data) => data.month.toLowerCase() === month.label.toLowerCase())
            .reduce((sum, data) => sum + data.total, 0)
          const paymentsForMonth = filteredPaymentsDataByResources
            .filter((payment) => {
              const paymentDate = new Date(payment.payment_date)
              return (
                /*selectedMonths.includes(
                  capitalizeFirstLetter(paymentDate.toLocaleString('ru-RU', {month: 'long'})),
                ) ||*/
                paymentDate.toLocaleString('ru-RU', {month: 'long'}).toLowerCase() ===
                month.label.toLowerCase()
              )
            })
            .reduce((sum, payment) => sum + parseFloat(payment.payment_fact), 0)

          return responsesForMonth > 0 ? Math.round(paymentsForMonth / responsesForMonth) : 0
        }),
        backgroundColor: 'rgb(0,54,203)',
        borderColor: 'rgb(0,54,203)',
        borderWidth: 1,
      },
    ],
  }

  const paymentsChartData: IBarChartData = {
    labels: monthOrder.map((month) => month.label),
    datasets: [
      {
        label: 'Оплата ресурсов, руб',
        data: monthOrder.map((month) =>
          filteredPaymentsDataByResources
            .filter((payment) => {
              const paymentDate = new Date(payment.payment_date)
              return (
                capitalizeFirstLetter(paymentDate.toLocaleString('ru-RU', {month: 'long'})) ===
                month.label
              )
            })
            .reduce((sum, payment) => sum + parseFloat(payment.payment_fact), 0),
        ),
        backgroundColor: 'rgb(255,19,19)',
        borderColor: 'rgb(255,19,19)',
        borderWidth: 1,
      },
    ],
  }

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>Каналы привлечения кандидатов</MapTitle>
          <Button type="primary" onClick={handlePaymentsClick}>
            Оплаты
          </Button>
        </TaskWrapper>
        <Layout>
          <Content style={{padding: '20px'}}>
            <Statistics {...statistics} />
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <MapContent>
                  <TableTitle>Количество откликов по ресурсам по дате создания</TableTitle>
                  <TableContainer>
                    {loading ? (
                      <Skeleton active />
                    ) : (
                      SplittedByYearsTable(ResourcesTable, {
                        data: candidateData,
                        columns: resourcesColumns,
                        showTotal: selectedMonths.length === 0 || selectedMonths.length > 1,
                      })
                    )}
                  </TableContainer>
                </MapContent>
              </Col>
              <Col span={24}>
                <MapContent>
                  <TableTitle>Воронка уникальных откликов по дате создания</TableTitle>
                  <TableContainer>
                    {loading ? (
                      <Skeleton active />
                    ) : (
                      SplittedByYearsTable(StagesTable, {
                        data: stagesData,
                        columns: stagesColumns,
                        showTotal: selectedMonths.length === 0 || selectedMonths.length > 1,
                      })
                    )}
                  </TableContainer>
                </MapContent>
              </Col>
              <Col span={12}>
                <MapContent>
                  <TableTitle>Цена за отклик, руб</TableTitle>
                  <div style={{height: '400px'}}>
                    {loading ? <Skeleton active /> : <BarChart data={pricePerResponseChartData} />}
                  </div>
                </MapContent>
              </Col>
              <Col span={12}>
                <MapContent>
                  <TableTitle>Оплата ресурсов, руб</TableTitle>
                  <div style={{height: '400px'}}>
                    {loading ? <Skeleton active /> : <BarChart data={paymentsChartData} />}
                  </div>
                </MapContent>
              </Col>
            </Row>
          </Content>
          <Sider width={300} style={{background: '#f0f2f5', padding: '20px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <h3>Фильтры</h3>
              <Button onClick={handleClearAllFilters} type="default">
                Очистить все
              </Button>
            </div>
            <FiltersContainer>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <YearFilter
                    selectedYears={selectedYears}
                    onYearChange={handleYearChange}
                    years={years}
                  />
                </Col>
                <Col span={24}>
                  <MonthFilter
                    selectedMonths={selectedMonths}
                    onMonthChange={handleMonthChange}
                    months={monthOrder}
                  />
                </Col>
                <Col span={24}>
                  <ResourceFilter
                    selectedResources={selectedResources}
                    onResourceChange={handleResourceChange}
                    resources={resources}
                  />
                </Col>
                <Col span={24}>
                  <DepartmentFilter
                    selectedDepartments={selectedDepartments}
                    onDepartmentChange={handleDepartmentChange}
                    departments={departments}
                  />
                </Col>
                <Col span={24}>
                  <VacancyFilter
                    selectedVacancies={selectedVacancies}
                    onVacancyChange={handleVacancyChange}
                    vacancies={vacancies}
                  />
                </Col>
                <Col span={24}>
                  <ManagerFilter
                    selectedManagers={selectedManagers}
                    onManagerChange={handleManagerChange}
                    managers={managers}
                  />
                </Col>
              </Row>
            </FiltersContainer>
          </Sider>
        </Layout>
      </MapWrapper>
    </B2BLayout>
  )
}
