import PhoneInput from 'react-phone-input-2'
import {Input} from 'antd'
import React, {useCallback, useState} from 'react'
import ru from 'react-phone-input-2/lang/ru.json'

/**
 * Принимает и возвращает только 0 и положительные целочисленные значения, напр. ID
 *
 * @param defaultValue
 * @param onSearch
 * @param onChange
 * @param placeholder
 * @param style
 * @constructor
 */
interface InputIntegerProps {
  defaultValue?: number | null
  onChange: (value?: number) => void
  placeholder?: string
  style?: React.CSSProperties
}

export const InputInteger: React.FC<InputIntegerProps> = ({
                                                            defaultValue,
                                                            onChange,
                                                            placeholder,
                                                            style
                                                          }) => {
  const [localValue, setLocalValue] = useState<number | null | undefined>(defaultValue)

  const handlerChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {value: inputValue} = e.target
      const reg = /^\d*$/
      if (reg.test(inputValue)) {
        const realValue = inputValue === '' ? undefined : Number(inputValue);
        setLocalValue(realValue ?? null)
        onChange(realValue)
      }
    },
    [onChange]
  )

  return (
    <Input
      value={localValue ?? ''}
      style={style}
      placeholder={placeholder}
      allowClear
      onChange={handlerChange}
      maxLength={10}
    />
  )
}

/**
 * Возвращает только валидные номера телефонов
 *
 * @param defaultValue
 * @param onChange
 * @constructor
 */
interface InputPhoneProps {
  defaultValue?: string | null
  onChange: (value?: string) => void
  style?: React.CSSProperties
}

export const InputPhone: React.FC<InputPhoneProps> = ({ defaultValue, onChange, style }) => {
  const [localValue, setLocalValue] = useState<string | null>(defaultValue || null)
  const [length, setLength] = useState<number | undefined>()

  const changePhone = useCallback(() => {
    if (localValue && localValue.length === length) {
      onChange(localValue)
    }
  }, [onChange, localValue, length])

  const handlerChange = useCallback((v: string, c: any) => {
    setLength(c.format.match(/\./g)?.length)
    setLocalValue(v)
    onChange(v)
  }, [])

  const clearLocal = useCallback(() => {
    setLocalValue('7')
    onChange(undefined)
  }, [])

  return (
    <div style={{ display: 'flex', width: '100%', position: 'relative' }}>
      <PhoneInput
        inputStyle={{ height: 32, borderRadius: 2, border: '1px solid #d9d9d9', width: '100%', ...style }}
        value={localValue || ''}
        dropdownStyle={{}}
        enableSearch={false}
        searchPlaceholder={'Поиск'}
        localization={ru}
        country={'ru'}
        onlyCountries={['ru', 'kz', 'by', 'tj', 'uz', 'am', 'kg', 'az', 'md', 'tm']}
        onChange={handlerChange}
      />
      {localValue && localValue.length >= 10 && (
        <span
          className="ant-input-suffix"
          style={{ height: '100%', right: '38px', zIndex: 10, position: 'absolute' }}
          onClick={clearLocal}
        >
          <span className="ant-input-clear-icon" role="button">
            <span role="img" aria-label="close-circle" className="anticon anticon-close-circle">
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="close-circle"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7-3.7-1.9-5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
              </svg>
            </span>
          </span>
        </span>
      )}
    </div>
  )
}
