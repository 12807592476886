// 6. Договоры по заявкам этого месяца (кол-во ЦО, если дата оплаты не null и Дата заявки находится в пределах выбранного периода)
import { isDateInRange } from './isDateInRange'


export const isValidContracts = (
  order,
  selectedMonths: string[],
  selectedYears: string[],
): boolean => {
  const validStages = ['ДОГОВОР','ПРОИЗВОДСТВО', 'ДОСТАВКА','МОНТАЖ', 'ВЫПОЛНЕНО', 'МОНТАЖ']
  const stage = order["stage-code"]?.toUpperCase();
  
  return (
    (validStages.includes(stage)) &&
    !!order['prepayment_date'] &&
    !!order['case-date-fact'] &&
    isDateInRange(order['prepayment_date'], selectedMonths, selectedYears)
  )
}
