import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Menu, message, Modal, Space, Upload } from 'antd'
import { DownOutlined, UploadOutlined } from '@ant-design/icons'
import { UploadProps } from 'antd/es/upload'
import type { MenuInfo } from 'rc-menu/lib/interface'

interface ActionButtonsProps {
  onUnloadFromWD: () => void
  uploadProps: UploadProps
  onSendToSchedex: () => void
  onUnloadFromSchedex: () => void
  isSendToSchedexEnabled: boolean
  isUnloadFromSchedexEnabled: boolean
  onSetPlanned: () => void
  isSetPlannedEnabled: boolean
  onSetVehicle: () => void
  onSetPyramid: () => void
  isSetVehicleEnabled: boolean
  isSetPyramidEnabled: boolean
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
                                                       onUnloadFromWD,
                                                       uploadProps,
                                                       onSendToSchedex,
                                                       onUnloadFromSchedex,
                                                       onSetPlanned,
                                                       onSetVehicle,
                                                       onSetPyramid,
                                                       isSendToSchedexEnabled,
                                                       isUnloadFromSchedexEnabled,
                                                       isSetPlannedEnabled,
                                                       isSetVehicleEnabled,
                                                       isSetPyramidEnabled,
                                                     }) => {
  const [isLockedSchedex, setIsLockedSchedex] = useState(false)
  const [schedexLockedTimer, setSchedexLockedTimer] = useState<number | null>(null)
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    const checkLockStatus = async () => {
      try {
        const response = await fetch('/api/schedex/lock/status')
        const data = await response.json()

        if (data.isLocked) {
          setIsLockedSchedex(true)
          setSchedexLockedTimer(data.timeout)
        }
      } catch (error) {
        console.error('Ошибка проверки блокировки:', error)
        message.error('Ошибка проверки блокировки')
      }
    }

    checkLockStatus()

    const eventSource = new EventSource('/api/schedex/events/lock')

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data)
      setIsLockedSchedex(data.isLocked)

      if (data.isLocked && data.timeout) {
        setSchedexLockedTimer(data.timeout)
      } else {
        setSchedexLockedTimer(null)
      }
    }

    eventSource.onerror = () => {
      console.error('Ошибка подключения к SSE')
      eventSource.close()
    }

    return () => {
      eventSource.close()
    }
  }, [])

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>

    if (isLockedSchedex && schedexLockedTimer && schedexLockedTimer > 0) {
      interval = setInterval(() => {
        setSchedexLockedTimer((prev) => {
          if (prev && prev > 0) {
            return prev - 1
          } else {
            clearInterval(interval)
            setIsLockedSchedex(false)
            setIsModalVisible(false)
            return 0
          }
        })
      }, 1000)
    }

    return () => clearInterval(interval)
  }, [isLockedSchedex, schedexLockedTimer])

  const handleSendToSchedexClick = () => {
    if (isLockedSchedex && schedexLockedTimer && schedexLockedTimer > 0) {
      setIsModalVisible(true)
    } else {
      onSendToSchedex()
    }
  }

  const handleSetVehicleClick = () => {
    if (isLockedSchedex && schedexLockedTimer && schedexLockedTimer > 0) {
      setIsModalVisible(true)
    } else {
      onSetVehicle()
    }
  }

  const handleMenuClick = (e: MenuInfo) => {
    switch (e.key) {
      case 'sendToSchedex':
        handleSendToSchedexClick()
        break
      case 'unloadFromSchedex':
        onUnloadFromSchedex()
        break
      case 'setPyramid':
        onSetPyramid()
        break
      case 'setPlanned':
        onSetPlanned()
        break
      case 'setVehicle':
        handleSetVehicleClick()
        break
      default:
        break
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="sendToSchedex" disabled={!isSendToSchedexEnabled}>
        Отправить в Шедекс
      </Menu.Item>
      <Menu.Item key="unloadFromSchedex" disabled={!isUnloadFromSchedexEnabled}>
        Выгрузить из Шедекс
      </Menu.Item>
      <Menu.Item key="setPyramid" disabled={!isSetPyramidEnabled}>
        Изменить пирамиду и очередность
      </Menu.Item>
      <Menu.Item key="setPlanned" disabled={!isSetPlannedEnabled}>
        Запланировать
      </Menu.Item>
      <Menu.Item key="setVehicle" disabled={!isSetVehicleEnabled}>
        Назначить экипаж
      </Menu.Item>
    </Menu>
  )

  return (
    <Space style={{ width: '100%' }} wrap>
      <Button onClick={onUnloadFromWD}>
        Выгрузить заказы из WD
      </Button>
      <Upload {...uploadProps}>
        <Button icon={<UploadOutlined />}>Загрузить CSV</Button>
      </Upload>
      <Dropdown overlay={menu} trigger={['click']}>
        <Button type="primary">
          Действия <DownOutlined />
        </Button>
      </Dropdown>

      <Modal
        title="Выгрузка в Шедекс недоступна"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setIsModalVisible(false)}>
            Закрыть
          </Button>,
        ]}
      >
        <p>Превышен лимит вызовов: лимит для загрузки заказов составляет 1 раз в 60 секунд.</p>
        {schedexLockedTimer !== null && (
          <p>Попробуйте снова через <b>{schedexLockedTimer}</b> секунд.</p>
        )}
      </Modal>
    </Space>
  )
}

export default React.memo(ActionButtons)