import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import React, { useCallback } from 'react'
import {Badge, Popconfirm} from 'antd'
import { DataType } from './interface'
import { ButtonActionWrapper } from '../../../components/list-operations/styles'
import { DeleteTwoTone } from '@ant-design/icons'
import { Stage } from '../../../components/activity-timeline/styles'
import { formatPrice } from '../../utils'
import { Value } from '../../elements'
import { Sorter } from '../../../interfaces'

const formatPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return <Value data={undefined}/>
  }
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`
  }
  return phoneNumber
}

const counterStyle ={ display: 'flex', justifyContent: 'center' }

const ColumnId: ColumnsType<DataType> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 70,
    fixed: 'left'
  },
  {
    title: 'Стадия',
    dataIndex: 'stage',
    key: 'stage',
    width: 170,
    render: (_, { stage }) => <StageContainer stage={stage} />
  },
  {
    title: 'Чаты',
    width: 60,
    dataIndex: 'count_unread_messages',
    key: 'count_unread_messages',
    sorter: true,
    render: val => <div style={counterStyle}>{val?<Badge count={val}/>:'-'}</div>
  }
]

const ColumnOrganization: ColumnsType<DataType> = [
  {
    title: 'Бренд',
    width: 200,
    dataIndex: 'brand',
    key: 'brand',
    sorter: true,
    render: val => <Value data={val}/>
  },
  {
    title: 'Офис',
    width: 250,
    dataIndex: 'office',
    key: 'office',
    sorter: true,
    render: val => <Value data={val}/>
  }
]

const ColumnsGeneral: ColumnsType<DataType> = [
  {
    title: 'Дата обращения',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 130,
    sorter: true,
    render: key => dayjs(key).format('DD.MM.YYYY HH:mm')
  },
  {
    title: 'ФИО менеджера',
    dataIndex: 'manager_name',
    key: 'manager_name',
    width: 120,
    sorter: true,
    render: val => <Value data={val}/>
  },
  {
    title: 'Контактное лицо клиента',
    dataIndex: 'name',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Телефон клиента',
    dataIndex: 'phone',
    key: 'phone',
    width: 150,
    render: val => formatPhoneNumber(val)
  },
  {
    title: 'Авито ID',
    dataIndex: 'avito_id',
    key: 'avito_id',
    width: 100,
  },
  {
    title: 'Адрес объекта',
    dataIndex: 'address',
    key: 'address',
    width: 120,
    render: val => <Value data={val}/>
  },
  {
    title: 'Вид контакта',
    dataIndex: 'source_appeal',
    key: 'source_appeal',
    width: 200,
    render: val => <Value data={val}/>
  },
  {
    title: 'Сумма заказа',
    dataIndex: 'order_price',
    key: 'order_price',
    width: 130,
    sorter: true,
    render: key => formatPrice(key || 0)
  },
  {
    title: 'Номер производственного заказа',
    dataIndex: 'production_number',
    key: 'production_number',
    width: 180,
    render: val => <Value data={val}/>
  },
  {
    title: 'В работе менеджера с',
    dataIndex: 'order_created_at',
    key: 'order_created_at',
    width: 130,
    sorter: true,
    render: val => val ? dayjs(val).format('DD.MM.YYYY HH:mm') : <Value data={val}/>
  },
  {
    title: 'Причина отмены',
    width: 200,
    dataIndex: 'reason_cancel',
    key: 'reason_cancel',
    render: val => <Value data={val}/>
  },
  {
    title: 'Дата обновления',
    width: 200,
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: val => val ? dayjs(val).format('DD.MM.YYYY HH:mm') : <Value data={val}/>
  }
]

const ColumnAction: ColumnsType<DataType> = [
  {
    title: 'Действие',
    key: 'operation',
    dataIndex: 'operation',
    className: 'no-edit',
    fixed: 'right',
    width: 100,
    align: 'center',
    render: (_, {id, deleteRecord}) => {
      return (
        <span onClick={e => {
          e.stopPropagation()
        }}>
          <DeleteButton id={id} deleteRecord={deleteRecord}/>
        </span>
      )
    }
  }
]

export function DeleteButton({id, deleteRecord}) {
  const localDeleteRecord = useCallback(() => deleteRecord(id), [deleteRecord, id])
  return (
    <Popconfirm
      title="Вы уверены, что хотите удалить эту запись?"
      okText="Да"
      cancelText="Нет"
      onConfirm={() => localDeleteRecord()}
    >
      <ButtonActionWrapper title="Удалить">
        <DeleteTwoTone />
      </ButtonActionWrapper>
    </Popconfirm>
  )
}

export function getColumns(leader: boolean, sorter: Sorter) {
  const columns = leader
    ? ColumnId.concat(ColumnOrganization, ColumnsGeneral, ColumnAction)
    : ColumnId.concat(ColumnsGeneral)

  return columns.map(item => {
    if (item.key === sorter.sort_field) {
      item.sortOrder = sorter.sort_order
    } else {
      delete item.sortOrder
    }
    return item
  })
}

export function StageContainer({ stage }) {
  if (stage) {
    return <Stage style={stage?.style}>{stage.name}</Stage>
  }
  return <>...</>
}
