import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Table } from 'antd'
import fetchAPI from '../../../../../lib/utils/fetch-api'
import dayjs from 'dayjs'
import { getCall } from '../../../../components/v2/activity-timeline/api/getCall'
import { formatPhoneNumber } from '../../../../pages_v2/orders/list/columns'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { ColumnFilterItem } from 'antd/es/table/interface'
import { State } from '../../../../interfaces'

interface ICallLogData {
  id: number
  date: string
  duration: number
  call_direction: string
  record: string[]
  manager: string
  office: string
  state: string
}

const createSession = createSelector(
  (state: State) => state.session,
  sessionData => sessionData,
)

const secondsToHumanReadable = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return dayjs().set('minute', minutes).set('second', remainingSeconds).format('m:ss')
}

function TabCallLog({ clientId }){
  const [data, setData] = useState<ICallLogData[]>([])
  const [officesList, setOfficesList] = useState<ColumnFilterItem[]>([])
  const { sessionData } = useSelector(createSession)
  // для ГО лидера (16) и руководителя бренда (32)
  const isLeader = sessionData?.roles.includes(16) || sessionData?.roles.includes(32)

  const columns = useMemo(() => [
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      render: (date: string) => dayjs(date).format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      render: (value?: string) => formatPhoneNumber(value),
    },
    {
      title: 'Продолжительность',
      dataIndex: 'duration',
      key: 'duration',
      render: (duration: number) => secondsToHumanReadable(duration),
    },
    {
      title: 'Офис',
      dataIndex: 'office',
      key: 'office',
      filters: (isLeader && officesList.length > 1) ? officesList : undefined,
      onFilter: (value, record) => record.office_id === value,
    },
    {
      title: 'Менеджер',
      dataIndex: 'manger',
      key: 'manger',
    },
    {
      title: 'Статус',
      dataIndex: 'call_direction',
      key: 'call_direction',
      render: (direction: string, row: ICallLogData) => `${direction}, ${row.state}`,
    },
    {
      title: 'Запись',
      dataIndex: 'records',
      key: 'records',
      render: (records: null | string[], row: ICallLogData) => {
        if(records && records.length) {
          return (<LoadButtonAndPlayer rowId={row.id} />)
        }
        return null
      },
    },
  ], [isLeader, officesList])

  useEffect(() => {
    (async () => {
      const result = await fetchAPI(`/api/mango-log?customer=${clientId}`)
      setData(result as ICallLogData[])
      if (!officesList.length) {
        const offices = result.reduce((acc, current) => {
          if (current.office_id && !acc.find(item => item.value === current.office_id)) {
            acc.push({ text: current.office, value: current.office_id })
          }
          return acc
        }, [])
        console.log({ offices })
        setOfficesList(offices)
      }
    })()
  }, [clientId])

  useEffect(() => {
    console.log({ data, officesList })
  }, [data, officesList])

  return <Table dataSource={data} columns={columns} pagination={false} key='id'/>
}

function LoadButtonAndPlayer({ rowId }) {
  const [calls, setCalls] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const handleLoadCall = useCallback(() => {
    const id = rowId
    if (!id) return
    setIsLoading(true)
    getCall(id)
      .then(response => {
        if (response && response?.length) {
          setCalls(response as string[])
        }
      })
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <div style={{ minWidth: 200, display: 'flex', justifyContent: 'center' }}>
      {
        !isLoading && calls.length > 0 ? (
          <div style={{ width: '100%', display: 'flex', margin: '1px 0' }}>
            {
              calls.map((call, index) => (
                <audio key={index} controls style={{ width: '100%', height: '30px' }}>
                  <source src={call} type='audio/mpeg' />
                </audio>
              ))
            }
          </div>
        ) : (
          <Button
            type='default'
            onClick={handleLoadCall}
            loading={isLoading}
          >
            Загрузить звонок
          </Button>
        )
      }
    </div>
  )
}

export default TabCallLog
