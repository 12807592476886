import { Button, notification } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SyncOutlined } from '@ant-design/icons'
import { isEqual } from 'lodash'

const UpdatesChecker = () => {
  const currentManifestRef = useRef<Record<string, string>>({})
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [isTabActive, setTabActive] = useState(true);

  const handleVisibilityChange = () => {
    setTabActive(!document.hidden)
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    if (!isOpened && isTabActive) {
      checkUpdates()
      const intervalId = setInterval(checkUpdates, 5 * 60 * 1000)
      return () => clearInterval(intervalId)
    }
  }, [isOpened, isTabActive])

  const checkUpdates = useCallback(async () => {
    if (isOpened) {
      return
    }
    try {
      const response = await fetch('/asset-manifest.json')
      const newManifest = await response.json()
      if (!Object.keys(currentManifestRef.current).length) {
        currentManifestRef.current = newManifest
        return
      }

      const hasUpdates = !isEqual(newManifest, currentManifestRef.current)
      if (hasUpdates) {
        currentManifestRef.current = newManifest
        setIsOpened(true)
        showUpdateNotification()
      }
    } catch (error) {
      console.error('Ошибка при проверке обновлений:', error)
    }
  }, [isOpened])

  const showUpdateNotification = useCallback(() => {
    notification.warning({
      message: (
        <>
          Версия ERP обновилась!<br />
          <div style={{
            fontSize: '85%',
            lineHeight: '1.5em',
            margin: '11px 0',
          }}>
            Чтобы использовать актуальную версию, обновите страницу вручную или нажмите кнопку.
          </div>
        </>
      ),
      description: (
        <Button
          type='primary'
          onClick={() => window.location.reload()}
          style={{
            background: '#24ab24',
            borderColor: '#2e932e',
          }}
        >
          Обновить страницу
        </Button>
      ),
      icon: <SyncOutlined />,
      placement: 'bottomRight',
      duration: 0,
      onClose: () => setIsOpened(false),
      style: { backgroundColor: '#beefbe' },
    })
  }, [])

  return null
}

export default UpdatesChecker
