import { Button, Form, InputNumber, Modal } from 'antd'
import React, { memo, useCallback, useState } from 'react'
import { InputPaymentAmountI } from '../model/types/types'
import { PlusOutlined } from '@ant-design/icons'
import { ModalPaymentAmount } from './modal/modal-payment-amount'
import './styles.css'


interface PriceInputProps {
  value?: number
  props: InputPaymentAmountI
  onOpenModal: () => void
}

const formatCurrency = (value: number) => value
  .toLocaleString('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    currencyDisplay: 'code',
  })
  .replace(/\s*RUB/, '')

const PriceInput: React.FC<PriceInputProps> = ({ value = {}, props, onOpenModal }) => {

  const {
    title,
    otherProps,
    required,
  } = props

  const sum = Number(otherProps?.sum) || 0
  const debt = Number(otherProps?.debt) || 0
  const prepayment = sum - debt

  return (
    <span>
      <Form.Item
        label='Сумма заказа'
        style={{ marginBottom: 0 }}
        required={required}
      >
        <InputNumber value={formatCurrency(sum)} disabled={true}/>
      </Form.Item>
      <Form.Item
        label='Задолженность'
        style={{ marginBottom: 0 }}
      >
        <InputNumber value={formatCurrency(debt)} disabled={true}/>
      </Form.Item>
      <Form.Item
        label={title}
        style={{ marginBottom: 0 }}
      >
        <InputNumber value={formatCurrency(prepayment)} disabled={true}/>
      </Form.Item>
      <Button
        style={{
          marginTop: '30px',
          width: '24px',
          flex: 'none',
        }}
        onClick={() => onOpenModal()}
        disabled={otherProps?.options?.disabledButton}
        icon={<PlusOutlined/>}
      />
    </span>
  )
}

export const InputPaymentAmount: React.FC<InputPaymentAmountI> = memo((props: InputPaymentAmountI) => {
  const {
    rules,
    required,
    name,
    otherProps,
  } = props

  const [validateStatus, setValidateStatus] = useState<'error' | ''>('')
  const [isOpenModalPayment, setIsOpenModalPayment] = useState<boolean>(false)

  const openModal = useCallback(async () => {
    const sum = Number(otherProps?.sum) || 0
    if (sum <= 0) {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Внесите данные по заказу.'
      })
      return
    }
    setIsOpenModalPayment(true)
  },[otherProps, setIsOpenModalPayment])

  const handleValidation = () => {
    const prepayment = Number(otherProps?.sum) - Number(otherProps?.debt)
    if (required && prepayment === 0) {
      setValidateStatus('error')
      return Promise.reject()
    }
  }

  return (
    <>
      <Form.Item
        name={name}
        rules={[{ validator: handleValidation }, ...rules]}
        required={required}
        style={{ 'marginBottom': 0 }}
        className={validateStatus === 'error' ? 'prepayment-input has-error' : 'prepayment-input'}
      >
        <PriceInput
          props={props}
          onOpenModal={openModal}
        />
      </Form.Item>
      {
        Boolean(otherProps?.case_id) && (
          <ModalPaymentAmount
            entityId={otherProps?.case_id}
            debt={Number(otherProps?.debt) || 0}
            isOpen={isOpenModalPayment}
            onClose={() => setIsOpenModalPayment(false)}
          />
        )
      }
    </>
  )
})

/* Старый код, на случай, если нужно откатиться
import React, {memo, useCallback, useState} from 'react'
import {Button, Form, InputNumber, Modal} from 'antd'
import { InputPaymentAmountI } from '../model/types/types'
import { PlusOutlined } from '@ant-design/icons'
import { ModalPaymentAmount } from './modal/modal-payment-amount'
import './styles.css'

export const InputPaymentAmount: React.FC<InputPaymentAmountI> = memo((props: InputPaymentAmountI) => {
  const {
    render,
    name,
    title,
    UISchema,
    otherProps,
    rules,
    required
  } = props
  const [isOpenModalPayment, setIsOpenModalPayment] = useState<boolean>(false)

  const openModal = useCallback(async () => {
    const sum = otherProps?.sum || 0
    if (sum <= 0) {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Внесите данные по заказу.'
      })
      return
    }
    setIsOpenModalPayment(true)
  },[otherProps, setIsOpenModalPayment])

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
    }}>
      <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
      }}>
        <Form.Item
          label={<span style={{ whiteSpace: 'nowrap' }}>Сумма заказа</span>}
          style={{ margin: 0 }}
        >
          <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
            <InputNumber value={otherProps?.sum || 0} disabled={true}/>
          </div>
        </Form.Item>
        <Form.Item
          label='Задолженность'
          style={{ margin: 0 }}
        >
            <InputNumber value={otherProps?.debt || 0} disabled={true}/>
        </Form.Item>
        <Form.Item
          label={<span style={{ whiteSpace: 'nowrap' }}>{title}</span>}
          name={name}
          style={{ margin: 0 }}
          rules={rules}
          required={required}
        >
          {render}
        </Form.Item>
      </div>
      <Form.Item
        style={{ 'marginTop': '30px'}}
      >
        <Button
          onClick={openModal} disabled={otherProps?.options?.disabledButton}
          icon={<PlusOutlined/>}
        />
      </Form.Item>
      {
        Boolean(otherProps?.case_id) && (
          <ModalPaymentAmount
            entityId={otherProps?.case_id}
            debt={otherProps?.debt || 0}
            isOpen={isOpenModalPayment}
            onClose={() => setIsOpenModalPayment(false)}
          />
        )
      }
    </div>
  )
})
*/