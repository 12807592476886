import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import React, { useCallback } from 'react'
import { Popconfirm } from 'antd'
import { DataType } from './interface'
import { ButtonActionWrapper } from '../../../components/list-operations/styles'
import { DeleteTwoTone } from '@ant-design/icons'
import { CountBallInline, Stage } from '../../../components/activity-timeline/styles'
import { formatPrice } from '../../utils'
import { Value } from '../../elements'
import { Sorter } from '../../../interfaces'

const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`
  }
  return phoneNumber
}

const ColumnId: ColumnsType<DataType> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 70,
    fixed: 'left'
  }
]

const ColumnStage: ColumnsType<DataType> = [{
    title: 'Стадия',
    dataIndex: 'stage',
    key: 'stage',
    width: 170,
    render: (_, { stage }) => <StageContainer stage={stage} />
  },
  /*
  {
    title: 'Чаты',
    width: 60,
    dataIndex: 'unread_messages',
    key: 'unread_messages',
    sorter: true,
    render: () => {
      const unread_messages = 0
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {+unread_messages ? (<CountBallInline title={'unread_messages'} bgColor={'countColor'}>{(unread_messages > 99 ? 99 : unread_messages )}</CountBallInline>) : '-'}
        </div>
      )
    }
  }
  */
]

const ColumnBrand: ColumnsType<DataType> = [
  {
    title: 'Бренд',
    width: 150,
    dataIndex: 'brand',
    key: 'brand',
    sorter: true,
    render: val => <Value data={val}/>
  }
]
const ColumnOrganization: ColumnsType<DataType> = [
  {
    title: 'Офис',
    width: 200,
    dataIndex: 'office',
    key: 'office',
    sorter: true,
    render: val => <Value data={val}/>
  }
]

const ColumnsGeneral: ColumnsType<DataType> = [
  {
    title: 'Дата обращения',
    dataIndex: 'appeal_date',
    key: 'appeal_date',
    width: 130,
    sorter: true,
    render: val => dayjs(val).format('DD.MM.YYYY HH:mm')
  },
  {
    title: 'ФИО менеджера',
    dataIndex: 'manager_name',
    key: 'manager_name',
    width: 120,
    sorter: true,
    render: val => <Value data={val}/>
  },
  {
    title: 'Дата замера (план)',
    dataIndex: 'measurement_date',
    key: 'measurement_date',
    width: 130,
    sorter: true,
    render: val => dayjs(val).format('DD.MM.YYYY')
  },
  {
    title: 'Время замера (план)',
    dataIndex: 'measurement_time',
    key: 'measurement_time',
    width: 130,
    render: val => <Value data={val}/>
  },
  {
    title: 'ФИО ответственного за замер',
    dataIndex: 'responsible',
    key: 'responsible',
    width: 130,
    sorter: true,
    render: val => <Value data={val}/>
  },
  {
    title: 'Адрес замера',
    dataIndex: 'address',
    key: 'address',
    width: 120,
    render: val => <Value data={val}/>
  },
  {
    title: 'Телефон клиента',
    dataIndex: 'phone',
    key: 'phone',
    width: 150,
    render: val => formatPhoneNumber(val)
  },
  {
    title: 'ФИО клиента',
    dataIndex: 'name',
    width: 160
  },
  /*
  {
    title: 'Мессенджер',
    dataIndex: 'additional',
    key: 'additional',
    width: 120,
    render: val => <Value data={val}/>
  },
  */
  {
    title: 'Доп телефон клиента',
    dataIndex: 'additional_phone',
    key: 'additional_phone',
    width: 120,
    sorter: true,
    render: val => <Value data={val}/>
  },
  {
    title: 'Сумма заказа',
    dataIndex: 'case_total',
    key: 'case_total',
    width: 130,
    sorter: true,
    render: key => formatPrice(key || 0)
  },
  {
    title: 'Признак заказа',
    dataIndex: 'order_attribute',
    key: 'order_attribute',
    width: 180,
    render: val => <Value data={val}/>
  },
  {
    title: 'Номер производственного заказа',
    dataIndex: 'production_number',
    key: 'production_number',
    width: 180,
    render: val => <Value data={val}/>
  },
  {
    title: 'Дата замера (скорр)',
    dataIndex: 'date_adjusted',
    key: 'date_adjusted',
    width: 180,
    render: val => val ? dayjs(val).format('DD.MM.YYYY') : <Value data={val}/>
  },
  {
    title: 'Время замера (скорр)',
    dataIndex: 'time_adjusted',
    key: 'time_adjusted',
    width: 180,
    render: val => <Value data={val}/>
  },
  {
    title: 'Дата и время замера (факт)',
    dataIndex: 'measurement_datetime',
    key: 'measurement_datetime',
    width: 180,
    render: val => val ? dayjs(val).format('DD.MM.YYYY HH:mm') : <Value data={val}/>
  },
  {
    title: 'Дата создания',
    width: 200,
    dataIndex: 'created_at',
    key: 'created_at',
    render: val => val ? dayjs(val).format('DD.MM.YYYY HH:mm') : <Value data={val}/>
  },
  {
    title: 'Дата обновления',
    width: 200,
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: val => val ? dayjs(val).format('DD.MM.YYYY HH:mm') : <Value data={val}/>
  }
]

const ColumnAction: ColumnsType<DataType> = [
  {
    title: 'Действие',
    key: 'operation',
    className: 'no-edit',
    fixed: 'right',
    width: 100,
    align: 'center',
    render: (_, {id, deleteRecord}) => {
      return (
        <span onClick={e => {
          e.stopPropagation()
        }}>
          <DeleteButton id={id} deleteRecord={deleteRecord}/>
        </span>
      )
    }
  }
]

export function DeleteButton({id, deleteRecord}) {
  const localDeleteRecord = useCallback(() => deleteRecord(id), [deleteRecord, id])
  return (
    <Popconfirm
      title="Вы уверены, что хотите удалить эту запись?"
      okText="Да"
      cancelText="Нет"
      onConfirm={() => localDeleteRecord()}
    >
      <ButtonActionWrapper title="Удалить">
        <DeleteTwoTone />
      </ButtonActionWrapper>
    </Popconfirm>
  )
}

export function getColumns(leader: boolean, measurer: boolean, sorter: Sorter) {
  const columns: ColumnsType<DataType> = []
  columns.push(...ColumnId)
  if (measurer) {
    columns.push(...ColumnBrand)
  }
  columns.push(...ColumnStage)
  if (leader) {
    columns.push(...ColumnOrganization)
  }
  columns.push(...ColumnsGeneral)
  if (leader) {
    columns.push(...ColumnAction)
  }
  return columns.map(item => {
    if (item.key === sorter.sort_field) {
      item.sortOrder = sorter.sort_order
    } else {
      delete item.sortOrder
    }
    return item
  })
}

export function StageContainer({ stage }) {
  if (stage) {
    return <Stage style={stage?.style}>{stage.name}</Stage>
  }
  return <>...</>
}
