import React, {memo, useEffect, useState} from "react"
import {HStack} from "../../../../ui/Stack"
import {Button} from "antd"
import {useHistory} from "react-router-dom"

interface IFilter {
    id?: number,
    name: string,
    code: string,
    style?: any
}

interface IButton {
    name: string,
    code: string,
    isActive: boolean
}

interface IFilterButtons {
    groupName?: string,
    filters?: IFilter[],
    onChange: (filters: any, isButtonChange?: boolean) => void
}

const excludeButtonStages = ['done', 'cancelled']
export const FilterFastButtons = memo((props: IFilterButtons) => {
    const {
        groupName = 'stage',
        filters,
        onChange,
    } = props
    const [buttons, setButtons] = useState<IButton[]>([])
    const history = useHistory()
    const [isUpd, setIsUpd] = useState(false)

    useEffect(() => {
        if (!filters) {
            return
        }
        let urlSearchParams = new URLSearchParams(history.location.search)

        const currentFilters = urlSearchParams.get(groupName)
        const currentFiltersArr = currentFilters?.split(',') || []

        const buttons = filters.filter((filter) => !excludeButtonStages.includes(filter.code))

        const result: IButton[] = buttons.map((filter) => ({
            name: filter.name,
            code: filter.code,
            isActive: currentFiltersArr.includes(filter.code)
        }))

        setButtons(result)
    }, [filters, groupName, history.location])

    useEffect(() => {
        if (!isUpd) {
            return
        }
        let urlSearchParams = new URLSearchParams(history.location.search)
        const currentUrlParams = (urlSearchParams.get(groupName)?.split(',') || [])
          .filter((currentUrlParams) => excludeButtonStages.includes(currentUrlParams))

        let tmp = new Set([
            ...currentUrlParams,
            ...buttons.filter((button) => button.isActive && button.code !== 'all').map((button) => button.code)
        ])

        let resultButtons = Array.from(tmp)
        if (resultButtons?.length === 0 || (resultButtons?.length === 1 && resultButtons[0] === '')) {
            urlSearchParams.delete(groupName)
            resultButtons = []
        }
        onChange({...Object.fromEntries(urlSearchParams), [groupName]: resultButtons}, true)
        setIsUpd(false)
    }, [buttons, groupName, history.location])

    const onClickButton = (code: string) => () => {
        setButtons((prevState) => {
            if (code !== 'all') {
                return prevState.map((prevButton) => {
                    if (prevButton.code === code) {
                        return { ...prevButton, isActive: !prevButton.isActive }
                    }
                    return prevButton
                })
            }

            const currentAll = prevState.find((btn) => btn.code === 'all')
            return [
                ...(prevState.map((prevButton) => {
                    prevButton.isActive = !currentAll!.isActive
                    return prevButton
                }))
            ]
        })
        setIsUpd(true)
    }

    return (
      <HStack gap={"8"} justify={"start"} max style={{marginBottom: '7px', flexWrap: "wrap"}}>
          {
              buttons?.map((button) => {
                  const filter = filters?.find((s) => s.code === button.code)
                  const buttonStyle = filter?.style || {}

                  return <Button
                    key={button.name}
                    type={button.isActive ? 'primary' : 'default'}
                    onClick={onClickButton(button.code)}
                    style={{
                        color: button.isActive ? buttonStyle.color : 'inherit',
                        backgroundColor: button.isActive ? buttonStyle.backgroundColor : 'inherit',
                        //fontWeight: buttonStyle.fontWeight,
                        border: `2px solid ${buttonStyle.backgroundColor}`,
                        lineHeight: 'normal',
                        boxShadow: 'none'
                    }}
                  >
                      {button.name}
                  </Button>
              })
          }
      </HStack>
    )
})
