import { IDispatchProps, IFormData } from './interfaces'
import { ActivityItems, ActivityUpdateItem, ITimelineResponse } from '../../components/v2/activity-timeline'

export const CLAIM_REQUEST_FORM_LOADING = 'CLAIM_REQUEST_FORM_LOADING'
export const CLAIM_REQUEST_DATA = 'CLAIM_REQUEST_DATA'
export const CLAIM_REQUEST_FORM_FAILED = 'CLAIM_REQUEST_FORM_FAILED'
export const CLAIM_REQUEST_FORM_ID_UPDATE = 'CLAIM_REQUEST_FORM_ID_UPDATE'
export const CLAIM_REQUEST_ENUMS_LOADING = 'CLAIM_REQUEST_ENUMS_LOADING'
export const CLAIM_REQUEST_ENUMS_DATA = 'CLAIM_REQUEST_ENUMS_DATA'
export const CLAIM_REQUEST_ENUMS_FAILED = 'CLAIM_REQUEST_ENUMS_FAILED'
export const CLAIM_REQUEST_TIMELINE_LOADING = 'CLAIM_REQUEST_TIMELINE_LOADING'
export const CLAIM_REQUEST_TIMELINE_DATA = 'CLAIM_REQUEST_TIMELINE_DATA'
export const CLAIM_REQUEST_TIMELINE_FAILED = 'CLAIM_REQUEST_TIMELINE_FAILED'
export const CLAIM_REQUEST_TIMELINE_CLEAR = 'CLAIM_REQUEST_TIMELINE_CLEAR'
export const CLAIM_REQUEST_TIMELINE_ADD_ITEM = 'CLAIM_REQUEST_TIMELINE_ADD_ITEM'
export const CLAIM_REQUEST_TIMELINE_UPDATE_ITEM = 'CLAIM_REQUEST_TIMELINE_UPDATE_ITEM'
export const CLAIM_REQUEST_TIMELINE_DELETE_ITEM = 'CLAIM_REQUEST_TIMELINE_DELETE_ITEM'
export const CLEAR_CLAIM_REQUEST_DATA = 'CLEAR_CLAIM_REQUEST_DATA'

/* ============== CLAIM_REQUEST_FORM ============== */
export function setClaimRequestFormLoading() {
  return async dispatch => {
    dispatch({ type: CLAIM_REQUEST_FORM_LOADING })
  }
}

export function clearClaimRequestData() {
  return async dispatch => {
    dispatch({ type: CLEAR_CLAIM_REQUEST_DATA })
  }
}

export function setClaimRequestData(data: IFormData) {
  return async dispatch => {
    dispatch({ type: CLAIM_REQUEST_DATA, payload: data })
  }
}

export function setClaimRequestFormFailed(error: string) {
  return async (dispatch: IDispatchProps) => {
    dispatch({ type: CLAIM_REQUEST_FORM_FAILED, payload: error })
  }
}

export function setClaimRequestId(id: string) {
  return async dispatch => {
    dispatch({ type: CLAIM_REQUEST_FORM_ID_UPDATE, payload: id })
  }
}

/* ============== end ClaimRequest_FORM ============== */

/* ============== ClaimRequest_ENUMS ============== */
export function setClaimRequestEnumsLoading() {
  return async dispatch => {
    dispatch({ type: CLAIM_REQUEST_ENUMS_LOADING })
  }
}

export function setClaimRequestEnumsData(enumsData) {
  return async dispatch => {
    dispatch({ type: CLAIM_REQUEST_ENUMS_DATA, payload: enumsData })
  }
}

export function setClaimRequestEnumsFailed(error: string) {
  return async dispatch => {
    dispatch({ type: CLAIM_REQUEST_ENUMS_FAILED, payload: error })
  }
}
/* ============== end ClaimRequest_ENUMS ==============  */

/* ============== ClaimRequest_TIMELINE ============== */
export function setClaimRequestTimelineLoading() {
  return async dispatch => {
    dispatch({ type: CLAIM_REQUEST_TIMELINE_LOADING })
  }
}

export function setClaimRequestTimelineData(formData: ITimelineResponse) {
  return async dispatch => {
    dispatch({ type: CLAIM_REQUEST_TIMELINE_DATA, payload: formData })
  }
}

export function setClaimRequestTimelineFailed(error: string) {
  return async dispatch => {
    dispatch({ type: CLAIM_REQUEST_TIMELINE_FAILED, payload: error })
  }
}

export function setClaimRequestTimelineClear() {
  return async dispatch => {
    dispatch({ type: CLAIM_REQUEST_TIMELINE_CLEAR })
  }
}

export function addClaimRequestTimeLineItem(activityItems: ActivityItems[]) {
  return async dispatch => {
    dispatch({ type: CLAIM_REQUEST_TIMELINE_ADD_ITEM, payload: activityItems })
  }
}

export function updateClaimRequestTimeLineItem(activityItem: ActivityUpdateItem) {
  return async dispatch => {
    dispatch({ type: CLAIM_REQUEST_TIMELINE_UPDATE_ITEM, payload: activityItem })
  }
}

export function deleteClaimRequestTimeLineItem(activityItem: ActivityUpdateItem) {
  return async dispatch => {
    dispatch({ type: CLAIM_REQUEST_TIMELINE_DELETE_ITEM, payload: activityItem })
  }
}
/* ============== end ClaimRequest_TIMELINE ==============  */
