import React, { useState, useEffect, ReactNode, forwardRef } from 'react'

interface FixedHeaderProps {
  children: ReactNode
}

const FixedHeader = forwardRef<HTMLDivElement, FixedHeaderProps>(({ children }, ref) => {
  const [isFixed, setIsFixed] = useState(false)

  const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop
    setIsFixed(scrollTop >= 90)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div
      style={isFixed ? {
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 1000,
        transition: 'box-shadow 0.3s ease',
        backgroundColor: 'rgb(246, 248, 250)',
        borderBottom: '1px solid rgb(152 152 152)',
        padding: '20px 0px 10px 0',
        maxWidth: 'inherit',
        width: 'calc(100%)',
      } : {}}
    >
      <div ref={ref}>
        {children}
      </div>
    </div>
  )
})

export default FixedHeader
