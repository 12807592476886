import { ActivityItemFileWDate, ActivityItems, ActivityUpdateItem, FileFolderData } from '../types/types'

export const updateTimelineFileFolders = (
  newDataItems: {items: ActivityItemFileWDate[], folder: FileFolderData},
  timelineItems: ActivityItems[],
  timelineDates: string[],
) => {
  newDataItems.items.forEach(newDataItem => {
    const indexScope = timelineDates?.findIndex(dateItem => dateItem == newDataItem.parentDate)
    timelineItems[indexScope].items?.forEach(currentActivityItem => {
      if (
        currentActivityItem.type === 'file' &&
        currentActivityItem.data?.id === newDataItem.data?.id
      ) {
        currentActivityItem.data = { ...currentActivityItem.data, folder: newDataItems.folder }
      }
    })
  })

  return timelineItems
}
export const clearTimelineFileFolders = (
  itemsToClear: {items: ActivityItemFileWDate[]},
  timelineItems: ActivityItems[],
  timelineDates: string[],
) => {
  itemsToClear.items.forEach(newDataItem => {
    const indexScope = timelineDates?.findIndex(dateItem => dateItem == newDataItem.parentDate)
    timelineItems[indexScope].items?.forEach(currentActivityItem => {
      if (
        currentActivityItem.type === 'file' &&
        currentActivityItem.data?.id === newDataItem.data?.id
      ) {
        currentActivityItem.data = { ...currentActivityItem.data, folder: undefined }
      }
    })
  })

  return timelineItems
}
