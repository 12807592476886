import React, { memo, Suspense, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEventSource } from '../../../../../hooks/useEventSource/useEventSource'
import {
  addTimeLineItem,
  updateTimeLineItem,
  deleteTimeLineItem
} from '../../../../../store/timeline/actions'
import {
  ActivityDataType,
  ActivityItem,
  ActivityItems,
  ActivityTimelineContextProps,
  ActivityTimeline,
  ActivityTimelineContext,
  selectTimelineData,
  selectTimelineError,
  selectTimelineIsLoading
} from '../../../../../components/v2/activity-timeline'

interface timeLineProps {
  className?: string
  id: string | number
}

export const CardTimeline: React.FC<timeLineProps> = memo((props: timeLineProps) => {
  const { id, className } = props
  const dispatch = useDispatch()
  const timelineIsLoading = useSelector(selectTimelineIsLoading)
  const timelineError = useSelector(selectTimelineError)
  const timelineItems = useSelector(selectTimelineData)
  const { sse, subscribe } = useEventSource()

  const handleTimelineNewItems = useCallback((newItems: ActivityItems[]) => {
    /* todo из-за манго колхозим проверку на соответствие type и id передаваемых и существующих элементов,
    *   подумать как оптимизировать
    * */
    newItems.forEach(newItem => {
      const scopeIndex = timelineItems.itemsDates?.findIndex(date => date == newItem.date)
      if (scopeIndex === -1 || !scopeIndex) {
        dispatch(addTimeLineItem([newItem]))
        return
      }
      if (!newItem?.items?.length) {
        return
      }

      const addItems: ActivityItem[] = []
      const updItems: DeepPartial<ActivityDataType>[] = []

      for (let newItemByType of newItem.items) {
        for (let currentItemByType of timelineItems.items[scopeIndex]?.items) {
          (newItemByType.type === currentItemByType.type && newItemByType.id === currentItemByType.id)
            ? updItems.push(newItemByType)
            : addItems.push(newItemByType)
        }
      }

      if (addItems.length) {
        dispatch(addTimeLineItem([{ date: newItem.date, items: addItems }]))
      }

      if (updItems.length) {
        updItems.forEach((item) => {
          dispatch(updateTimeLineItem({ date: newItem.date, data: item }))
        })
      }
    })
  }, [timelineItems, dispatch])

  useEffect(() => {
    if (id !== 'new') {
      subscribe(`/api/get-activity/live/claim_request/${id}`, handleTimelineNewItems)
    }
    return () => sse?.current?.close()
  }, [id, dispatch])

  const updateTimeline = useCallback(activityItem => {
    dispatch(updateTimeLineItem(activityItem))
  }, [])

  const deleteTimelineItem = useCallback(activityItem => {
    dispatch(deleteTimeLineItem(activityItem))
  }, [])

  const defaultProps = useMemo(
    (): ActivityTimelineContextProps => ({
      card_id: id,
      card_type: 'claim_request',
      updateTimeline: updateTimeline,
      deleteTimelineItem: deleteTimelineItem,
    }),
    [id],
  )

  return (
    <Suspense fallback={''}>
      <ActivityTimelineContext.Provider value={defaultProps}>
        <ActivityTimeline
          isLoading={timelineIsLoading}
          items={timelineItems?.items}
          error={timelineError}
          className={className}
        />
      </ActivityTimelineContext.Provider>
    </Suspense>
  )
})
